import Vue from 'vue'
import VueRouter from 'vue-router'

// ============================== Start Views ==============================
import Home from '../views/Home.vue' // Home

// ********** Children
import HomePage from '../views/children/HomePage.vue'

// ========== Start HomePage ==========

// **** Start Categories
import CategoriesHome from '../views/children/Categories/Home.vue'
// Show All
import AllCategories from '../views/children/Categories/ShowAll.vue'
import SubShowAll from '../views/children/Categories/ShowAllSub.vue'
import ShowAllSecondSub from '../views/children/Categories/ShowAllSecondSub.vue'
// Add
import AddCategory from '../views/children/Categories/Add.vue'
// Edit
import EditCategory from '../views/children/Categories/Edit.vue'
// **** End Categories

// **** Start Sub_Category
import SubCategoryHome from '../views/children/SubCategories/Home.vue'
// Show All
import AllSubCategories from '../views/children/SubCategories/ShowAll.vue'
// Add
import AddSubCategory from '../views/children/SubCategories/Add.vue'
// Edit
import EditSubCategory from '../views/children/SubCategories/Edit.vue'
// **** End Sub_Category

// ========== Start PROMO CODE ==========
import PromoCode from '../views/children/PromoCode/Home.vue'
// Show All
import AllPromoCode from '../views/children/PromoCode/ShowAll.vue'
// Add
import AddPromoCode from '../views/children/PromoCode/Add.vue'
// Edit
import EditPromoCode from '../views/children/PromoCode/Edit.vue'
// ========== End PROMO CODE ==========

// ========== Start Flash Sale ==========
import FlashSaleHome from '../views/children/FlashSale/Home.vue'
// Show All
import AllFlashSales from '../views/children/FlashSale/ShowAll.vue'
// Add
import AddFlashSale from '../views/children/FlashSale/Add.vue'
// Edit
import EditFlashSale from '../views/children/FlashSale/Edit.vue'
// ========== End Flash Sale ==========

// ========== Start Offers ==========
import OfferHome from '../views/children/Offers/Home.vue'
// Show All
import AllOffers from '../views/children/Offers/ShowAll.vue'
// Add
import AddOffer from '../views/children/Offers/Add.vue'
// Edit
import EditOffer from '../views/children/Offers/Edit.vue'
// ========== End Offers ==========

// ========== Start Users ==========
// Crud
import UsersHome from '../views/children/Users/Home.vue'
import AllUserss from '../views/children/Users/ShowAll.vue'
import AddUsers from '../views/children/Users/Add.vue'
import EditUsers from '../views/children/Users/Edit.vue'
// Profile
import UserShowHome from '../views/children/Users/UserProfile/AdminShowHome.vue'
import UserCards from '../views/children/Users/UserProfile/children/Cards.vue'
import UserPersonalData from '../views/children/Users/UserProfile/children/PersonalData.vue'
import ShowOrdersUser from '../views/children/Users/UserProfile/children/Orders.vue'

// ========== End Users ==========

// ========== Start Providers ==========
// Crud
import ProvidersHome from '../views/children/Providers/Home.vue'
import AllProviders from '../views/children/Providers/ShowAll.vue'
import AddProvider from '../views/children/Providers/Add.vue'
import EditProvider from '../views/children/Providers/Edit.vue'
// Profile
import ProviderShowHome from '../views/children/Providers/UserProfile/AdminShowHome.vue'
import ProviderCards from '../views/children/Providers/UserProfile/children/Cards.vue'
import ProviderPersonalData from '../views/children/Providers/UserProfile/children/PersonalData.vue'
import ShowOrdersProvider from '../views/children/Providers/UserProfile/children/Orders.vue'

// ========== End Providers ==========

// ========== Start Countries ==========
import CountriesHome from '../views/children/Countries/Home.vue'
// Show All
import AllCountries from '../views/children/Countries/ShowAll.vue'
// Add
import AddCountry from '../views/children/Countries/Add.vue'
// Edit
import EditCountry from '../views/children/Countries/Edit.vue'
// ========== End Countries ==========

// ========== Start Cities ==========
import CitiesHome from '../views/children/Cities/Home.vue'
// Show All
import AllCities from '../views/children/Cities/ShowAll.vue'
// Add
import AddCity from '../views/children/Cities/Add.vue'
// Edit
import EditCity from '../views/children/Cities/Edit.vue'
// ========== End Cities ==========

// ========== Start Faqs ==========
import FaqHome from '../views/children/Faqs/Home.vue'
// Show All
import AllFaqs from '../views/children/Faqs/ShowAll.vue'
// Add
import AddFaq from '../views/children/Faqs/Add.vue'
// Edit
import EditFaq from '../views/children/Faqs/Edit.vue'
// ========== End Faqs ==========

// ========== Start Reoprts Reasons ==========
import ReportsHome from '../views/children/ReportReason/Home.vue'
// Show All
import AllReports from '../views/children/ReportReason/ShowAll.vue'
// Add
import AddReports from '../views/children/ReportReason/Add.vue'
// Edit
import EditReports from '../views/children/ReportReason/Edit.vue'
// ========== End Reoprts Reasons ==========

// ========== Start Reoprts ==========
import ApplicationReportsHome from '../views/children/Reports/Home.vue'
// Products Reports
import ProductsReports from '../views/children/Reports/ProductsReports.vue'
// ========== End Reoprts ==========

// ========== Start Transaction ==========
import TransactionsHome from '../views/children/Transactions/Home.vue'
// All Transaction
import Transactions from '../views/children/Transactions/Transactions.vue'
// Single Transaction
import ShowTransaction from '../views/children/Transactions/Show.vue'
// ========== End Transaction ==========

// ========== Start Orders ==========
import OrdersHome from '../views/children/Orders/Home.vue'
// All Orders
import Orders from '../views/children/Orders/ShowAll.vue'
// Single Order
import ShowOrder from '../views/children/Orders/Show.vue'
// ========== End Orders ==========

// ========== Start Returns ==========
import ReturnsHome from '../views/children/Returns/Home.vue'
// All Returns
import Returns from '../views/children/Returns/ShowAll.vue'
// Single Return
import ShowReturn from '../views/children/Returns/Show.vue'
// ========== End Returns ==========

// ========== Start Returns ==========
import RateHome from '../views/children/Rates/Home.vue'
// All Returns
import Rates from '../views/children/Rates/ShowAll.vue'
// Single Return
import ShowRate from '../views/children/Rates/Show.vue'
// ========== End Returns ==========

// ========== Start Stock Tracking ==========
import StockTrackingHome from '../views/children/StockTracking/Home.vue'
// All Stock
import StockTracking from '../views/children/StockTracking/ShowAll.vue'
import StockTrackingShow from '../views/children/StockTracking/Show.vue'
// ========== End Stock Tracking ==========

// ========== Start Notifications ==========
import NotificationsHome from '../views/children/Notifications/Home.vue'
// All Notifications
import Notifications from '../views/children/Notifications/ShowAll.vue'
// Add Notification
import AddNotification from '../views/children/Notifications/Add.vue'
// Single Notification
import ShowNotification from '../views/children/Notifications/Show.vue'
// ========== End Notifications ==========

// ========== Start Slider ==========
import SliderHome from '../views/children/MainSlider/Home.vue'
// Show All
import AllSliders from '../views/children/MainSlider/ShowAll.vue'
// Add
import AddSlider from '../views/children/MainSlider/Add.vue'
// Edit
import EditSlider from '../views/children/MainSlider/Edit.vue'
// ========== End Slider ==========

// ========== Start Sizes ==========
import SizesHome from '../views/children/Sizes/Home.vue'
// Show All
import AllSizes from '../views/children/Sizes/ShowAll.vue'
// Add
import AddSize from '../views/children/Sizes/Add.vue'
// Edit
import EditSize from '../views/children/Sizes/Edit.vue'
// ========== End Sizes ==========

// ========== Start Feature ==========
import FeaturesHome from '../views/children/Features/Home.vue'
// Show All
import AllFeatures from '../views/children/Features/ShowAll.vue'
// Add
import AddFeature from '../views/children/Features/Add.vue'
// Edit
import EditFeature from '../views/children/Features/Edit.vue'
// ========== End Features ==========

// ========== Start Products ==========
import ProductsHome from '../views/children/Products/Home.vue'
// Show All
import AllProducts from '../views/children/Products/ShowAll.vue'
// Add
import AddProduct from '../views/children/Products/Add.vue'
// Edit
import EditProduct from '../views/children/Products/Edit.vue'
// Show Product
import ShowProduct from '../views/children/Products/Show.vue'
// ========== End Products ==========

// ========== Start Colors ==========
import ColorsHome from '../views/children/Colors/Home.vue'
// Show All
import AllColors from '../views/children/Colors/ShowAll.vue'
// Add
import AddColor from '../views/children/Colors/Add.vue'
// Edit
import EditColor from '../views/children/Colors/Edit.vue'
// ========== End Colors ==========

// ========== Start Admins ==========
// Crud
import AdminHome from '../views/children/Admins/Home.vue'
import AllAdmins from '../views/children/Admins/ShowAll.vue'
import AddAdmin from '../views/children/Admins/Add.vue'
import EditAdmin from '../views/children/Admins/Edit.vue'
// Profile
import AdminShowHome from '../views/children/Admins/AdminProfile/AdminShowHome.vue'
import Cards from '../views/children/Admins/AdminProfile/children/Cards.vue'
import PersonalData from '../views/children/Admins/AdminProfile/children/PersonalData.vue'
// ========== End Admins ==========

// ========== Start Permissions ==========
import PermissionsHome from '../views/children/Permissions/Home.vue'
// Show All
import AllPermissions from '../views/children/Permissions/ShowAll.vue'
// Add
import AddPermission from '../views/children/Permissions/Add.vue'
// Edit
import EditPermission from '../views/children/Permissions/Edit.vue'
// ========== End Permissions ==========

// ========== Start Settings ==========
// import SettingsHome from "../views/children/Settings/Home.vue";
import globalSettings from '../views/children/Settings/Global/index.vue'

// ABOUT
import AboutHome from '../views/children/Settings/About/Home.vue'
import AboutShow from '../views/children/Settings/About/ShowAll.vue'
import AddAbout from '../views/children/Settings/About/Add.vue'
import AddMetaAbout from '../views/children/Settings/About/AddMeta.vue'
import EditAbout from '../views/children/Settings/About/Edit.vue'
// Terms
import TermsHome from '../views/children/Settings/Terms/Home.vue'
import TermsShow from '../views/children/Settings/Terms/ShowAll.vue'
import AddTerms from '../views/children/Settings/Terms/Add.vue'
import AddMetaTerms from '../views/children/Settings/Terms/AddMeta.vue'
import EditTerms from '../views/children/Settings/Terms/Edit.vue'
// ABOUT
import PolicyHome from '../views/children/Settings/Policy/Home.vue'
import PolicyShow from '../views/children/Settings/Policy/ShowAll.vue'
import AddPolicy from '../views/children/Settings/Policy/Add.vue'
import AddMetaPolicy from '../views/children/Settings/Policy/AddMeta.vue'
import EditPolicy from '../views/children/Settings/Policy/Edit.vue'
// ABOUT
import ContactMessages from '../views/children/Settings/ContactMessages/Home.vue'
import ContactMessagesShow from '../views/children/Settings/ContactMessages/ShowAll.vue'
import ReplayContactMessages from '../views/children/Settings/ContactMessages/Edit.vue'
// ABOUT
import BanubaUsHome from '../views/children/Settings/Banuba/Home.vue'
import BanubaShow from '../views/children/Settings/Banuba/Show.vue'
import EditBanuba from '../views/children/Settings/Banuba/Edit.vue'
// POLICY
import ContactHome from '../views/children/Settings/ContactUs/Home.vue'
import ContactShow from '../views/children/Settings/ContactUs/Show.vue'
import EditContact from '../views/children/Settings/ContactUs/Edit.vue'

import SmsHome from '../views/children/Settings/Sms/Home.vue'
import SmsShow from '../views/children/Settings/Sms/Show.vue'
import EditSms from '../views/children/Settings/Sms/Edit.vue'
// ========== End Settings ==========

// ********** Auth
import Login from '../views/Auth/Login.vue'

// ********** NotFound
import NotFound from '../views/NotFound.vue'

// ============================== End Views ==============================

// Vuex store
import store from '../store/index'
let router = null
window.rootRouter = router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '/',
          name: 'HomePage',
          component: HomePage,
        },

        // ========== Start Categories ==========
        {
          path: 'categories',
          component: CategoriesHome,
          children: [
            {
              path: '',
              name: 'AllCategories',
              component: AllCategories,
            },
            // Add
            {
              path: 'add',
              name: 'AddCategory',
              component: AddCategory,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditCategory',
              component: EditCategory,
              props: true,
            },
            // Sub
            {
              path: ':id/sub-category',
              name: 'SubShowAll',
              component: SubShowAll,
              props: true,
            },
            // Sub
            {
              path: ':id/sub-category/:parent_id/sub',
              name: 'ShowAllSecondSub',
              component: ShowAllSecondSub,
              props: true,
            },
          ],
        },
        // ========== End Categories ==========

        // ========== Start Subcategories ==========
        {
          path: 'subcategories',
          component: SubCategoryHome,
          children: [
            {
              path: '',
              name: 'AllSubCategories',
              component: AllSubCategories,
            },
            {
              path: 'add',
              name: 'AddSubCategory',
              component: AddSubCategory,
            },
            {
              path: 'edit/:id',
              name: 'EditSubCategory',
              component: EditSubCategory,
              props: true,
            },
          ],
        },
        // ========== End Subcategories ==========

        // ========== Start Users ==========
        {
          path: '/users',
          component: UsersHome,
          children: [
            {
              path: '',
              name: 'AllUserss',
              component: AllUserss,
            },
            {
              path: 'add',
              name: 'AddUsers',
              component: AddUsers,
            },
            {
              path: 'edit/:id',
              name: 'EditUsers',
              component: EditUsers,
              props: true,
            },
            // Show Admin
            {
              path: 'show/:id',
              component: UserShowHome,
              props: true,
              children: [
                {
                  path: '',
                  name: 'Cards',
                  component: UserCards,
                  props: true,
                },
                {
                  path: 'data',
                  name: 'PersonalData',
                  component: UserPersonalData,
                  props: true,
                },
                {
                  path: 'orders',
                  name: 'orders',
                  component: ShowOrdersUser,
                  props: true,
                },
              ],
            },
          ],
        },
        // ========== End Users ==========

        // ========== Start Providers ==========
        {
          path: '/providers',
          component: ProvidersHome,
          children: [
            {
              path: '',
              name: 'AllProviders',
              component: AllProviders,
            },
            {
              path: 'add',
              name: 'AddProvider',
              component: AddProvider,
            },
            {
              path: 'edit/:id',
              name: 'EditProvider',
              component: EditProvider,
              props: true,
            },
            // Show Admin
            {
              path: 'show/:id',
              component: ProviderShowHome,
              props: true,
              children: [
                {
                  path: '',
                  name: 'ProviderCards',
                  component: ProviderCards,
                  props: true,
                },
                {
                  path: 'data',
                  name: 'ProviderPersonalData',
                  component: ProviderPersonalData,
                  props: true,
                },
                {
                  path: 'orders',
                  name: 'ShowOrdersProvider',
                  component: ShowOrdersProvider,
                  props: true,
                },
              ],
            },
          ],
        },
        // ========== End Providers ==========

        // ========== Start Countries ==========
        {
          path: '/countries',
          component: CountriesHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllCountries',
              component: AllCountries,
            },
            // Add
            {
              path: 'add',
              name: 'AddCountry',
              component: AddCountry,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditCountry',
              component: EditCountry,
              props: true,
            },
          ],
        },
        // ========== End Countries ==========

        // ========== Start Cities ==========
        {
          path: '/cities',
          component: CitiesHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllCities',
              component: AllCities,
            },
            // Add
            {
              path: 'add',
              name: 'AddCity',
              component: AddCity,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditCitiy',
              component: EditCity,
              props: true,
            },
          ],
        },
        // ========== End Cities ==========

        // ========== Start Cities ==========
        {
          path: '/faqs',
          component: FaqHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllFaq',
              component: AllFaqs,
            },
            // Add
            {
              path: 'add',
              name: 'AddFaq',
              component: AddFaq,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditFaq',
              component: EditFaq,
              props: true,
            },
          ],
        },
        // ========== End Cities ==========

        // ========== Start Promo Code ==========
        {
          path: '/promoCode',
          component: PromoCode,
          children: [
            // Show All
            {
              path: '',
              name: 'AllPromoCode',
              component: AllPromoCode,
            },
            // Add
            {
              path: 'add',
              name: 'AddPromoCode',
              component: AddPromoCode,
            },
            // Show
            {
              path: 'show/:id',
              name: 'ShowPromoCode',
              component: ()=> import('../views/children/PromoCode/Show.vue'),
              props: true,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditPromoCode',
              component: EditPromoCode,
              props: true,
            },
          ],
        },
        // ========== End Promo Code ==========

        // ========== Start Falsh Sale ==========
        {
          path: '/flash-sales',
          component: FlashSaleHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllFlashSales',
              component: AllFlashSales,
            },
            // Add
            {
              path: 'add',
              name: 'AddFlashSale',
              component: AddFlashSale,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditFlashSale',
              component: EditFlashSale,
              props: true,
            },
          ],
        },
        // ========== End Flash Sale ==========

        // ========== Start Offers ==========
        {
          path: '/offers',
          component: OfferHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllOffers',
              component: AllOffers,
            },
            // Add
            {
              path: 'add',
              name: 'AddOffer',
              component: AddOffer,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditOffer',
              component: EditOffer,
              props: true,
            },
          ],
        },
        // ========== End Offers ==========

        // ========== Start Reports Reasons ==========
        {
          path: '/reports-reasons',
          component: ReportsHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllReports',
              component: AllReports,
            },
            // Add
            {
              path: 'add',
              name: 'AddReports',
              component: AddReports,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditReports',
              component: EditReports,
              props: true,
            },
          ],
        },
        // ========== End Reports Reasons ==========

        // ========== Start Reports ==========
        {
          path: '/reports',
          component: ApplicationReportsHome,
          children: [
            // Products Reports
            {
              path: 'products-reports',
              name: 'ProductsReports',
              component: ProductsReports,
            },
          ],
        },

        // ========== End Reports ==========

        // ========== Start Transactions ==========
        {
          path: '/transactions',
          component: TransactionsHome,
          children: [
            // All Transaction
            {
              path: 'all-transactions',
              name: 'Transactions',
              component: Transactions,
            },
            {
              path: 'show/:id',
              name: 'ShowTransaction',
              component: ShowTransaction,
              props: true,
            },
          ],
        },
        // ========== End Transactions ==========

        // ========== Start Orders ==========
        {
          path: '/Orders',
          component: OrdersHome,
          children: [
            // All Orders
            {
              path: 'all-orders',
              name: 'Orders',
              component: Orders,
            },
            {
              path: 'show/:id',
              name: 'ShowOrder',
              component: ShowOrder,
              props: true,
            },
          ],
        },
        // ========== End Orders ==========

        // ========== Start Returns ==========
        {
          path: '/returns',
          component: ReturnsHome,
          children: [
            // All Returns
            {
              path: 'all-returns',
              name: 'Returns',
              component: Returns,
            },
            {
              path: 'show/:id',
              name: 'ShowReturn',
              component: ShowReturn,
              props: true,
            },
          ],
        },
        // ========== End Returns ==========
        // ========== Start Rates ==========
        {
          path: '/rates',
          component: RateHome,
          children: [
            // All Returns
            {
              path: 'all-rates',
              name: 'all-rates',
              component: Rates,
            },
            {
              path: 'show/:id',
              name: 'show-rate',
              component: ShowRate,
              props: true,
            },
          ],
        },
        // ========== End Rates ==========

        // ========== Start StockTracking ==========
        {
          path: '/stock-tracking',
          component: StockTrackingHome,
          children: [
            // All StockTracking
            {
              path: 'all-stock',
              name: 'AllStock',
              component: StockTracking,
            },
            {
              path: 'show/:id',
              name: 'StockTrackingShow',
              component: StockTrackingShow,
            },
          ],
        },
        // ========== End StockTracking ==========

        // ========== Start Notifications ==========
        {
          path: '/notifications',
          component: NotificationsHome,
          children: [
            // All Notifications
            {
              path: 'all-notifications',
              name: 'Notifications',
              component: Notifications,
            },
            {
              path: 'add',
              name: 'AddNotification',
              component: AddNotification,
            },
            {
              path: 'show/:id',
              name: 'ShowNotification',
              component: ShowNotification,
              props: true,
            },
          ],
        },
        // ========== End Notifications ==========

        // ========== Start Slider ==========
        {
          path: '/sliders',
          component: SliderHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllSliders',
              component: AllSliders,
            },
            // Add
            {
              path: 'add',
              name: 'AddSlider',
              component: AddSlider,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditSlider',
              component: EditSlider,
              props: true,
            },
          ],
        },
        // ========== End Slider ==========

        // ========== Start Sizes ==========
        {
          path: '/sizes',
          component: SizesHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllSizes',
              component: AllSizes,
            },
            // Add
            {
              path: 'add',
              name: 'AddSize',
              component: AddSize,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditSize',
              component: EditSize,
              props: true,
            },
          ],
        },
        // ========== End Sizes ==========

        // ========== Start Colors ==========
        {
          path: '/colors',
          component: ColorsHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllColors',
              component: AllColors,
            },
            // Add
            {
              path: 'add',
              name: 'AddColor',
              component: AddColor,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditColor',
              component: EditColor,
              props: true,
            },
          ],
        },
        // ========== End Colors ==========

        // ========== Start Products ==========
        {
          path: '/products',
          component: ProductsHome,

          children: [
            // Show All
            {
              path: '',
              name: 'AllProducts',
              component: AllProducts,
            },
            // Add
            {
              path: 'add',
              name: 'AddProduct',
              component: AddProduct,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditProduct',
              component: EditProduct,
              props: true,
            },
            // Show
            {
              path: 'show/:id',
              name: 'ShowProduct',
              component: ShowProduct,
              props: true,
            },
          ],
        },
        // ========== End Products ==========

        // ========== Start Features ==========
        {
          path: '/features',
          component: FeaturesHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllFeatures',
              component: AllFeatures,
            },
            // Add
            {
              path: 'add',
              name: 'AddFeature',
              component: AddFeature,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditFeature',
              component: EditFeature,
              props: true,
            },
          ],
        },
        // ========== End Products ==========

        // ========== Start Admins ==========
        {
          path: '/admins',
          component: AdminHome,
          children: [
            {
              path: '',
              name: 'AllAdmins',
              component: AllAdmins,
            },
            {
              path: 'add',
              name: 'AddAdmin',
              component: AddAdmin,
            },
            {
              path: 'edit/:id',
              name: 'EditAdmin',
              component: EditAdmin,
              props: true,
            },
            // Show Admin
            {
              path: 'show/:id',
              component: AdminShowHome,
              props: true,
              children: [
                {
                  path: '',
                  name: 'Cards',
                  component: Cards,
                  props: true,
                },
                {
                  path: 'data',
                  name: 'PersonalData',
                  component: PersonalData,
                  props: true,
                },
              ],
            },
          ],
        },
        // ========== End Admins ==========

        // ========== Start Permissions ==========
        {
          path: '/permissions',
          component: PermissionsHome,
          children: [
            // Show All
            {
              path: '',
              name: 'AllPermissions',
              component: AllPermissions,
            },
            // Add
            {
              path: 'add',
              name: 'AddPermission',
              component: AddPermission,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditPermission',
              component: EditPermission,
              props: true,
            },
          ],
        },
        // ========== End Permissions ==========

        // ========== Start Settings ==========
        {
          alias: '/settings/about',
          path: 'about',
          component: AboutHome,
          children: [
            // Show
            {
              path: '',
              name: 'aboutShow',
              component: AboutShow,
            },
            {
              path: 'add',
              name: 'add_about',
              component: AddAbout,
            },
            {
              path: 'add_meta',
              name: 'add_meta',
              component: AddMetaAbout,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'edit_about',
              component: EditAbout,
              props: true,
            },
          ],
        },
        {
          alias: '/settings/terms',
          path: 'terms',
          component: TermsHome,
          children: [
            // Show
            {
              path: '',
              name: 'TermsShow',
              component: TermsShow,
            },
            {
              path: 'add',
              name: 'AddAbout',
              component: AddTerms,
            },
            {
              path: 'add_meta',
              name: 'add_meta',
              component: AddMetaTerms,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditAbout',
              component: EditTerms,
              props: true,
            },
          ],
        },
        {
          alias: '/settings/policy',
          path: 'policy',
          component: PolicyHome,
          children: [
            // Show
            {
              path: '',
              name: 'PolicyShow',
              component: PolicyShow,
            },
            {
              path: 'add',
              name: 'AddPolicy',
              component: AddPolicy,
            },
            {
              path: 'add_meta',
              name: 'add_meta',
              component: AddMetaPolicy,
            },
            // Edit
            {
              path: 'edit/:id',
              name: 'EditPolicy',
              component: EditPolicy,
              props: true,
            },
          ],
        },
        {
          alias: '/settings/contact_messages',
          path: 'ContactMessages',
          component: ContactMessages,
          children: [
            // Show
            {
              path: '',
              name: 'ContactMessagesShow',
              component: ContactMessagesShow,
            },
            // Replay
            {
              path: 'replay/:id',
              name: 'ReplayContactMessages',
              component: ReplayContactMessages,
              props: true,
            },
          ],
        },
        {
          path: 'settings/global',
          name: 'globalSettings',
          component: globalSettings,
        },

        // TERMS
        {
          path: 'banuba',
          component: BanubaUsHome,
          children: [
            // Show
            {
              path: '',
              name: 'BanubaShow',
              component: BanubaShow,
            },
            // Edit
            {
              path: 'edit',
              name: 'EditBanuba',
              component: EditBanuba,
            },
          ],
        },
        // POLICY
        {
          path: 'contact',
          component: ContactHome,
          children: [
            // Show
            {
              path: '',
              name: 'ContactShow',
              component: ContactShow,
            },
            // Edit
            {
              path: 'edit',
              name: 'EditContact',
              component: EditContact,
            },
          ],
        },
        {
          path: 'sms',
          component: SmsHome,
          children: [
            // Show
            {
              path: '',
              name: 'SmsShow',
              component: SmsShow,
            },
            // Edit
            {
              path: 'edit',
              name: 'EditSms',
              component: EditSms,
            },
          ],
        },
        // ========== End Settings ==========
      ],
    },

    // Auth
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },

    // NotFound
    { path: '/:notFound(.*)', name: 'NotFound', component: NotFound },
  ],
})

const TOKEN = store.getters['auth_module/currentUser'].token

router.beforeEach((to, _2, next) => {
  if (to.name !== 'Login' && !TOKEN) {
    next({ name: 'Login' })
  } else if (to.name == 'Login' && TOKEN) {
    next('/')
  } else if (to.name == 'Login' || to.name == 'NotFound') {
    store.dispatch('sideNav_module/reseatBodyPadding')
    next()
  } else {
    next()
  }
})

Vue.use(VueRouter)
export default router
