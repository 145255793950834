<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("edit") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-12 py-0">
              <uplode-image
                @inputChanged="uplodeImg_1"
                :data_src="data.image"
                placeHolder="الصورة "
              ></uplode-image>
            </div>
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.code"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.code") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.use"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.use_code") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.max_users"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.max_use_users") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.discount"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.discount") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.max_discount"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.max_discount") }}
                </label>
              </div>
            </div>
            <!-- START:: DATE PICKER INPUT -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_date") }}
                </label>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    @input="dateMenu = false"
                    :allowed-dates="disablePastDates"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_time") }}
                </label>
                <v-dialog
                  ref="start_time_dialog"
                  v-model="start_time_modal"
                  :return-value.sync="data.start_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start_time_modal"
                    v-model="data.start_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_time_modal = false"
                    >
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_time_dialog.save(data.start_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- END:: DATE PICKER INPUT -->

            <!-- START:: DATE PICKER INPUT -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_date") }}
                </label>
                <v-menu
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    @input="dateMenu2 = false"
                    :allowed-dates="disablePastDates2"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_time") }}
                </label>
                <v-dialog
                  ref="dialog"
                  v-model="end_time_modal"
                  :return-value.sync="data.end_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end_time_modal"
                    v-model="data.end_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_time_modal = false">
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(data.end_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- END:: DATE PICKER INPUT -->

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">نوع الخصم</label>
                <multiselect
                  v-model="data.discount_type"
                  :options="discount_types"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <!-- START:: APPLAY COUPOUN ON -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.addition_options") }}
                </label>
                <multiselect
                  v-model="data.addition_option"
                  :options="addition_options"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- END:: APPLAY COUPOUN ON -->

            <!-- START:: APPLAY COUPOUN ON -->
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.applay_coupoun_on") }}
                </label>
                <multiselect
                  v-model="data.applay_on"
                  :options="appliedCoupoun"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- END:: APPLAY COUPOUN ON -->
            <!-- {{ data.apply_ids.flat() }} -->
            <!-- Start:: Products -->
            <div class="row" v-if="data.applay_on">
              <template
                v-if="
                  data.applay_on.id == 'special_products' ||
                  data.applay_on.id == 'except_products'
                "
              >
                <div
                  class="col-lg-12 py-0"
                  v-for="(row, index) in data.products_rows"
                  :key="row.id"
                >
                  <div class="row">
                    <div class="col-11">
                      <div class="productRow">
                        <div class="input_wrapper top_label">
                          <label class="form-label">
                            {{ $t("forms.labels.product") }}
                          </label>

                          <multiselect
                            v-model="row.product"
                            :options="products"
                            :custom-label="nameWithCode"
                            track-by="id"
                            placeholder=" "
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                            @input="getProductDetails(index, row.product.id)"
                          ></multiselect>
                        </div>
                        <div v-if="row.product_details_show.length">
                          <h5>الخصائص</h5>

                          <main>
                            <v-data-table
                              class="elevation-1 thumb diff_table"
                              :headers="headers"
                              :items="row.product_details_show"
                              disable-pagination
                              item-key="id"
                              :show-select="true"
                              v-model="row.selected"
                              hide-default-footer
                            >
                              <!-- ================== You Can use any slots you want ================== -->

                              <template v-slot:no-data>
                                {{ $t("table.noData", { name: "بروموكود" }) }}
                              </template>

                              <template v-slot:[`item.item`]="{ item }">
                                <div class="detail">
                                  <div class="productRow mb-0 w-100">
                                    <div class="detail_show w-100">
                                      <span class="rate">
                                        {{ item.rate_avg }}
                                        <span><i class="fas fa-star"></i></span>
                                      </span>

                                      <div class="image">
                                        <span class="key">الصورة</span>

                                        <img
                                          v-if="item.media"
                                          :src="item.media[0].image"
                                          alt=""
                                        />
                                        <img
                                          v-else
                                          :src="item.product.image"
                                          alt=""
                                        />
                                      </div>

                                      <div class="color">
                                        <span class="key">اللون</span>
                                        <v-chip
                                          :color="
                                            item.color
                                              ? item.color.hex
                                              : 'لا يوجد'
                                          "
                                          class="value"
                                        ></v-chip>
                                      </div>

                                      <div class="size">
                                        <span class="key">الكمية</span>
                                        <v-chip
                                          color="#0E3958"
                                          class="value text-white"
                                        >
                                          {{
                                            item.quantity
                                              ? item.quantity
                                              : "لا يوجد"
                                          }}
                                        </v-chip>
                                      </div>

                                      <div class="size">
                                        <div class="size">
                                          <span class="key">الحجم</span>
                                          <v-chip
                                            color="#0E3958"
                                            class="value text-white"
                                          >
                                            {{
                                              item.size
                                                ? item.size.name
                                                : "لا يوجد"
                                            }}
                                          </v-chip>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </v-data-table>
                          </main>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 py-0 d-flex justify-center align-center">
                      <span
                        v-if="index == data.products_rows.length - 1"
                        class="append mx-1"
                        @click="appendProductRow"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </span>

                      <span
                        v-if="data.products_rows.length > 1"
                        class="append mx-1"
                        @click="deleteProductRow(index)"
                      >
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- End:: Products -->

            <!-- Start:: Categories -->
            <div class="row" v-if="data.applay_on">
              <template
                v-if="
                  data.applay_on.id == 'special_categories' ||
                  data.applay_on.id == 'except_categories'
                "
              >
                <div
                  class="col-lg-12 py-0"
                  v-for="(row, index) in data.categories_rows"
                  :key="row.id"
                >
                  <div class="row">
                    <div class="col-11 productRow">
                      <div class="row">
                        <div class="col-lg-6 py-0">
                          <div class="input_wrapper top_label">
                            <label class="form-label">
                              {{ $t("forms.labels.category") }}
                            </label>
                            <multiselect
                              v-model="row.category"
                              :options="categories"
                              label="name"
                              track-by="id"
                              placeholder=" "
                              :searchable="true"
                              :allow-empty="true"
                              :show-labels="false"
                              @input="getSubCategories(row.category.id)"
                            ></multiselect>
                          </div>
                        </div>

                        <div class="col-lg-6 py-0">
                          <div
                            class="input_wrapper top_label"
                            :class="{
                              disabled:
                                data.categories_rows[index].category == null,
                            }"
                          >
                            <label class="form-label">
                              {{ $t("forms.labels.sub_category") }}
                            </label>
                            <multiselect
                              multiple
                              v-model="
                                data.categories_rows[index].selected_categories
                              "
                              :options="sub_categories"
                              :custom-label="subCatWithParent"
                              track-by="id"
                              placeholder=" "
                              :searchable="true"
                              :allow-empty="true"
                              :show-labels="false"
                              @input="
                                all_apply_ids(
                                  data.categories_rows[index]
                                    .selected_categories,
                                  index
                                )
                              "
                            ></multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 py-0 d-flex justify-center align-center">
                      <span
                        v-if="index == data.categories_rows.length - 1"
                        class="append mx-1"
                        @click="appendCategoryRow"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </span>

                      <span
                        v-if="data.categories_rows.length > 1"
                        class="append mx-1"
                        @click="deleteCategoryRow(index)"
                      >
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- End:: Categories -->

            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->

            <!-- Start:: Is Active -->
            <!-- <div class="col-lg-6 py-0">
              <v-radio-group v-model="data.discount_type" row color="#339989">
                <span>نوع الخصم</span>
                <v-radio label="كمية" value="value"></v-radio>
                <v-radio label="نسبة" value="percentage"></v-radio>
              </v-radio-group>
            </div> -->
            <!-- End:: Is Active -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["id"],

  data() {
    return {
      dateMenu: false,
      dateMenu2: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.promo_code.title"),
          disabled: false,
          href: "/promo-code",
        },
        {
          text: this.$t("breadcrumb.promo_code.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image: null,
        code: null,
        use: null,
        discount: null,
        end_date: null,
        start_date: null,
        is_active: false,
        discount_type: null,
        applay_on: null,
        max_discount: null,
        max_users: null,
        use: null,
        addition_option: null,
        apply_ids: [],
        products_rows: [
          {
            product: null,
            product_details_show: [],
            product_details_ids: [],
            selected: [],
          },
        ],
        categories_rows: [
          {
            category: null,
            selected_categories: [],
          },
        ],
        start_time: null,
        end_time: null,
      },

      products: [],
      // Fixed Data
      categories: [],
      sub_categories: [],
      start_time_modal: false,
      end_time_modal: false,
      addition_options: [
        {
          id: "free_shipping",
          name: "توصيل مجاني",
        },
      ],
      last_categories: [],
      appliedCoupoun: [
        {
          id: "all",
          name: this.$t("appliedCoupoun.all"),
        },
        {
          id: "special_products",
          name: this.$t("appliedCoupoun.special_products"),
        },
        {
          id: "except_products",
          name: this.$t("appliedCoupoun.except_products"),
        },
        {
          id: "special_categories",
          name: this.$t("appliedCoupoun.special_categories"),
        },
        {
          id: "except_categories",
          name: this.$t("appliedCoupoun.except_categories"),
        },
      ],
    };
  },

  computed: {
    discount_types() {
      return [
        {
          id: "value",
          name: "قيمة",
        },
        {
          id: "percentage",
          name: "نسبة من اجمالي المشتريات",
        },
      ];
    },
    headers() {
      return [
        {
          text: "المنتج",
          align: "center",
          value: "item",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    uplodeImg_1(obj) {
      this.data.image = obj;
    },
    all_apply_ids(ids, index) {
      if (this.data.applay_on.id != "all") {
        this.data.apply_ids[index] = ids;
      }
    },

    //Products
    appendProductRow() {
      this.data.products_rows.push({
        product: null,
        product_details_show: [],
        product_details_ids: [],
        selected: [],
      });
    },
    deleteProductRow(index) {
      this.data.products_rows.splice(index, 1);
    },

    getProductDetails(index, id) {
      this.$axios({
        method: "GET",
        url: `products/${id}`,
      }).then((res) => {
        this.data.products_rows[index].product_details_ids = [];
        this.data.products_rows[index].product_details_show =
          res.data.data.product_details;
      });
    },

    appendCategoryRow() {
      this.data.categories_rows.push({
        category: null,
        selected_categories: [],
      });
    },
    deleteCategoryRow(index) {
      this.data.categories_rows.splice(index, 1);
      this.data.apply_ids.splice(index, 1);
    },

    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    disablePastDates2(val) {
      return val >= new Date(this.data.start_date).toISOString().substr(0, 10);
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.code) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.code"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (+this.data.max_users > +this.data.use) {
        this.$iziToast.error({
          timeout: 3000,
          message:
            "عدد مرات الاستخدام يجب ان يكون اكبر من اقصي عدد استخدام لليوزر الواحد",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.use) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.use"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.max_users) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.max_users"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.discount) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.discount"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.max_discount) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.max_discount"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.applay_on) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.applay_coupoun_on"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("_method", "PUT");
      if (this.data.image?.img_file) {
        submit_data.append("image", this.data.image?.img_file);
      }
      submit_data.append("code", this.data.code);
      submit_data.append(
        "start_at",
        `${this.data.start_date} ${this.data.start_time}`
      );
      submit_data.append(
        "end_at",
        `${this.data.end_date} ${this.data.end_time}`
      );
      submit_data.append("discount_amount", this.data.discount);

      submit_data.append("discount_type", this.data.discount_type.id);
      submit_data.append("max_discount", this.data.max_discount);
      submit_data.append("max_used_num", this.data.use);
      submit_data.append("max_used_for_user", this.data.max_users);
      submit_data.append("is_active", +this.data.is_active);
      submit_data.append("applly_coupon_on", this.data.applay_on.id);
      if (this.data.addition_option) {
        submit_data.append("addtion_options", this.data.addition_option?.id);
      }

      if (
        this.data.applay_on.id == "special_categories" ||
        this.data.applay_on.id == "except_categories"
      ) {
        [...new Set(this.data.apply_ids)].map((item, index) => {
          submit_data.append(`apply_ids[${index}]`, item);
        });
      }
      if (
        this.data.applay_on.id == "except_products" ||
        this.data.applay_on.id == "special_products"
      ) {
        let ids = [];
        if (this.data.products_rows[0].selected.length) {
          this.data.products_rows.map((el) => {
            el.selected.map((el) => {
              ids.push(el.id);
            });
          });
        } else {
          this.$iziToast.error({
            timeout: 2000,
            message: "ادخل منتجات العرض",
            position: "bottomRight",
          });
        }

        if (ids.length) {
          [...new Set(ids)].map((item, index) => {
            submit_data.append(`apply_ids[${index}]`, item);
          });
        }
      }

      this.$axios({
        method: "POST",
        url: `coupon/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/promoCode" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
    // ====== Products
    getProducts() {
      this.$axios({
        method: "GET",
        url: `product/without_paginate`,
      }).then((res) => {
        this.products = res.data.data.map((item) => {
          return {
            id: item.id,
            code: item.code,
            name: item.name,
          };
        });
      });
    },
    subCatWithParent({ name, parent }) {
      return `${name} —  (${parent})`;
    },
    // ====== Main Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `categories`,
      }).then((res) => {
        this.categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },

    // ====== Sub Categories
    getSubCategories(id) {
      this.$axios({
        method: "GET",
        url: `category/${id}/third_level`,
      }).then((res) => {
        this.data.sub_categories = [];
        this.sub_categories = res.data.data.map((item) => {
          return {
            id: item.id,
            parent: item.parent.label,
            name: item.name,
          };
        });
      });
    },
    getLastCategories() {
      this.$axios({
        method: "GET",
        url: `last-categories`,
      }).then((res) => {
        this.last_categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    // ====== Get Coupoun
    getCopoun() {
      this.$axios({
        method: "GET",
        url: `coupon/${this.id}`,
      }).then((res) => {
        this.data.image = res.data.data.image;
        this.data.code = res.data.data.code;
        this.data.start_date = res.data.data.start_at;
        this.data.end_date = res.data.data.end_at;
        this.data.start_time = res.data.data.start_time;
        this.data.end_time = res.data.data.end_time;
        this.data.discount = res.data.data.discount_amount;
        this.data.max_discount = res.data.data.max_discount;
        this.data.use = res.data.data.max_used_num;
        this.data.is_active = res.data.data.is_active;
        this.data.max_users = res.data.data.max_used_for_user;
        let allProduct = null;

        if (
          res.data.data.applly_coupon_on == "special_products" ||
          res.data.data.applly_coupon_on == "except_products"
        ) {
          this.data.products_rows = [];

          res.data.data.apply_on_product.map((el) => {
            this.data.products_rows.push({
              product: el.product,
              product_details_show: el.product_details_show,
              product_details_ids: el.product_details_ids,
              selected: el.product_details_show,
            });
          });
        } else {
          allProduct = res.data.data.applly_on_category;
        }
        if (allProduct) {
          this.getProductAll(allProduct, res.data.data.applly_coupon_on);
        }
        let discount_type_name = "";
        let applly_coupon_on_name = "";
        if (res.data.data.discount_type == "percentage") {
          discount_type_name = "نسبة";
        } else {
          discount_type_name = "كمية";
        }

        if (res.data.data.applly_coupon_on == "all") {
          applly_coupon_on_name = this.$t("appliedCoupoun.all");
        } else if (res.data.data.applly_coupon_on == "special_products") {
          applly_coupon_on_name = this.$t("appliedCoupoun.special_products");
        } else if (res.data.data.applly_coupon_on == "except_products") {
          applly_coupon_on_name = this.$t("appliedCoupoun.except_products");
        } else if (res.data.data.applly_coupon_on == "special_categories") {
          applly_coupon_on_name = this.$t("appliedCoupoun.special_categories");
        } else if (res.data.data.applly_coupon_on == "except_categories") {
          applly_coupon_on_name = this.$t("appliedCoupoun.except_categories");
        }

        this.data.discount_type = {
          id: res.data.data.discount_type,
          name: discount_type_name,
        };

        if (this.data.addition_option) {
          this.data.addition_option = this.addition_options.find(
            (el) => el.id == res.data.data.addtion_options
          );
        }
        this.data.applay_on = {
          id: res.data.data.applly_coupon_on,
          name: applly_coupon_on_name,
        };
      });
    },
    resetAll() {
      this.data.products_rows = {
        product: null,
        product_details_show: [],
        product_details_id: "",
      };
      this.data.categories_rows = {
        category: null,
        selected_categories: [],
      };
    },
    nameWithCode({ name, code }) {
      return `${name} — كود ${code}`;
    },
    getProductAll(data, type) {
      if (type == "special_products") {
        let ids = [];
        let selectData = [];
        let newData = [];
        if (data) {
          data.map((el) => {
            ids.push(...el.product_details_id);
            selectData.push(...el.product_details_show);
          });
        }

        if (selectData.length) {
          ids.map((el) => {
            selectData.filter((ele) => {
              if (ele.id == el) {
                newData.push(ele);
              }
            });
          });
        }
        if (newData.length) {
          this.data.products_rows[0].product_details_show = newData;
        }
      } else {
        this.data.categories_rows = [];
        let newData = [];
        let sub_categories = [];
        this.data.apply_ids = [];
        data.map((el) => {
          sub_categories.push(el.root_id);
          this.data.apply_ids.push(el.category_id);
          let obj = {};
          const found = newData.find((ele) => ele.root_id == el.root_id);
          if (found) {
            found.category_ids.push(el.category_id);
          } else {
            obj["root_id"] = el.root_id;
            obj["category_ids"] = [el.category_id];
            newData.push(obj);
          }
        });
        [...new Set(sub_categories)].map((el) => this.getSubCategories(el));

        newData.map((el) => {
          this.data.categories_rows.push({
            category: this.categories.filter((item) => item.id == el.root_id),
            selected_categories: el.category_ids.map(
              (selectd) =>
                this.last_categories.filter((item) => item.id == selectd)[0]
            ),
          });
        });
      }
    },
  },
  mounted() {
    this.getCopoun();
  },
  created() {
    this.getProducts();
    this.getLastCategories();
    this.getCategories();
  },
};
</script>

<style lang="scss">
.productRow {
  border: 1px solid #339989;
  border-radius: 15px;
  padding: 10px 10px 20px 10px;
  margin-bottom: 20px;

  .detail {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .detail_show {
      border: 1px solid #339989;
      padding: 10px;
      flex-grow: 1;
      border-radius: 15px;
      position: relative;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .rate {
        color: gray;
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 13px;

        span {
          color: yellow;
        }
      }

      .image {
        img {
          max-height: 50px;
        }
      }

      .color,
      .size {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .key {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
