<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Start:: Start Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_date") }}
                </label>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    @input="dateMenu = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_time") }}
                </label>
                <v-dialog
                  ref="start_time_dialog"
                  v-model="start_time_modal"
                  :return-value.sync="data.start_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start_time_modal"
                    v-model="data.start_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_time_modal = false"
                    >
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_time_dialog.save(data.start_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- End:: Start Date -->

            <!-- Start:: End Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_date") }}
                </label>
                <v-menu
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    @input="dateMenu2 = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_time") }}
                </label>
                <v-dialog
                  ref="dialog"
                  v-model="end_time_modal"
                  :return-value.sync="data.end_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end_time_modal"
                    v-model="data.end_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_time_modal = false">
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(data.end_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- End:: End Date -->

            <!-- Start:: Products -->
            <div
              class="col-lg-12 py-0"
              v-for="(row, index) in data.products_rows"
              :key="row.id"
            >
              <div class="row">
                <div class="col-11 productRow">
                  <div class="row">
                    <div class="col-12 py-0">
                      <div class="productRow">
                        <div class="input_wrapper top_label">
                          <label class="form-label">
                            {{ $t("forms.labels.product") }}
                          </label>
                          <multiselect
                            v-model="row.product"
                            :options="products"
                            label="name"
                            track-by="id"
                            placeholder=" "
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                            @input="getProductDetails(index, row.product.id)"
                          ></multiselect>
                        </div>

                        <h5 v-if="row.product_details_show.length > 0">
                          الخصائص
                        </h5>
                        <!-- {{filter(
                            (x) => x.id != row.product_details_id
                          )}} -->
                        <div
                          class="detail"
                          v-for="detail in row.product_details_show"
                          :key="detail.detail"
                        >
                          <v-checkbox
                            color="#339989"
                            v-model="row.product_details_id"
                            :value="detail.id"
                            @change="updatePrice(index, detail.price)"
                          ></v-checkbox>
                          <div class="detail_show">
                            <span class="rate">
                              {{ detail.rate_avg }}
                              <span><i class="fas fa-star"></i></span>
                            </span>

                            <div class="image">
                              <span class="key">الصورة</span>

                              <img :src="detail.media[0].image" alt="" />
                            </div>

                            <div class="color">
                              <span class="key">اللون</span>
                              <v-chip
                                :color="
                                  detail.color ? detail.color.hex : 'لا يوجد'
                                "
                                class="value"
                              ></v-chip>
                            </div>

                            <div class="size">
                              <span class="key">الكمية</span>
                              <v-chip color="#339989" class="value">
                                {{
                                  detail.quantity ? detail.quantity : "لا يوجد"
                                }}
                              </v-chip>
                            </div>

                            <div class="size">
                              <div class="size">
                                <span class="key">الحجم</span>
                                <v-chip color="#339989" class="value">
                                  {{
                                    detail.size ? detail.size.name : "لا يوجد"
                                  }}
                                </v-chip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" :class="{ disabled: !row.product }">
                      <div class="col-lg-3 py-0">
                        <div class="input_wrapper top_label">
                          <input
                            @input="update_discount_price(index)"
                            type="number"
                            class="form-control"
                            v-model.trim="row.discount_amount"
                          />
                          <label for="name_input" class="form-label">
                            قيمة الخصم
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-3 py-0">
                        <div class="input_wrapper top_label">
                          <label class="form-label">نوع الخصم</label>
                          <multiselect
                            v-model="row.discount_type"
                            :options="discount_types"
                            label="name"
                            track-by="id"
                            placeholder=" "
                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                            @input="update_discount_price(index)"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-lg-3 py-0">
                        <div class="input_wrapper top_label">
                          <input
                            @input="update_discount_price(index)"
                            type="number"
                            class="form-control"
                            v-model.trim="row.price_before"
                          />
                          <label for="name_input" class="form-label">
                            السعر قبل الخصم
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-3 py-0">
                        <div class="input_wrapper top_label">
                          <input
                            type="text"
                            class="form-control"
                            v-model.trim="row.price_after"
                            @input="update_discount_price(index)"
                          />
                          <label for="name_input" class="form-label">
                            السعر بعد الخصم
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-4 py-0">
                        <div class="input_wrapper top_label">
                          <input
                            type="number"
                            class="form-control"
                            @input="preventMinus"
                            min="0"
                            v-model.trim="row.quantity"
                          />
                          <label for="name_input" class="form-label">
                            الكمية
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-4 py-0">
                        <div class="input_wrapper top_label">
                          <input
                            type="number"
                            class="form-control"
                            @input="preventMinus"
                            min="0"
                            v-model.trim="row.quantity_for_user"
                          />
                          <label for="name_input" class="form-label">
                            الكمية للعميل
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-4 py-0">
                        <div class="input_wrapper top_label">
                          <input
                            type="number"
                            class="form-control"
                            @input="preventMinus"
                            min="0"
                            v-model.trim="row.ordering"
                          />
                          <label for="name_input" class="form-label">
                            الترتيب
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1 py-0 d-flex justify-center align-center">
                  <span
                    v-if="index == data.products_rows.length - 1"
                    class="append mx-1"
                    @click="appendProductRow"
                  >
                    <i class="fas fa-plus-circle"></i>
                  </span>

                  <span
                    v-if="data.products_rows.length > 1"
                    class="append mx-1"
                    @click="deleteProductRow(index)"
                  >
                    <i class="fas fa-minus-circle"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- End:: Products -->

            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      dateMenu: false,
      dateMenu2: false,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.flash-sales.title"),
          disabled: false,
          href: "/flash-sales",
        },
        {
          text: this.$t("breadcrumb.flash-sales.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        start_date: null,
        end_date: null,
        is_active: true,
        start_time: null,
        end_time: null,
        products_rows: [
          {
            quantity: null,
            quantity_for_user: null,
            ordering: null,
            discount_type: null,
            discount_amount: null,
            price_before: null,
            price_after: null,
            product: null,
            product_details_show: [],
            product_details_id: "",
          },
        ],
      },
      start_time_modal: false,
      end_time_modal: false,
      // ========== Fixed Data
      products: [],
    };
  },

  computed: {
    discount_types() {
      return [
        {
          id: "value",
          name: "كمية",
        },
        {
          id: "percentage",
          name: "نسبة",
        },
      ];
    },
  },

  methods: {
    appendProductRow() {
      this.data.products_rows.push({
        quantity: null,
        quantity_for_user: null,
        ordering: null,
        discount_type: null,
        discount_amount: null,
        price_before: null,
        price_after: null,
        product: null,
        product_details_show: [],
        product_details_id: "",
      });
    },
    deleteProductRow(index) {
      this.data.products_rows.splice(index, 1);
    },

    getProductDetails(index, id) {
      this.data.products_rows[index].product_details_show = [];
      this.$axios({
        method: "GET",
        url: `products/${id}`,
      }).then((res) => {
        this.data.products_rows[index].product_details_show =
          res.data.data.product_details;

        this.data.products_rows[index].price_before =
          res.data.data.product_details[0].price;
      });
    },

    updatePrice(rowIndex, price) {
      let current_row = this.data.products_rows[rowIndex];
      current_row.price_before = price;
    },

    update_discount_price(rowIndex) {
      let current_row = this.data.products_rows[rowIndex];

      if (current_row.discount_type?.id == "value") {
        current_row.price_after =
          current_row.price_before - current_row.discount_amount;
        return;
      }

      if (current_row.discount_type?.id == "percentage") {
        current_row.price_after =
          current_row.price_before -
          (current_row.price_before * current_row.discount_amount) / 100;
        return;
      }
    },
    // ====== Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;
      for (let row of this.data.products_rows) {
        if (!row.discount_amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.discount"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.discount_type) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.discount_type"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.price_before) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.price_before"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.price_after) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.price_after"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.quantity) {
          this.$iziToast.error({
            timeout: 2000,
            message: "الكمية مطلوبة",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.quantity_for_user) {
          this.$iziToast.error({
            timeout: 2000,
            message: "الكمية للعميل مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!row.ordering) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("forms.validation.ordering"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (row.quantity_for_user == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "الكمية للعميل يجب ان تكون اكبر من صفر",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (row.quantity == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "الكمية  يجب ان تكون اكبر من صفر",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        }
      }

      if (!this.data.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // ====== Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append(
        "start_at",
        `${this.data.start_date} ${this.data.start_time}`
      );
      submit_data.append(
        "end_at",
        `${this.data.end_date} ${this.data.end_time}`
      );
      submit_data.append("is_active", +this.data.is_active);

      this.data.products_rows.forEach((row, index) => {
        submit_data.append(
          `flash_sale_products[${index}][product_detail_id]`,
          row.product_details_id
        );
        submit_data.append(
          `flash_sale_products[${index}][quantity]`,
          row.quantity
        );
        submit_data.append(
          `flash_sale_products[${index}][quantity_for_user]`,
          row.quantity_for_user
        );
        submit_data.append(
          `flash_sale_products[${index}][ordering]`,
          row.ordering
        );
        submit_data.append(
          `flash_sale_products[${index}][discount_type]`,
          row.discount_type.id
        );
        submit_data.append(
          `flash_sale_products[${index}][discount_amount]`,
          row.discount_amount
        );
        submit_data.append(
          `flash_sale_products[${index}][price_before]`,
          row.price_before
        );
        submit_data.append(
          `flash_sale_products[${index}][price_after]`,
          row.price_after
        );
      });

      this.$axios({
        method: "POST",
        url: "flash-sale",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/flash-sales" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },

    // ====== Products
    getProducts() {
      this.$axios({
        method: "GET",
        url: `product/without_paginate`,
      }).then((res) => {
        this.products = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
  },

  created() {
    this.getProducts();
  },
};
</script>

<style scoped lang="scss">
.productRow {
  border: 1px solid #339989;
  border-radius: 15px;
  padding: 10px 10px 20px 10px;

  .detail {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .detail_show {
      border: 1px solid #339989;
      padding: 10px;
      flex-grow: 1;
      border-radius: 15px;
      position: relative;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .rate {
        color: gray;
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 13px;

        span {
          color: yellow;
        }
      }

      .image {
        img {
          max-height: 50px;
        }
      }

      .color,
      .size {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .key {
        margin-bottom: 5px;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
</style>
