<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <MainLoader key="loader" v-if="loading" />
      <!-- End Main Loader -->

      <div key="main" v-else>
        <!-- Start Statistics Card-->
        <div class="row mb-5">
          <!--START:: SUB TITLE-->
          <div class="col-lg-12 py-0">
            <!------Bank---------->
            <div class="divider">
              <h4>بيانات البنك</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>
          <!--START:: SUB TITLE-->
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="رقم الحساب"
                :number="allData.account_number"
              ></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="اسم البنك"
                :number="allData.bank_name"
              ></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="فرع البنك"
                :number="allData.branch"
              ></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card title="المدينة" :number="allData.city"></stats-card>
            </div>
          </div>
          <!------Client---------->
          <!--START:: SUB TITLE-->
          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>بيانات العميل</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>
          <!--START:: SUB TITLE-->
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="رقم الحساب"
                :number="allData.client.fullname"
              ></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="البريد الالكتروني"
                :number="allData.client.email"
              ></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="رقم الهاتف"
                :number="allData.client.phone"
              ></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="الدولة"
                :number="allData.client.country.ar.name"
              ></stats-card>
            </div>
          </div>
          <!------Transaction---------->
          <!--START:: SUB TITLE-->
          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>بيانات العملية</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="المبلغ"
                :number="allData.amount + ' ' + allData.currency"
              ></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card title="الحالة" :number="allData.status"></stats-card>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xxl-3">
            <div class="wrraper">
              <stats-card
                title="التاريخ"
                :number="allData.created_at"
              ></stats-card>
            </div>
          </div>
          <div
            class="col-sm-6 col-md-4 col-xxl-3"
            v-if="allData.rejected_reason"
          >
            <div class="wrraper">
              <stats-card
                title="سبب الرفض"
                :number="allData.rejected_reason"
              ></stats-card>
            </div>
          </div>
          <div class="col-lg-12 py-0" v-if="allData.status == 'pending'">
            <div class="divider">
              <h4>تغير الحالة</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
            <div class="btns_actions">
              <v-btn
                depressed
                color="success"
                large
                @click="changeStatus('accepted')"
              >
                قبول
              </v-btn>
              <v-btn
                depressed
                color="error"
                large
                @click="toggleDeleteModal(allData)"
              >
                رفض
              </v-btn>
            </div>
          </div>
        </div>
        <!-- End Statistics Card-->
        <!-- Start:: Text Content Modal -->
        <change-status
          @toggleModal="toggleDeleteModal"
          :modalIsActive="deleteModalIsOpen"
          :elementIdToGet="deleteItemToShow"
          routeToDirect="transactions/all-transactions"
          elementStatus="rejected"
          nameUrl="wallet/withdrawal"
        />

        <!-- End:: Text Content Modal -->
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Start:: Importing Modals

import ChangeStatus from '../../../components/Modals/ChangeStatus.vue'
// End:: Importing Modals

export default {
  props: ['id'],
  name: 'Withdraw',

  components: {
    ChangeStatus,
  },

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t('breadcrumb.mainPage'),
          disabled: false,
          href: '/',
        },
        {
          text: this.$t('breadcrumb.transaction.title'),
          disabled: true,
          href: '',
        },
        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: '',

      rejectedReason: '',

      deleteModalIsOpen: false,
      deleteItemToShow: null,

      // ========== Your Data
      allData: null, // injected in created

      // ========== Loading
      loading: false,
      reportReasonToShow: null,
    }
  },

  watch: {
    [`paginations.current_page`]() {
      this.setRows()
    },
  },

  computed: {
    ...mapGetters({
      lang: 'lang_module/lang',
    }),
  },

  methods: {
    // ===== Toggle Delete Modal =====
    toggleDeleteModal(item) {
      this.deleteItemToShow = item
      this.deleteModalIsOpen = !this.deleteModalIsOpen
    },

    // Set Rows
    setRows() {
      this.loading = true
      this.$axios({
        method: 'GET',
        url: 'wallet/withdrawal/' + this.id,
      })
        .then((res) => {
          this.allData = res.data.data

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    //Change Status
    changeStatus(status) {
      let submit_data = new FormData()
      submit_data.append('_method', 'PUT')
      submit_data.append('status', status)
      this.$axios({
        method: 'POST',
        url: 'wallet/withdrawal/' + this.id,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t('sendSuccess'),
            position: 'bottomRight',
          })
          this.$router.push({ path: '/transactions/all-transactions' })
          this.btnIsLoading = false
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: 'bottomRight',
          })
          this.btnIsLoading = false
        })
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows()
  },
}
</script>

<style lang="scss" scoped>
.btns_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 5px;
  }
}
</style>
