<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->
    <MainLoader v-if="loading"></MainLoader>
    <div class="custom_card fadeIn" v-else>
      <div class="card-header">
        <h4 class="card-title">{{ $t("edit") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Image -->
            <uplode-image
              @inputChanged="uplodeImg_1"
              placeHolder="صورة التصنيف"
              :image_src="data.preview"
              :data_src="data.image"
            ></uplode-image>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.image_alt_ar"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.image_alt_ar") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.image_alt_en"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.image_alt_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_en") }}
                </label>
              </div>
            </div>

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.slug"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_ar") }}
                </label>
              </div>
            </div> -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.slug"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_en") }}
                </label>
              </div>
            </div> -->

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.content_ar") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.ar.desc"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.content_en") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.en.desc"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.meta_title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_title_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.meta_title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_title_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.meta_description"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_description_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.meta_description"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_description_en") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.meta_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_tag_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.meta_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_tag_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.meta_canonical_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_canonical_tag") }}
                </label>
              </div>
            </div>

            <v-radio-group v-model="data.type" row>
              <v-radio label="اضافة ك قسم رئيسي" value="main"></v-radio>
              <v-radio
                label="اضافة ك قسم فرعي"
                @click="getCategories"
                value="sub"
              ></v-radio>
            </v-radio-group>

            <div class="col-lg-6 py-0" v-if="data.type == 'sub'">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.category") }}
                </label>
                <treeselect
                  dir="rtl"
                  :multiple="false"
                  placeholder=""
                  :options="categories"
                  :show-count="true"
                  v-model="data.category"
                  value-consists-of="LEAF_PRIORITY"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-center fs-6" v-if="data.type == 'sub'">
          <span class="status canceled">
            في حالة نقل القسم الفرعي الي قسم اخر يرجي مراجعه المنتجات المتعلقة
            به
          </span>
        </div>
        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  props: ["id"],

  data() {
    return {
      loading: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.categories.title"),
          disabled: false,
          href: "/categories",
        },
        {
          text: this.$t("breadcrumb.categories.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        preview: null,
        image: null,
        ar: {
          title: null,
          desc: null,
          // slug: null,
          meta_title: null,
          meta_tag: null,
          meta_description: null,
        },
        en: {
          title: null,
          desc: null,
          // slug: null,
          meta_title: null,
          meta_tag: null,
          meta_description: null,
        },
        type: "main",
        image_alt_ar: null,
        image_alt_en: null,
        meta_canonical_tag: null,
        category: null,
        sub_category: null,
      },

      // Fixed Data
      categories: [],
      sub_categories: [],
    };
  },

  methods: {
    // Uplode Image
    uplodeImg_1(obj) {
      this.data.image = obj;
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image_alt_ar) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image_alt_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.image_alt_en) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image_alt_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.title_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.title_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      }
      //  else if (!this.data.ar.slug) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.slug_ar"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // } else if (!this.data.en.slug) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.slug_en"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // }
      else if (!this.data.en.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.content_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.content_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_title_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_title_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_description) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_description_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_description) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_description_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_tag) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_tag_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_tag) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_tag_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.meta_canonical_tag) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_canonical_tag"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      this.btnIsLoading = true;
      if (this.data.type == "sub" && !this.data.category) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يجب اختيار قسم فرعي",
          position: "bottomRight",
        });
        this.btnIsLoading = false;

        return;
      } else {
        const submit_data = new FormData();
        if (this.data.image) {
          submit_data.append("image", this.data.image.img_file);
        }
        submit_data.append("ar[name]", this.data.ar.title);
        submit_data.append("ar[desc]", this.data.ar.desc);
        // submit_data.append("ar[slug]", this.data.ar.slug);
        submit_data.append("ar[meta_title]", this.data.ar.meta_title);
        submit_data.append("ar[meta_tag]", this.data.ar.meta_tag);
        submit_data.append(
          "ar[meta_description]",
          this.data.ar.meta_description
        );
        submit_data.append("en[name]", this.data.en.title);
        submit_data.append("en[desc]", this.data.en.desc);
        // submit_data.append("en[slug]", this.data.en.slug);
        submit_data.append("en[meta_title]", this.data.en.meta_title);
        submit_data.append("en[meta_tag]", this.data.en.meta_tag);
        submit_data.append(
          "en[meta_description]",
          this.data.en.meta_description
        );
        submit_data.append("meta_canonical_tag", this.data.meta_canonical_tag);
        submit_data.append("image_alt_ar", this.data.image_alt_ar);
        submit_data.append("image_alt_en", this.data.image_alt_en);

        if (this.data.type == "sub") {
          if (this.data.category) {
            submit_data.append("parent_id", this.data.category);
          }
        }
        if (this.data.type == "main") {
          submit_data.append("parent_id", "");
        }

        submit_data.append("_method", "PUT");

        this.$axios({
          method: "POST",
          url: `categories/${this.id}`,
          data: submit_data,
        })
          .then(() => {
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("editSuccess"),
              position: "bottomRight",
            });
            if (this.data.type == "sub") {
              this.$router.push({
                path: `/categories/${this.data.category}/sub-category`,
              });
            } else {
              this.$router.push({ path: "/categories" });
            }
            this.btnIsLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
            this.btnIsLoading = false;
          });
      }
    },

    recursion(items) {
      items.map((child, child_index) => {
        if (child.children) {
          if (child.children.length) {
            this.recursion(child.children);
          }
        }
        if (child.id == this.id) {
          items.splice(child_index, 1);
        }
      });
    },

    // ====== Main Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `category/treet`,
      }).then((res) => {
        // this.categories = res.data.data

        res.data.data.map((el, index) => {
          if (el.id == this.id) {
            res.data.data.splice(index, 1);
            // el.id != this.id
          } else {
            if (el.children) {
              if (el.children) {
                this.recursion(el.children);
              }
            }
          }
        });
        this.categories = res.data.data;
        // console.log(res.data.data)
      });
    },

    // ====== Sub Categories
    getSubCategories(id) {
      this.$axios({
        method: "GET",
        url: `categories?category_id=${id}`,
      }).then((res) => {
        this.data.sub_category = "";
        this.sub_categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },

    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: `categories/${this.id}`,
      }).then((res) => {
        this.loading = false;
        this.data.preview = res.data.data.image;

        this.data.ar.title = res.data.data.ar.name;
        this.data.ar.desc = res.data.data.ar.desc;
        // this.data.ar.slug = res.data.data.ar.slug;
        this.data.ar.meta_title = res.data.data.ar.meta_title;
        this.data.ar.meta_description = res.data.data.ar.meta_description;
        this.data.ar.meta_tag = res.data.data.ar.meta_tag;

        this.data.en.title = res.data.data.en.name;
        this.data.en.desc = res.data.data.en.desc;
        // this.data.en.slug = res.data.data.en.slug;
        this.data.en.meta_title = res.data.data.en.meta_title;
        this.data.en.meta_description = res.data.data.en.meta_description;
        this.data.en.meta_tag = res.data.data.en.meta_tag;

        this.data.meta_canonical_tag = res.data.data.meta_canonical_tag;
        this.data.image_alt_ar = res.data.data.image_alt_ar;
        this.data.image_alt_en = res.data.data.image_alt_en;

        if (res.data.data.parent) {
          this.data.category = res.data.data.parent.id;
          this.data.type = "sub";
        } else {
          this.data.type = "main";
        }
      });
    },
  },

  mounted() {
    this.getCategories();
    this.getData();
  },
};
</script>
