<template>
  <div class="wrraper custom_card">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />

    <!-- End Breadcrumb -->
    <MainLoader v-if="loading"></MainLoader>
    <div class="row" v-else>
      <!--Product Slider-->
      <div class="col-md-6">
        <div class="single_slider" v-if="products_details">
          <div class="slider_for">
            <vue-slick
              ref="c1"
              :asNavFor="c2"
              :focusOnSelect="true"
              :slidesToShow="1"
              :dots="false"
              :draggable="true"
              :arrows="false"
              @beforeChange="syncSliderBehavior"
            >
              <div v-for="media in products_details.media" :key="media.id">
                <img
                  :src="media.image"
                  :alt="media.image_alt_ar"
                  v-if="media.image"
                />
              </div>
            </vue-slick>
          </div>
          <div class="slider_nav">
            <vue-slick
              ref="c2"
              :asNavFor="c1"
              :focusOnSelect="true"
              :slidesToShow="4"
              :dots="false"
              :arrows="false"
              :draggable="true"
              @beforeChange="syncSliderBehavior"
            >
              <div v-for="media in products_details.media" :key="media.id">
                <img
                  :src="media.image"
                  :alt="media.image_alt_ar"
                  v-if="media.image"
                />
              </div>
            </vue-slick>
          </div>
        </div>
      </div>
      <!--Product details-->
      <div class="col-md-6">
        <div class="product_details" v-if="products_details">
          <!--Product Head-->
          <div class="product_head">
            <!--Title-->
            <div class="pro_det_tit">
              <h4>{{ allData.name }}</h4>
            </div>
            <!--Reviews-->
            <div class="review">
              <RatingStars :rate="products_details.rate_avg"></RatingStars>
              <span>({{ products_details.rate_avg }}) المراجعات</span>
            </div>
          </div>
          <hr class="line" />
          <!--Title-->
          <div class="pro_det_tit">
            <h4>الوان واحجام المنتج</h4>
          </div>
          <div class="product_features">
            <!-- ********** Start:: Product Colors ********** -->
            <div class="product_colors_wrapper" v-if="allData.colors">
              <div
                class="radio_input_wrapper"
                v-for="item in allData.colors"
                :key="item.id"
              >
                <input
                  :id="'color' + item.id"
                  class="radio_input"
                  type="radio"
                  name="product_colors"
                  :value="item.id"
                  v-model="selectedColor"
                  @change="getProductDetailsColor(selectedColor)"
                />
                <label
                  v-if="item.image"
                  class="radio_label"
                  :for="'color' + item.id"
                >
                  <img :src="item.image" alt="" width="25" height="25" />
                </label>
                <label
                  v-else
                  class="radio_label"
                  :for="'color' + item.id"
                  :style="`background: ${item.hex}`"
                ></label>
              </div>
            </div>
            <!-- ********** End:: Product Colors ********** -->
            <!-- ********** Start:: Product Sizes ********** -->
            <div class="product_sizes_wrapper" v-if="products_details.size">
              <div class="radio_input_wrapper">
                <input
                  :id="'size' + products_details.size.id"
                  class="radio_input"
                  type="radio"
                  name="product_sizes"
                  :value="'size' + products_details.size.id"
                  v-model="selectedSize"
                />
                <label
                  class="radio_label"
                  :for="'size' + products_details.size.id"
                >
                  {{ products_details.size.name }}
                </label>
              </div>
            </div>
            <!-- ********** End:: Product Sizes ********** -->
          </div>
          <hr class="line" />
          <!--Description-->
          <div class="product_desc">
            <!--Title-->
            <div class="pro_det_tit">
              <h4>وصف المنتج</h4>
            </div>
            <div class="desc">
              <p v-html="allData.desc"></p>
            </div>
            <div class="price_qun">
              <div>
                <!--Title-->
                <div class="pro_det_tit">
                  <h4>السعر</h4>
                </div>
                <div class="desc">
                  <v-chip color="#0e3958" text-color="#fff">
                    {{ products_details.price + " " + "جنيه" }}
                  </v-chip>
                </div>
              </div>
              <div>
                <!--Title-->
                <div class="pro_det_tit">
                  <h4>الكمية</h4>
                </div>
                <div class="desc">
                  <v-chip color="#0e3958" text-color="#fff">
                    {{ products_details.quantity }}
                  </v-chip>
                </div>
              </div>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  خصائص المنتج
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    class="specfication_card"
                    v-for="item in products_details.features"
                    :key="item.id"
                  >
                    <div class="key">
                      <span>{{ item.feature.name }}</span>
                    </div>
                    <div class="value">
                      <span>{{ item.value.value }}</span>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <!--Title-->
          <div v-if="products_details.rates.length">
            <div class="pro_det_tit">
              <h4>تقيمات المنتج</h4>
            </div>

            <UserRate rateNum="5"></UserRate>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-6">
        <UserRate name="Diaa Mashaly" rateNum="5" desc="منتج ممتاز"></UserRate>
      </div>
      <div class="col-md-6">
        <UserRate name="Diaa Mashaly" rateNum="5" desc="منتج ممتاز"></UserRate>
      </div>
      <div class="col-md-6">
        <UserRate name="Diaa Mashaly" rateNum="5" desc="منتج ممتاز"></UserRate>
      </div>
      <div class="col-md-6">
        <UserRate name="Diaa Mashaly" rateNum="5" desc="منتج ممتاز"></UserRate>
      </div>
    </div> -->
    <!-- Start Image_Model -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <!-- End Image_Model -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RatingStars from "@/components/UI/RatingStars.vue";
import UserRate from "@/components/Global/UserRate.vue";

export default {
  props: ["id"],
  components: {
    RatingStars,
    UserRate,
  },
  data() {
    return {
      selectedColor: null,
      selectedSize: null,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.products.title"),
          disabled: false,
          href: "/products",
        },
        {
          text: this.$t("breadcrumb.products.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      //Data
      allData: null,
      products_details: null,

      c1: undefined,
      c2: undefined, // ========== Loding
      loading: false,

      // ========== Table
    };
  },

  watch: {
    [`paginations.current_page`]() {},
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },
    //Get Prosuct
    getProduct() {
      this.$axios({
        method: "GET",
        url: `products/${this.id}/show`,
      }).then((res) => {
        this.allData = res.data.data;
      });
    },
    getProductDetails(index) {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: `products/${this.id}`,
      }).then((res) => {
        this.products_details = res.data.data.product_details[index];
        this.loading = false;
      });
    },
    getProductDetailsColor(id) {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: `products/${this.id}/show`,
        params: { color_id: id },
      }).then((res) => {
        this.loading = false;
        this.products_details = res.data.data.product_details[0];
      });
    },
    // Start:: Sync Slider Behavior Method
    syncSliderBehavior(_, nextPosition) {
      this.$refs.c1.goTo(nextPosition);
      this.$refs.c2.goTo(nextPosition);
    },
    // End:: Sync Slider Behavior Method
  },

  // ======= hooks
  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
    this.getProduct();
    this.getProductDetails(0);
  },
};
</script>

<style lang="scss" scoped>
span.color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}
</style>
