<template>
  <div class="teacher_data">
    <!-- Start Main Loader -->
    <transition name="fadeInUp" mode="out-in" v-if="!userInfo">
      <MainLoader />
    </transition>
    <!-- End Main Loader -->
    <div class="personal_data_wrapper fadeIn" v-else>
      <div class="row" v-if="userInfo">
        <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.name") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-if="userInfo.fullname"
              v-model.trim="userInfo.fullname"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control"
              value="لا يوجد"
              readonly
            />
          </div>
        </div>

        <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.phone") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-if="userInfo.phone"
              :value="userInfo.phone + ' - ' + '(' + userInfo.phone_code + ')'"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control"
              value="لا يوجد"
              readonly
            />
          </div>
        </div>
        <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">النوع</label>
            <input
              type="text"
              class="form-control"
              v-if="userInfo.gender_trans"
              v-model.trim="userInfo.gender_trans"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control"
              value="لا يوجد"
              readonly
            />
          </div>
        </div>
        <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.email") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-if="userInfo.email"
              v-model.trim="userInfo.email"
              readonly
            />
            <input
              v-else
              type="text"
              class="form-control"
              value="لا يوجد"
              readonly
            />
          </div>
        </div>

        <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.activation_status") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model.trim="isActive"
              readonly
            />
          </div>
        </div>
        <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">مفعل من الاداره</label>
            <input
              type="text"
              class="form-control"
              v-model.trim="isActiveManager"
              readonly
            />
          </div>
        </div>
        <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.is_ban") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model.trim="isBan"
              readonly
            />
          </div>
        </div>
        <!-- <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.wallet") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model.trim="userInfo.wallet"
              readonly
            />
          </div>
        </div> -->
        <div class="col-md-4 col-xxl-3" v-if="userInfo.country">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.country") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model.trim="userInfo.country.name"
              readonly
            />
          </div>
        </div>
        <div class="col-md-4 col-xxl-3" v-if="userInfo.country">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">
              {{ $t("forms.labels.city") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model.trim="userInfo.city.name"
              readonly
            />
          </div>
        </div>
        <!-- <div class="col-md-4 col-xxl-3">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label"> عدد الطلبات </label>
            <input
              type="text"
              class="form-control"
              v-model.trim="userInfo.order_count"
              readonly
            />
          </div>
        </div> -->

        <div class="col-lg-12" v-if="userInfo.ban_reason">
          <div class="input_wrapper top_label only_read my-2">
            <label class="form-label">سبب الحظر</label>
            <input
              type="text"
              class="form-control"
              v-model.trim="userInfo.ban_reason"
              readonly
            />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="qr-code">
            <img :src="userInfo.qr_image" alt="" />
          </div>
        </div>
        <!-- ban_reason -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["userInfo"],

  data() {
    return {
      data: {},
    };
  },

  computed: {
    isActive() {
      return this.userInfo.is_active ? this.$t("active") : this.$t("inactive");
    },

    isBan() {
      return this.userInfo.is_ban ? this.$t("ban") : this.$t("inban");
    },
    isActiveManager() {
      return this.userInfo.is_ban ? "غير مفعل" : " مفعل";
    },
  },

  methods: {},
};
</script>

<style></style>
