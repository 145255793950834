<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      search_route="/sliders/add"
      search_title="إضافه جديد"
      icon="fa-plus"
    />
    <!-- End Breadcrumb -->

    <!-- Start Main Loader -->
    <transition name="fadeInUp" mode="out-in" v-if="loaderPage">
      <MainLoader />
    </transition>
    <!-- End Main Loader -->

    <template class="fadeIn" v-else>
      <!-- Start Statistics Card-->
      <!-- <div class="row mb-5">
        <div class="wrraper">
          <stats-card
            :title="$t('slidersNum')"
            :number="statisticsItem.number"
          ></stats-card>
        </div>
      </div> -->
      <!-- End Statistics Card-->

      <!-- Start Main Section -->
      <main>
        <v-data-table
          class="elevation-1 thumb diff_table"
          :headers="headers"
          :items="rows"
          :search="search"
          :custom-filter="helper_filterSearch"
          :loading="loading"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- ====== Select row field ====== -->
          <!-- Image -->
          <template v-slot:[`item.images.ar`]="{ item }">
            <img @click="show_model_1" class="image" :src="item.images.ar" />
          </template>

          <!-- Image -->
          <template v-slot:[`item.images.en`]="{ item }">
            <img @click="show_model_1" class="image" :src="item.images.en" />
          </template>

          <!-- IsActive -->
          <template v-slot:[`item.is_active`]="{ item }">
            <span class="status" :class="item.is_active ? 'paid' : 'unpaid'">
              {{ item.is_active ? "فعال" : "غير فعال" }}
            </span>
          </template>

          <template v-slot:[`item.slider_name`]="{ item }">
            {{ item.ar.name + " - " + item.en.name }}
          </template>

          <template v-slot:[`item.category`]="{ item }">
            {{ item.category || "لا يوجد" }}
          </template>
          <template v-slot:[`item.link`]="{ item }">
            {{ item.link ? item.link.slice(0, 20) + "..." : "لا يوجد" }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.type ? item.type + "..." : "لا يوجد" }}
          </template>

          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData", { name: "سلايدر" }) }}
          </template>

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <!-- <v-icon class="show" small @click="showItem(item)">
              fal fa-eye
            </v-icon> -->
              <v-icon class="edit" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <h3 class="title table-title">الاسلايدر</h3>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  {{ $t("table.deletedialog.areYouSure") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm">
                    {{ $t("table.deletedialog.ok") }}
                  </v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">
                    {{ $t("table.deletedialog.cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete_selected" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  {{ $t("table.deletedialog.areYouSure") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteSelectedConfirm">
                    {{ $t("table.deletedialog.ok") }}
                  </v-btn>
                  <v-btn color="#F44336" @click="dialogDelete_selected = false">
                    {{ $t("table.deletedialog.cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add & Delete -->
            <v-row>
              <v-col cols="12" sm="8"></v-col>
            </v-row>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>
      <!-- End Main Section -->

      <!-- Start Pagination -->
      <template>
        <div
          class="pagination_container text-center mb-5 d-flex justify-content-end"
        >
          <!-- <div class="select-pagination d-flex">
            <span class="first">{{ $t('show') }}</span>
            <v-select
              :items="[5, 20, 50, 100]"
              v-model="paginations.items_per_page"
            ></v-select>
            <span>{{ $t('entries') }}</span>
          </div> -->

          <v-pagination
            color="primary"
            v-model="paginations.current_page"
            :length="paginations.last_page"
            :total-visible="5"
            @input="fetchData($event)"
          ></v-pagination>
        </div>
      </template>
      <!-- End Pagination -->
    </template>

    <!-- Start Image_Model -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <!-- End Image_Model -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // ========== Main Loader
      loaderPage: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.main_slider.title"),
          disabled: false,
          href: "/sliders",
        },
        {
          text: this.$t("breadcrumb.main_slider.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 20,
      },

      // ========== Loding
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "الصورة (عربي)",
            align: "center",
            value: "images.ar",
            sortable: false,
          },
          {
            text: "الصورة (بالإنجليزي)",
            align: "center",
            value: "images.en",
            sortable: false,
          },
          {
            text: "القسم الأساسي",
            align: "center",
            value: "category_id.name",
            sortable: false,
          },
          {
            text: "النوع",
            align: "center",
            value: "type",
            sortable: false,
          },
          {
            text: "الاسم",
            align: "center",
            value: "slider_name",
            sortable: false,
          },
          {
            text: " حالة التفعيل ",
            value: "is_active",
            align: "center",
          },
          {
            text: "التحكم",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "Image", // the name to display
            align: "center", // [center, start]
            value: "image", // Key must be equal to the name in rows objects
            sortable: false,
          },
          {
            text: "content (ar)",
            value: "ar.desc",
            align: "center",
          },
          {
            text: "content (en)",
            value: "en.desc",
            align: "center",
          },
          { text: "title (ar)", value: "ar.name", align: "center" },
          {
            text: "title (en)",
            value: "en.name",
            align: "center",
          },
          {
            text: "hashtag",
            value: "hashtag.name",
            align: "center",
          },
          {
            text: "control",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ];
      }
    },
  },

  methods: {
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/sliders/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/sliders/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/sliders/edit/" + item.id });
    },

    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `slider/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "POST",
        url: `slider/deleteAll`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.loaderPage = true;
      this.loading = true;

      this.$axios({
        method: "GET",
        url: "slider",
        params: { page: this.paginations.current_page },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.statisticsItem.number = res.data.meta.total;

          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;

          this.loading = false;
          this.loaderPage = false;
        })
        .catch((err) => {
          this.loading = false;
          this.loaderPage = false;
        });
    },
    fetchData(e) {
      this.$router.replace({ query: { page: e } });
      this.setRows();
    },
  },
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
};
</script>

<style lang="scss" scoped></style>
