<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t('edit') }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-6 py-0">
              <label class="form-label text-center">
                {{ $t('forms.labels.image_ar') }}
              </label>
              <uplode-image
                @inputChanged="uplodeImg_1"
                :data_src="data.image_ar"
                :image_src="data.preview_ar"
              ></uplode-image>
            </div>

            <div class="col-lg-6 py-0">
              <label class="form-label text-center">
                {{ $t('forms.labels.image_en') }}
              </label>
              <uplode-image
                @inputChanged="uplodeImg_2"
                :data_src="data.image_en"
                :image_src="data.preview_en"
              ></uplode-image>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t('forms.labels.title_ar') }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t('forms.labels.title_en') }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="textarea"
                  class="form-control"
                  v-model.trim="data.ar.desc"
                />
                <label for="name_input" class="form-label">
                  الوصف بالعربية
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="textarea"
                  class="form-control"
                  v-model.trim="data.en.desc"
                />
                <label for="name_input" class="form-label">
                  الوصف بالانجليزية
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ordering"
                />
                <label for="name_input" class="form-label">
                  {{ $t('forms.labels.ordering') }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">{{ $t('forms.labels.type') }}</label>

                <multiselect
                  v-model="data.bunnerType"
                  :options="types"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t('forms.labels.platform') }}
                </label>

                <multiselect
                  v-model="data.platform"
                  :options="platforms"
                  placeholder=" "
                  label="name"
                  track-by="value"
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t('forms.labels.category') }}
                </label>

                <multiselect
                  v-model="data.category"
                  :options="categories"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <div class="col-lg-12 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="indigo lighten-2"
              ></v-checkbox>
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1">
            {{ $t('forms.submit') }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Update',

  props: ['id'],

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t('breadcrumb.mainPage'),
          disabled: false,
          href: '/',
        },
        {
          text: this.$t('breadcrumb.main_slider.title'),
          disabled: false,
          href: '/sliders',
        },
        {
          text: this.$t('breadcrumb.main_slider.edit'),
          disabled: true,
          href: '',
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image_ar: null,
        image_en: null,
        preview_ar: null,
        preview_en: null,
        bunnerType: '',
        ar: {
          title: null,
          link_name: null,
          desc: null,
        },
        en: {
          title: null,
          link_name: null,
          desc: null,
        },
        ordering: null,
        is_active: false,
        link: null,
        category: null,
        platform: null,
        type: 'category',
      },
      platforms: [
        { value: 'all', name: 'الكل' },
        { value: 'website', name: 'الويب سايت' },
        { value: 'app', name: 'تطبيق الموبايل' },
      ],
      types: [
        {
          id: 'divided',
          name: 'منقسم',
        },
        {
          id: 'main',
          name: 'رئيسي',
        },
        {
          id: 'banner',
          name: 'بنر',
        },
      ],
      categories: [],
    }
  },

  methods: {
    // Uplode Image
    uplodeImg_1(obj) {
      this.data.image_ar = obj
    },
    uplodeImg_2(obj) {
      this.data.image_en = obj
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true

      // if (!this.data.image_ar?.img_file || !this.data.image_en?.img_file) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.image"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      if (!this.data.ar.title || !this.data.en.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t('forms.validation.title'),
          position: 'bottomRight',
        })
        this.btnIsLoading = false
      } else if (!this.data.ar.desc || !this.data.en.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t('forms.validation.desc'),
          position: 'bottomRight',
        })
        this.btnIsLoading = false
      } else {
        this.submitData()
        return
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData()
      if (this.data.image_ar) {
        submit_data.append('ar[image]', this.data.image_ar.img_file)
      }
      if (this.data.image_en) {
        submit_data.append('en[image]', this.data.image_en.img_file)
      }

      submit_data.append('ar[name]', this.data.ar.title || '')
      submit_data.append('en[name]', this.data.en.title || '')

      submit_data.append('en[desc]', this.data.en.desc || '')
      submit_data.append('ar[desc]', this.data.ar.desc || '' || '')

      submit_data.append('ordering', this.data.ordering)
      submit_data.append('platform', this.data.platform.value)

      if (this.data.category) {
        submit_data.append('category_id', this.data.category.id)
      }
      if (this.data.bunnerType != '') {
        submit_data.append('type', this.data.bunnerType.id)
      }
      submit_data.append('is_active', +this.data.is_active)
      submit_data.append('_method', 'PUT')

      this.$axios({
        method: 'POST',
        url: `slider/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t('editSuccess'),
            position: 'bottomRight',
          })
          this.$router.push({ path: '/sliders' })
          this.btnIsLoading = false
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: 'bottomRight',
          })
          this.btnIsLoading = false
        })
    },

    getCategories() {
      this.$axios({
        method: 'GET',
        url: `category/all`,
      }).then((res) => {
        this.categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          }
        })
      })
    },

    getData() {
      this.$axios({
        method: 'GET',
        url: `slider/${this.id}`,
      }).then((res) => {
        this.data.preview_ar = res.data.data.images.ar
        this.data.preview_en = res.data.data.images.en
        this.data.is_active = res.data.data.is_active
        this.data.ordering = res.data.data.ordering
        if (res.data.data.type) {
          if (res.data.data.type == 'divided') {
            this.data.bunnerType = {
              id: 'divided',
              name: 'منقسم',
            }
          } else if (res.data.data.type == 'main') {
            this.data.bunnerType = {
              id: 'main',
              name: 'رئيسي',
            }
          } else if (res.data.data.type == 'banner') {
            this.data.bunnerType = {
              id: 'banner',
              name: 'بنر',
            }
          }
        }
        if (res.data.data.platform) {
          if (res.data.data.platform == 'all') {
            this.data.platform = {
              value: 'all',
              name: 'الكل',
            }
          } else if (res.data.data.platform == 'website') {
            this.data.platform = {
              value: 'website',
              name: 'الويب سايت',
            }
          } else if (res.data.data.platform == 'app') {
            this.data.platform = {
              app: 'app',
              name: 'تطبيق الموبايل',
            }
          }
        }

        this.data.category = res.data.data.category_id

        this.data.ar.title = res.data.data.ar.name

        this.data.ar.desc = res.data.data.ar.desc

        this.data.en.title = res.data.data.en.name

        this.data.en.desc = res.data.data.en.desc

        this.getCategories()
      })
    },
  },

  created() {
    this.getData()
  },
}
</script>
