<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Name_ar -->
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.name_ar"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.name_ar")
                }}</label>
              </div>
            </div>

            <!-- Name_en -->
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.name_en"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.name_en")
                }}</label>
              </div>
            </div>

            <!-- Permissions -->
            <!-- Select -->
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("forms.labels.permissions")
                }}</label>
                <multiselect
                  v-model="data.selected_permissions"
                  :options="all_permissions"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  multiple
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                >
                </multiselect>
              </div>
            </div>
            <!-- Options -->
            <div class="permisions">
              <div
                v-for="permission in data.selected_permissions"
                :key="permission.id"
                class="col-lg-12 switch_part d-flex justify-content-between align-items-center"
              >
                <h4 class="m-0">{{ permission.name }} :</h4>

                <div class="input_wrapper checkbox_input">
                  <v-switch
                    v-model="permission.show.status"
                    :label="$t('show')"
                  ></v-switch>
                </div>

                <div class="input_wrapper checkbox_input">
                  <v-switch
                    v-model="permission.create.status"
                    :label="$t('add')"
                  ></v-switch>
                </div>

                <div class="input_wrapper checkbox_input">
                  <v-switch
                    v-model="permission.edit.status"
                    :label="$t('edit')"
                  ></v-switch>
                </div>

                <div class="input_wrapper checkbox_input">
                  <v-switch
                    v-model="permission.can_destroy"
                    :label="$t('delete')"
                  ></v-switch>
                </div>
              </div>
              <!-- --------------------- -->
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.permissions.title"),
          disabled: false,
          href: "/countries",
        },
        {
          text: this.$t("breadcrumb.permissions.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        name_ar: null,
        name_en: null,
        selected_permissions: [],
      },

      // ========== Fixed Data
      all_permissions: [],
    };
  },

  methods: {
    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.name_ar) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.name_en) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Get_Permissions
    get_permissions() {
      // Convert 'JSON' Object to JS Object
      const ALLPERMISSIONS = JSON.parse(JSON.stringify(this.$t("sidebar")));

      ALLPERMISSIONS.forEach((el) => {
        if (el.items) {
          let inside_nested = false;

          // Check Nested
          el.items.forEach((element) => {
            if (element.items) {
              inside_nested = true;
            }
          });

          // IF Nested Else
          if (inside_nested) {
            el.items.forEach((subEl) => {
              this.all_permissions.push({
                id: Math.random()
                  .toString(36)
                  .replace(/[^a-z]+/g, "")
                  .substr(0, 5),
                name: subEl.title,
                main_url: subEl.items[0].url,
                show: { url: `${subEl.items[0].url}/show`, status: false },
                create: { url: `${subEl.items[0].url}/add`, status: false },
                edit: { url: `${subEl.items[0].url}/edit`, status: false },
                can_destroy: false,
              });
            });
          } else {
            this.all_permissions.push({
              id: Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, "")
                .substr(0, 5),
              name: el.title,
              main_url: el.items[0].url,
              show: { url: `${el.items[0].url}/show`, status: false },
              create: { url: `${el.items[0].url}/add`, status: false },
              edit: { url: `${el.items[0].url}/edit`, status: false },
              can_destroy: false,
            });
          }
        }
      });

      // Remove First Obj [homePage]
      this.all_permissions.shift();
      // console.log(this.all_permissions);
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("ar[name]", this.data.name_ar);
      submit_data.append("en[name]", this.data.name_ar);

      this.$axios({
        method: "POST",
        url: "permissions",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/countries" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.get_permissions();
  },
};
</script>
