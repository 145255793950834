<template>
  <div class="create_wrapper custom_card">
    <!-- START:: CARD TITLE -->
    <div class="card-header">
      <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
    </div>
    <!-- END:: CARD TITLE -->

    <!-- START:: UPDATE FORM WRAPPER -->
    <form @submit.prevent="validateCreateForm">
      <div class="container">
        <div class="row justify-content-between">
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model="updateData.project_name"
              />
              <label class="form-label">اسم المشروع</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model="updateData.description_location"
              />
              <label class="form-label">وصف المكان</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.site_meta_title_ar"
              />
              <label class="form-label">عنوان ميتا (عربي)</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.site_meta_title_en"
              />
              <label class="form-label">عنوان ميتا (انجليزي)</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.site_meta_description_ar"
              />
              <label class="form-label">وصف ميتا (عربي)</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.site_meta_description_en"
              />
              <label class="form-label">وصف ميتا (انجليزي)</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.site_meta_tag_ar"
              />
              <label class="form-label">الكلمة المفتاحية (عربي)</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.site_meta_tag_en"
              />
              <label class="form-label">الكلمة المفتاحية (انجليزي)</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model="updateData.how_to_shop_ar"
              />
              <label class="form-label">كيفية التسوق باللغه العربية</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model="updateData.how_to_shop_en"
              />
              <label class="form-label">كيفية التسوق باللغة الانجليزية</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model="updateData.website_link"
              />
              <label class="form-label">لينك الموقع</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model="updateData.pinterest"
              />
              <label class="form-label">pinterest</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input"
                v-model="updateData.phone"
              />
              <label class="form-label">
                {{ $t("settings.contactData.phone") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="email"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model="updateData.email"
              />
              <label class="form-label">
                {{ $t("settings.contactData.email") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input3"
                @input="checkIfInputIsEmpty"
                v-model="updateData.whatsapp"
              />
              <label class="form-label">
                {{ $t("settings.contactData.whatsapp") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input4"
                @input="checkIfInputIsEmpty"
                v-model="updateData.facebook"
              />
              <label class="form-label">
                {{ $t("settings.contactData.facebook") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input5"
                @input="checkIfInputIsEmpty"
                v-model="updateData.twitter"
              />
              <label class="form-label">
                {{ $t("settings.contactData.twitter") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input6"
                @input="checkIfInputIsEmpty"
                v-model="updateData.youtube"
              />
              <label class="form-label">
                {{ $t("settings.contactData.youtube") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <!-- <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input7"
                @input="checkIfInputIsEmpty"
                v-model="updateData.snapchat"
              />
              <label class="form-label">{{
                $t("settings.contactData.snapchat")
              }}</label>
            </div>
          </div> -->
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.instagram"
              />
              <label class="form-label">
                {{ $t("settings.contactData.instagram") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.ios_link"
              />
              <label class="form-label">آب ستور</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.android_link"
              />
              <label class="form-label">جوجل بلاي</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.minimum_stock"
              />
              <label class="form-label">اقل قيمة في المخزن</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <label class="form-label">التوصيل على</label>
              <multiselect
                v-model="updateData.shipping_on"
                :options="shipping_on"
                label="name"
                track-by="id"
                placeholder=" "
                :searchable="true"
                :allow-empty="true"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>
          <!-- START:: INPUT WRAPPER -->
          <template v-if="updateData.shipping_on">
            <div
              class="col-lg-6 py-0"
              v-if="updateData.shipping_on.id == 'distance'"
            >
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  id="name_input8"
                  @input="checkIfInputIsEmpty"
                  v-model="updateData.shipping_price"
                />
                <label class="form-label">سعر التوصيل</label>
              </div>
            </div>
          </template>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.vat_percentage"
              />
              <label class="form-label">ضريبة القيمة المضافة</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.time_of_reminder_in_minute"
              />
              <label class="form-label">تنبيه قبل للعروض المفاجأه</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.product_return_grace_period"
              />
              <label class="form-label">اقصى مدة متاحة للاسترجاع</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model="updateData.total_product_for_discount_shipping"
              />
              <label class="form-label">قيمة الغاء تكلفة الشحن</label>
            </div>
          </div>
          <div class="col-lg-6 py-0"></div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: MAP -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper col-12 top_label">
              <ckeditor class="col-12" v-model="updateData.return_policy_ar" />
              <label class="form-label">سياسة الارجاع باللغة العربية</label>
            </div>
          </div>
          <div class="col-lg-6 py-0">
            <div class="input_wrapper col-12 top_label">
              <ckeditor class="col-12" v-model="updateData.return_policy_en" />
              <label class="form-label">سياسة الارجاع باللغة الانجليزية</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <div class="col-12 fadeIn">
            <div class="large-map">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="autocomplete_ar"
                  placeholder=""
                  @input="getAddressAr"
                  v-model="updateData.address"
                />
                <label class="form-label">العنوان</label>
              </div>

              <GmapMap
                :center="center"
                :zoom="12"
                map-type-id="terrain"
                style="width: 100%; height: 600px"
              >
                <GmapMarker
                  v-for="(m, index) in markers"
                  :key="index"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @drag="updateCoordinates"
                  @click="clickMethod(m)"
                />
              </GmapMap>
            </div>
          </div>
          <!-- END:: MAP -->
        </div>
      </div>

      <div class="buttons_wrapper">
        <!-- START:: BUTTON -->
        <button class="button_style_1" :class="btnIsLoading ? 'disabled' : ''">
          {{ $t("forms.submit") }}
          <span class="btn_loader" v-if="btnIsLoading"></span>
        </button>
        <!-- END:: BUTTON -->
      </div>
    </form>
    <!-- END:: UPDATE FORM WRAPPER -->
  </div>
</template>

<script>
export default {
  name: "Update",
  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: CREATE DATA
      updateData: {
        project_name: "",
        phone: "",
        email: "",
        whatsapp: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        return_policy_ar: "",
        return_policy_en: "",
        android_link: "",
        ios_link: "",
        address: "",
        lat: "",
        lng: "",
        how_to_shop_ar: "",
        how_to_shop_en: "",
        site_meta_title_ar: "",
        site_meta_title_en: "",
        site_meta_description_ar: "",
        site_meta_description_en: "",
        site_meta_tag_ar: "",
        site_meta_tag_en: "",
        description_location: "",
        website_link: "",
        pinterest: "",
        minimum_stock: "",
        shipping_on: null,
        shipping_price: "",
        total_product_for_discount_shipping: null,
        vat_percentage: "",
        time_of_reminder_in_minute: "",
        product_return_grace_period: "",
      },
      // END:: CREATE DATA

      // START:: MAP
      center: {
        lat: null,
        lng: null,
      },

      markers: [],
      coordinates_to_edit: { lat: 0, lng: 0 },
      // END:: MAP
    };
  },
  methods: {
    // START:: UPDATE LOCATION
    updateCoordinates(location) {
      this.updateData = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },

    clickMethod(m) {
      this.center = m.position;
    },
    // END:: UPDATE LOCATION

    // ============== Get User Location
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.markers = [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
          ];
          this.center = this.markers[0].position;
          this.updateData.lat = this.center.lat;
          this.updateData.lng = this.center.lng;
        });
      }
    },

    // START:: G-MAP GET ADDRESS
    getAddressAr() {
      var self = this;
      var input = document.getElementById("autocomplete_ar");
      var searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener("places_changed", function () {
        var places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function (place) {
          bounds.extend(place.geometry.location);
          place.geometry.location.lat();
          place.geometry.location.lng();
          place.formatted_address;
          self.updateData.address = place.formatted_address;
          self.center.lat = place.geometry.location.lat();
          self.center.lng = place.geometry.location.lng();
          self.updateData.lat = place.geometry.location.lat();
          self.updateData.lng = place.geometry.location.lng();
          self.markers[0].position.lat = place.geometry.location.lat();
          self.markers[0].position.lng = place.geometry.location.lat();
        });
      });
    },

    // START:: G-MAP GET ADDRESS

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    //START:: GET DATA
    getData() {
      this.$axios({
        method: "GET",
        url: "settings",
      }).then((res) => {
        this.updateData.project_name = res.data.data.project_name;

        this.updateData.phone = res.data.data.phone;

        this.updateData.email = res.data.data.email;

        this.updateData.return_policy_ar = res.data.data.return_policy_ar;
        this.updateData.return_policy_en = res.data.data.return_policy_en;

        this.updateData.whatsapp = res.data.data.whatsapp;

        this.updateData.facebook = res.data.data.facebook;

        this.updateData.twitter = res.data.data.twitter;

        this.updateData.instagram = res.data.data.instagram;

        this.updateData.youtube = res.data.data.youtube;

        this.updateData.android_link = res.data.data.android_link;

        this.updateData.ios_link = res.data.data.ios_link;

        this.updateData.address = res.data.data.address;
        this.updateData.total_product_for_discount_shipping =
          res.data.data.total_product_for_discount_shipping;

        this.updateData.lat = +res.data.data.lat;

        this.updateData.lng = +res.data.data.lng;

        this.updateData.how_to_shop_en = res.data.data.how_to_shop_en;
        this.updateData.how_to_shop_ar = res.data.data.how_to_shop_ar;

        this.updateData.site_meta_title_ar = res.data.data.site_meta_title_ar;
        this.updateData.site_meta_title_en = res.data.data.site_meta_title_en;

        this.updateData.site_meta_description_ar =
          res.data.data.site_meta_description_ar;
        this.updateData.site_meta_description_en =
          res.data.data.site_meta_description_en;

        this.updateData.site_meta_tag_ar = res.data.data.site_meta_tag_ar;
        this.updateData.site_meta_tag_en = res.data.data.site_meta_tag_en;

        this.updateData.description_location =
          res.data.data.description_location;

        this.updateData.website_link = res.data.data.website_link;

        this.updateData.pinterest = res.data.data.pinterest;

        this.updateData.minimum_stock = res.data.data.minimum_stock;

        this.updateData.shipping_on = {
          id: res.data.data.shipping_on,
          name: res.data.data.shipping_on?.id == "distance" ? "مسافة" : "مدينة",
        };
        if (this.updateData.shipping_on?.id == "distance") {
          this.updateData.shipping_price = res.data.data.shipping_price;
        }
        this.updateData.time_of_reminder_in_minute =
          res.data.data.time_of_reminder_in_minute;
        this.updateData.vat_percentage = res.data.data.vat_percentage;
        this.updateData.product_return_grace_period =
          res.data.data.product_return_grace_period;
      });
    },
    //END:: GET DATA

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      this.btnIsLoading = true;
      if (!this.updateData.phone) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("settings.contactData.validation.phone"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        const data = new FormData();
        // data.append('phone', this.updateData.phone ? this.updateData.phone : '')
        // data.append('project_name', this.updateData.project_name)
        // data.append('email', this.updateData.email)
        // data.append(
        //   'whatsapp',
        //   this.updateData.whatsapp ? this.updateData.whatsapp : '',
        // )
        // data.append(
        //   'twitter',
        //   this.updateData.twitter ? this.updateData.twitter : '',
        // )
        // data.append(
        //   'facebook',
        //   this.updateData.facebook ? this.updateData.facebook : '',
        // )
        // data.append(
        //   'instagram',
        //   this.updateData.instagram ? this.updateData.instagram : '',
        // )
        // data.append(
        //   'youtube',
        //   this.updateData.youtube ? this.updateData.youtube : '',
        // )
        // data.append(
        //   'return_policy_ar',
        //   this.updateData.return_policy_ar
        //     ? this.updateData.return_policy_ar
        //     : '',
        // )
        // data.append(
        //   'return_policy_en',
        //   this.updateData.return_policy_en
        //     ? this.updateData.return_policy_en
        //     : '',
        // )
        // data.append(
        //   'android_link',
        //   this.updateData.android_link ? this.updateData.android_link : '',
        // )
        // data.append(
        //   'ios_link',
        //   this.updateData.ios_link ? this.updateData.ios_link : '',
        // )
        // data.append('address', this.updateData.address)
        // data.append('lat', this.updateData.lat)
        // data.append('lng', this.updateData.lng)

        // data.append('how_to_shop_ar', this.updateData.how_to_shop_ar)
        // data.append('how_to_shop_en', this.updateData.how_to_shop_en)
        // data.append(
        //   'description_location',
        //   this.updateData.description_location,
        // )
        // data.append(
        //   'website_link',
        //   this.updateData.website_link ? this.updateData.website_link : '',
        // )
        // data.append('pinterest', this.updateData.pinterest)
        // data.append(
        //   'minimum_stock',
        //   this.updateData.minimum_stock ? this.updateData.minimum_stock : 0,
        // )
        // data.append('shipping_on', this.updateData.shipping_on.id)
        // if (this.updateData.shipping_price) {
        //   data.append('shipping_price', this.updateData.shipping_price)
        // }
        // if (this.updateData.vat_percentage) {
        //   data.append('vat_percentage', this.updateData.vat_percentage)
        // }
        // if (this.updateData.time_of_reminder_in_minute) {
        //   data.append(
        //     'time_of_reminder_in_minute',
        //     this.updateData.time_of_reminder_in_minute,
        //   )
        // }
        // data.append(
        //   'product_return_grace_period',
        //   this.updateData.product_return_grace_period,
        // )

        for (const [key, value] of Object.entries(this.updateData)) {
          if (value) {
            if (typeof value == "object") {
              data.append(key, value.id);
            } else {
              data.append(key, value);
            }
          }
        }

        this.$axios({
          method: "post",
          url: "settings",
          data: data,
        })
          .then(() => {
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("editSuccess"),
              position: "bottomRight",
            });
            this.btnIsLoading = false;
            this.$router.push({ path: "/" });
          })
          .catch((err) => {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
            this.btnIsLoading = false;
          });
      }
    },
    // END:: VALIDATE CREATE FORM
  },
  computed: {
    shipping_on() {
      return [
        {
          id: "city",
          name: "مدينة",
        },
        {
          id: "distance",
          name: "مسافة",
        },
      ];
    },
  },
  created() {
    this.getLocation();
    this.getData();
  },
};
</script>
