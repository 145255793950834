<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->
    <main-filter
      @getResult="setRows"
      status
      :orderStatusItems="orderStatusItems"
      users
      :withSearch="false"
    />
    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <MainLoader key="loader" v-if="loading" />
      <!-- End Main Loader -->

      <div key="main" v-else>
        <!-- Start Main Section -->
        <main>
          <v-data-table
            class="elevation-1 thumb diff_table"
            :headers="headers"
            :items="rows"
            :search="search"
            v-model="tableSelected"
            :loading="loading"
            :show-select="true"
            :loading-text="$t('table.loadingData')"
            item-key="user_id"
            :items-per-page="paginations.items_per_page"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData", { name: "تقييمات" }) }}
            </template>

            <!-- Start:: Users Routes -->
            <template v-slot:[`item.product`]="{ item }">
              <div class="item-with-img">
                <img
                  :src="item.product_details.product.image"
                  :alt="item.product_details.product.name"
                />
                <h3 class="title">{{ item.product_details.product.name }}</h3>
              </div>
            </template>
            <template v-slot:[`item.client`]="{ item }">
              <div class="item-with-img">
                <img :src="item.user.image" :alt="item.user.fullname" />
                <h3 class="title">{{ item.user.fullname }}</h3>
              </div>
            </template>

            <template v-slot:[`item.rates`]="{ item }">
              <v-rating
                readonly
                length="5"
                color="yellow"
                size="20"
                :value="item.rate"
              ></v-rating>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span class="status pending" v-if="item.status == 'pending'">
                {{ item.status_trans }}
              </span>
              <span class="status canceled" v-if="item.status == 'rejected'">
                {{ item.status_trans }}
              </span>
              <span class="status success" v-if="item.status == 'accepted'">
                {{ item.status_trans }}
              </span>
            </template>

            <!-- Start:: Report Reason Modal Button -->
            <template v-slot:[`item.report_reason`]="{ item }">
              <v-icon class="show" small v-if="item.report_reason">
                fal fa-eye
              </v-icon>

              <span v-else>_</span>
            </template>
            <!-- End:: Report Reason Modal Button -->

            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions d-flex">
                <v-icon class="show" small @click="showItem(item)">
                  fal fa-eye
                </v-icon>
                <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm">
                      {{ $t("table.deletedialog.ok") }}
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row class="justify-content-start" v-if="rows.length">
                <v-col sm="8">
                  <h3 class="table-title title">التقييمات</h3>
                </v-col>
                <v-col sm="4">
                  <section class="table-buttons p-0 justify-content-end">
                    <v-select
                      label="تغيير الحالة"
                      solo
                      :items="orderStatusList"
                      @input="changeStatus"
                    ></v-select>
                  </section>
                </v-col>
              </v-row>

              <!-- Start:: Text Content Modal -->
              <TextContentModal
                @toggleModal="controlReportReasonModalModal"
                :modalIsActive="reportReasonModalIsOpen"
                :title="$t('reportReason')"
                :textContent="reportReasonToShow"
              />
              <!-- End:: Text Content Modal -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->

        <!-- Start Pagination -->
        <template>
          <div
            class="pagination_container text-center mb-5 d-flex justify-content-between"
          >
            <div class="select-pagination d-flex">
              <span class="first">{{ $t("show") }}</span>
              <v-select
                :items="[5, 20, 50, 100]"
                v-model="paginations.items_per_page"
              ></v-select>
              <span>{{ $t("entries") }}</span>
            </div>

            <v-pagination
              v-model.number="paginations.current_page"
              :length="paginations.last_page"
              :total-visible="5"
              @input="fetchData($event)"
            ></v-pagination>
          </div>
        </template>
        <!-- End Pagination -->
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Start:: Importing Modals
import TextContentModal from "@/components/Modals/TextContentModal.vue";
// End:: Importing Modals

export default {
  name: "UsersReports",

  components: {
    TextContentModal,
  },

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.rates.title"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",
      tableSelected: [],
      // ========== dialog Status
      dialogDelete: false,
      itemtoDelete: null,
      reportReasonModalIsOpen: false,
      reportReasonToShow: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 20,
      },

      // ========== Loading
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    orderStatusList() {
      if (this.tableSelected.length) {
        return [
          {
            text: "قبول الكل",
            value: "accepted-all",
          },
          {
            text: "قبول المحدد",
            value: "accept-selected",
          },
          { text: "رفض الكل", value: "rejected-all" },
          { text: "رفض المحدد", value: "reject-selected" },
        ];
      } else {
        return [
          {
            text: "قبول الكل",
            value: "accepted-all",
          },
          { text: "رفض الكل", value: "rejected-all" },
        ];
      }
    },

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "order.id",
            sortable: true,
          },
          {
            text: "المنتج",
            align: "center",
            value: "product",
            sortable: false,
          },
          {
            text: "العميل",
            align: "center",
            value: "client",
            sortable: false,
          },
          {
            text: "التقييم",
            align: "center",
            value: "rates",
            sortable: false,
          },
          {
            text: "التعليق",
            align: "center",
            value: "comment",
            sortable: false,
          },
          {
            text: "الحالة",
            align: "center",
            value: "status",
            sortable: false,
          },

          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
          },
          {
            text: "Reporter",
            align: "center",
            value: "from",
            sortable: false,
          },
          {
            text: "Reported",
            align: "center",
            value: "to",
            sortable: false,
          },
          {
            text: "Report Reason",
            align: "center",
            value: "report_reason",
            sortable: false,
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
    orderStatusItems() {
      return [
        {
          label: this.$t("pending"),
          name: "pending",
        },
        {
          label: this.$t("accepted"),
          name: "accepted",
        },
        {
          label: this.$t("rejected"),
          name: "rejected",
        },
      ];
    },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },
    changeStatus(e) {
      this.loading = true;
      let currentStatus = "";

      let ids = [];
      if (this.tableSelected.length) {
        this.tableSelected.map((item) => {
          ids.push(item.id);
        });
      }
      let submit_data = new FormData();
      if (e == "accepted-all") {
        currentStatus = "accepted";
        submit_data.append(`apply_on`, "all");
      } else if (e == "rejected-all") {
        currentStatus = "rejected";
        submit_data.append(`apply_on`, "all");
      } else if (e == "accept-selected") {
        currentStatus = "accepted";
        ids.map((id, index) => {
          submit_data.append(`apply_on[${index}]`, id);
        });
      } else if (e == "reject-selected") {
        currentStatus = "rejected";
        ids.map((id, index) => {
          submit_data.append(`apply_on[${index}]`, id);
        });
      }

      submit_data.append("_method", "PATCH");
      submit_data.append("status", currentStatus);

      this.$axios({
        method: "POST",
        url: `rates/change-status`,
        data: submit_data,
      })
        .then((data) => {
          this.loading = false;
          this.$iziToast.sucess({
            timeout: 2000,
            message: this.$t(data.data.messages),
            position: "bottomRight",
          });
          this.setRows();
        })
        .catch(() => (this.loading = false));
    },

    // ===== Toggle Report Reason Modal =====
    controlReportReasonModalModal(selectedReportReason) {
      this.reportReasonToShow = selectedReportReason;
      this.reportReasonModalIsOpen = !this.reportReasonModalIsOpen;
    },

    // ==================== Start CRUD ====================

    showItem(item) {
      this.$router.push({ path: "/rates/show/" + item.id });
    },

    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `rates/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows(e) {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "/rates",
        params: {
          status: e?.status ? e.status.name : "",
          user_id: e?.user ? e.user : "",
        },
      })
        .then((res) => {
          this.rows = res.data.data;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    changePerPage() {
      this.setRows();
    },
    fetchData(e) {
      this.$router.replace({ query: { page: e } });
      this.setRows();
    },
  },
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.btn.change-status {
  background: var(--mainColor-2) !important;
  color: #fff !important;
  height: 45px;
  font-size: 16px;
  line-height: 30px;
}
.item-with-img {
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    width: 50px;
    border-radius: 5px;
    margin-inline-end: 10px;
  }
  h3.title {
    color: var(--cardTitle);
    overflow: hidden;
    font-size: 16px !important;
  }
}
</style>
