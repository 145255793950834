<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->

    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <MainLoader key="loader" v-if="loading" />
      <!-- End Main Loader -->

      <div key="main" v-else>
        <!-- <main-filter payment="true" price="true" date="true" /> -->
        <!-- Start Statistics Card-->
        <!-- <div class="row mb-5">
          <div class="wrraper">
            <stats-card
              :title="$t('ordersNum')"
              :number="statisticsItem.number"
            ></stats-card>
          </div>
        </div> -->
        <!-- End Statistics Card-->

        <!-- Start Main Section -->
        <main>
          <v-data-table
            class="elevation-1 thumb diff_table"
            :headers="headers"
            :items="rows"
            :search="search"
            :custom-filter="helper_filterSearch"
            :loading="loading"
            disable-pagination
            :loading-text="$t('table.loadingData')"
            item-key="id"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData", { name: "طلبات" }) }}
            </template>

            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:[`item.is_payment`]="{ item }">
              <span class="status" :class="item.is_payment ? 'paid' : 'unpaid'">
                {{ item.is_payment ? "مدفوع" : "غير مدفوع" }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <!-- <span
                v-if="
                  item.status == 'admin_rejected' ||
                  item.status == 'admin_cancel' ||
                  item.status == 'client_cancel'
                "
                class="status canceled"
              >
                {{ item.status_trans }}
              </span>
              <span
                v-if="
                  item.status == 'admin_shipping' || item.status == 'pending'
                "
                class="status pending"
              >
                {{ item.status_trans }}
              </span>
              <span
                v-if="
                  item.status == 'admin_delivered' ||
                  item.status == 'admin_accept'
                "
                class="status success"
              >
                {{ item.status_trans }}
              </span> -->
              <div class="action">
                <div
                  v-if="
                    item.status == 'admin_cancel' ||
                    item.status == 'client_cancel' ||
                    item.status == 'admin_delivered'
                  "
                >
                  <span
                    v-if="
                      item.status == 'admin_cancel' ||
                      item.status == 'client_cancel'
                    "
                    class="status canceled"
                  >
                    {{ item.status_trans }}
                  </span>
                  <span
                    v-if="item.status == 'admin_delivered'"
                    class="status success"
                  >
                    {{ item.status_trans }}
                  </span>
                </div>
                <div class="select-wrapper" v-else>
                  <div class="input_wrapper">
                    <v-select
                      :label="item.status_trans"
                      solo
                      :items="orderStatusList(item.status)"
                      @input="changeStatus($event, item.id)"
                    ></v-select>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:[`item.to`]="{ item }">
              <router-link :to="`/users/show/${item.to.id}`">
                {{ item.to.fullname }}
              </router-link>
            </template>
            <!-- End:: Users Routes -->

            <!-- Start:: Report Reason Modal Button -->
            <template v-slot:[`item.report_reason`]="{ item }">
              <v-icon
                class="show"
                small
                @click="controlReportReasonModalModal(item.report_reason)"
                v-if="item.report_reason"
              >
                fal fa-eye
              </v-icon>

              <span v-else>_</span>
            </template>
            <!-- End:: Report Reason Modal Button -->

            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions">
                <v-icon class="show" small @click="showItem(item)">
                  fal fa-eye
                </v-icon>
                <!-- <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon> -->
                <!-- <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon> -->
              </div>
            </template>

            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <h3 class="title table-title">
                عدد الطلبات {{ statisticsItem.number }}
              </h3>
              <!-- Delete dialog -->

              <!-- Start:: Text Content Modal -->
              <TextContentModal
                @toggleModal="controlReportReasonModalModal"
                :modalIsActive="reportReasonModalIsOpen"
                :title="$t('reportReason')"
                :textContent="reportReasonToShow"
              />
              <!-- End:: Text Content Modal -->
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Start:: Importing Modals
import TextContentModal from "@/components/Modals/TextContentModal.vue";

// End:: Importing Modals

export default {
  name: "UsersReports",

  components: {
    TextContentModal,
  },
  props: ["id"],

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.orders.title"),
          disabled: true,
          href: "",
        },
        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      itemtoDelete: null,
      reportReasonModalIsOpen: false,
      reportReasonToShow: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },

      // ========== Loading
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            sortable: true,
            width: "80",
          },
          {
            text: "رقم الطلب",
            align: "center",
            value: "id",
            sortable: false,
          },
          {
            text: "اجمالي السعر",
            align: "center",
            value: "total_price",
            sortable: false,
          },
          {
            text: "اجمالي كمية المنتج",
            align: "center",
            value: "total_quantity_product",
            sortable: false,
          },
          {
            text: "حالة الدفع ؟",
            align: "center",
            value: "is_payment",
            sortable: false,
          },
          {
            text: "الحالة",
            align: "center",
            value: `status`,
            sortable: false,
            width: "200",
            padding: "2",
          },

          {
            text: "التاريخ",
            align: "center",
            value: "created_at",
            sortable: false,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
          },
          {
            text: "Reporter",
            align: "center",
            value: "from",
            sortable: false,
          },
          {
            text: "Reported",
            align: "center",
            value: "to",
            sortable: false,
          },
          {
            text: "Report Reason",
            align: "center",
            value: "report_reason",
            sortable: false,
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
    orderStatusItems() {
      return [
        {
          label: "انتظار قبول الادارة",
          name: "pending",
        },
        {
          label: "قبول من قبل الادارة",
          name: "admin_accept",
        },
        {
          label: "الالغاء من قبل الادارة",
          name: "admin_cancel",
        },
        {
          label: "الغاء من قبل العميل",
          name: "client_cancel",
        },
        {
          label: "جاري الشحن",
          name: "admin_shipping",
        },
        {
          label: "تم التوصيل",
          name: "admin_delivered",
        },
        {
          label: "الانتهاء للعميل",
          name: "client_finished",
        },
      ];
    },
  },

  methods: {
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },
    changeStatus(currStatus, id) {
      this.btnIsLoading = true;
      let submit_data = new FormData();
      submit_data.append("_method", "PUT");
      submit_data.append("status", currStatus);
      this.$axios({
        method: "POST",
        url: `order/${id}/change_status`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("sendSuccess"),
            position: "bottomRight",
          });
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.btnIsLoading = false;
          this.setRows();
        })
        .catch((err) => {
          if (err.response.data.status == "fail") {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }

          this.setRows();
          this.btnIsLoading = false;
        });
    },
    orderStatusList(status) {
      if (status == "pending") {
        return [
          {
            text: "قبول",
            value: "admin_accept",
          },
          { text: "رفض", value: "admin_rejected" },
        ];
      } else if (status == "admin_accept") {
        return [
          { text: "شحن", value: "admin_shipping" },
          { text: "الغاء", value: "admin_cancel" },
        ];
      } else if (status == "admin_rejected") {
        return [{ text: "الغاء", value: "admin_cancel" }];
      } else if (status == "admin_shipping") {
        return [
          { text: "تم التوصيل", value: "admin_delivered" },
          { text: "الغاء", value: "admin_cancel" },
        ];
      } else if (status == "admin_delivered") {
        return [{ text: "الغاء", value: "admin_cancel" }];
      }
    },
    // ===== Toggle Report Reason Modal =====
    controlReportReasonModalModal(selectedReportReason) {
      this.reportReasonToShow = selectedReportReason;
      this.reportReasonModalIsOpen = !this.reportReasonModalIsOpen;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/orders/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/orders/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/orders/edit/" + item.id });
    },
    // ===== Delete

    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: `providers/${this.id}/orders`,
      })
        .then((res) => {
          this.rows = res.data.data;

          this.statisticsItem.number = res.data.meta.total;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fetchData(e) {
      this.$router.replace({ query: { page: e } });
      this.setRows();
    },
  },
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.v-text-field__details {
  display: none !important;
}
.v-input__slot {
  margin-bottom: 0 !important;
}
</style>
