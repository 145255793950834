<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">ميتا</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.meta_title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_title_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.meta_title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_title_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.meta_description"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_description_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.meta_description"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_description_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.meta_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_tag_ar") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.meta_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_tag_en") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="url"
                  class="form-control"
                  v-model.trim="data.meta_canonical_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_canonical_tag") }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      dateMenu: false,
      dateMenu2: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.about.title"),
          disabled: false,
          href: "/settings/about",
        },
        {
          text: this.$t("breadcrumb.about.add_meta"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        ar: {
          meta_title: null,
          meta_tag: null,
          meta_description: null,
        },
        en: {
          meta_title: null,
          meta_tag: null,
          meta_description: null,
        },
        meta_canonical_tag: null,
      },
    };
  },

  methods: {
    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;
      if (!this.data.ar.meta_title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_title_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_title_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_description) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_description_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_description) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_description_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_tag) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_tag_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_tag) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_tag_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("ar[meta_title]", this.data.ar.meta_title);
      submit_data.append("ar[meta_tag]", this.data.ar.meta_tag);
      submit_data.append("ar[meta_description]", this.data.ar.meta_description);
      submit_data.append("en[meta_title]", this.data.en.meta_title);
      submit_data.append("en[meta_tag]", this.data.en.meta_tag);
      submit_data.append("en[meta_description]", this.data.en.meta_description);
      submit_data.append("meta_canonical_tag", this.data.meta_canonical_tag);

      this.$axios({
        method: "POST",
        url: "about",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/settings/about" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },

    //GET DATA
    getData() {
      this.$axios({
        method: "GET",
        url: `about/${this.id}`,
      }).then((res) => {
        this.data.ar.meta_title = res.data.data.ar.meta_title;
        this.data.en.meta_title = res.data.data.en.meta_title;

        this.data.ar.meta_description = res.data.data.ar.meta_description;
        this.data.en.meta_description = res.data.data.en.meta_description;

        this.data.ar.meta_tag = res.data.data.ar.meta_tag;
        this.data.en.meta_tag = res.data.data.en.meta_tag;

        this.data.meta_canonical_tag = res.data.data.meta_canonical_tag;
      });
    },
    //GET DATA
  },

  created() {
    this.getData();
  },
};
</script>
