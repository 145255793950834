<template>
  <div class="box">
    <img src="../../assets/images/icons/logo.png" alt="" />
  </div>
</template>

<script>
export default {
  name: 'mainLoader',

  props: ['ChartType'],

  data() {
    return {}
  },

  created() {},
}
</script>
