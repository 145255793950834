<template>
  <div class="addForm">
    <section id="basic-vertical-layouts">
      <div class="row match-height">
        <div class="col-md-12">
          <div class="custom_card">
            <div class="card-header">
              <h4 class="card-title">{{ $t("permissions.edit.mainTitle") }}</h4>
            </div>
            <div class="card-content">
              <div class="card-body">
                <form class="form form-vertical">
                  <div class="form-body">
                    <div class="row">
                      <div class="col-lg-6 col-12">
                        <div class="form-group">
                          <label for="first-name-icon">{{
                            $t("permissions.edit.nameInArabic")
                          }}</label>
                          <div class="position-relative">
                            <input
                              type="text"
                              v-model="data.nameInArabic"
                              id="first-name-icon"
                              class="form-control"
                              name="fname-icon"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12">
                        <div class="form-group">
                          <label for="first-name-icon">{{
                            $t("permissions.edit.nameInEnglish")
                          }}</label>
                          <div class="position-relative">
                            <input
                              type="text"
                              v-model="data.nameInEnglish"
                              id="first-name-icon"
                              class="form-control"
                              name="fname-icon"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="first-name-icon">{{
                            $t("permissions.edit.permissionPage")
                          }}</label>
                          <div class="position-relative">
                            <vue-select
                              dir="rtl"
                              v-if="
                                this.$store.getters['lang_module/lang'] == 'ar'
                              "
                              multiple
                              v-model="data.selected_permission"
                              :options="permissions_map"
                            ></vue-select>
                          </div>
                        </div>
                      </div>

                      <div class="permisions">
                        <!-- --------------------- -->
                        <div
                          v-for="permission in data.selected_permission"
                          :key="permission.id"
                          class="
                            col-lg-12
                            switch_part
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="form-group">
                            <h4>{{ permission.label }} :</h4>
                          </div>

                          <div class="form-group">
                            <h5>عرض :</h5>
                            <label class="switch">
                              <!-- checked -->
                              <input
                                type="checkbox"
                                name="permissions"
                                v-model="permission.is_show"
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>

                          <div class="form-group">
                            <h5>اضافة :</h5>
                            <label class="switch">
                              <input
                                type="checkbox"
                                name="permissions"
                                v-model="permission.is_create"
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>

                          <div class="form-group">
                            <h5>تعديل :</h5>
                            <label class="switch">
                              <input
                                type="checkbox"
                                name="permissions"
                                v-model="permission.is_edit"
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>

                          <div class="form-group">
                            <h5>حذف :</h5>
                            <label class="switch">
                              <input
                                type="checkbox"
                                name="permissions"
                                v-model="permission.is_destroy"
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <!-- --------------------- -->
                      </div>

                      <div class="col-12">
                        <div class="form-buttons">
                          <button
                            type="button"
                            class="dark-button button"
                            ref="login2"
                            @click="addUser"
                          >
                            <i class="fas fa-spinner"></i>
                            {{ $t("permissions.edit.save") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iziToast from "izitoast";
export default {
  data() {
    return {
      data: {
        image: null,
        imgSrc: null,

        nameInArabic: "",
        nameInEnglish: "",
        selected_permission: [],
      },
      id: this.$route.params.id,
      items: null,
      permissions: [
        {
          name: "الصلاحيات",
          name_en: "Permission",
          route_name: "/allPermission",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المديرين",
          name_en: "Mangers",
          route_name: "/allManagers",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المؤثرين المفعلين",
          name_en: "Activited Influencer",
          route_name: "/activatedInfluencer",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المؤثرين الغير المفعلين",
          name_en: "Disactivited Influencer",
          route_name: "/disActivatedInfluencer",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المؤثرين المحظورين",
          name_en: "Blocked Influencer",
          route_name: "/blockedInfluencer",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المؤثرين قيد الانتظار",
          name_en: "Pendding Influencer",
          route_name: "/penddingInfluencer",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "طلبات تعديل منصات التواصل",
          name_en: "Edit Influencer Platform",
          route_name: "/editInfluencerPlatform",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المستثمرين المفعلين",
          name_en: "Activated Investor",
          route_name: "/activatedInvestor",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المستثمرين الغير المفعلين",
          name_en: "Disactivated Investor",
          route_name: "/disActivatedInvestor",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المستثمرين المحظورين",
          name_en: "Blocked Investor",
          route_name: "/blockedInvestor",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المستثمرين قيد الانتظار",
          name_en: "Pendding Investor",
          route_name: "/penddingInvestor",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "رسائل التواصل",
          name_en: "Contact Message",
          route_name: "/allContacts",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "العقود المبرمة",
          name_en: "Contracts",
          route_name: "/AllContracts",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "الفيديوهات",
          name_en: "Add Video",
          route_name: "/addVideo",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "الاهتمامات",
          name_en: "Interests",
          route_name: "/interests",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "منصات التواصل",
          name_en: "Platforms",
          route_name: "/platforms",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "مدة العقد",
          name_en: "Contract Duration",
          route_name: "/durations",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "اسباب رفض العقد",
          name_en: "Contract Cancel Reason",
          route_name: "/reports",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "اسباب الابلاغ",
          name_en: "Report Reason",
          route_name: "/declines",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "حقل الاهتمام في التواصل",
          name_en: "Social Interest",
          route_name: "/Areas",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المدونه",
          name_en: "Blogs",
          route_name: "/blogs",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "الدول",
          name_en: "Countries",
          route_name: "/countries",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "المدن",
          name_en: "Cities",
          route_name: "/cities",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "اسباب حذف الحساب",
          name_en: "Delete Account Reason",
          route_name: "/deleteAccounts",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "الاسئلة الشائعة",
          name_en: "Faqs",
          route_name: "/faqs",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "الاعدادات العامة",
          name_en: "Settings",
          route_name: "/about-us",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "الرسائل الادارية",
          name_en: "Manage Messages",
          route_name: "/addManageMessage",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "البلاغات",
          name_en: "Reports",
          route_name: "/allReports",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "التحويلات البنكية",
          name_en: "Transfares",
          route_name: "/allTransfares",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "تحويلات باي بال",
          name_en: "Paypal Transfares",
          route_name: "/allTransfaresPaypal",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "طلبات تعطيل الحساب",
          name_en: "Deactivate Account Request",
          route_name: "/inactivatedAccounts",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "الاسليدر الرئيسي",
          name_en: "Slider",
          route_name: "/allSlides",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "كيفية العمل",
          name_en: "How Work",
          route_name: "/allCards",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "تعديل العوائد",
          name_en: "Edit Invest",
          route_name: "/invest_Text_img",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
        {
          name: "شركاء النجاح",
          name_en: "Partners",
          route_name: "/allPartners",
          is_show: false,
          is_create: false,
          is_edit: false,
          is_destroy: false,
        },
      ],
      // permissions: [
      //   {
      //     name: "الصلاحيات",
      //     // name_en: "Mangers",
      //     route_name: "/AllPermission",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المديرين",
      //     // name_en: "Mangers",
      //     route_name: "/allManagers",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المؤثرين المفعلين",
      //     // name_en: "Influencer",
      //     route_name: "/activatedInfluencer",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المؤثرين الغير المفعلين",
      //     // name_en: "Influencer",
      //     route_name: "/disActivatedInfluencer",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المؤثرين المحظورين",
      //     // name_en: "Influencer",
      //     route_name: "/blockedInfluencer",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المؤثرين قيد النتظار",
      //     // name_en: "Influencer",
      //     route_name: "/penddingInfluencer",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "طلبات تعديل منصات التواصل",
      //     // name_en: "Influencer",
      //     route_name: "/editInfluencerPlatform",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المستثمرين المفعلين",
      //     // name_en: "Investors",
      //     route_name: "/activatedInvestor",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المستثمرين الغير المفعلين",
      //     // name_en: "Investors",
      //     route_name: "/disActivatedInvestor",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المستثمرين المحظورين",
      //     // name_en: "Investors",
      //     route_name: "/blockedInvestor",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المستثمرين قيد الانتظار",
      //     // name_en: "Investors",
      //     route_name: "/penddingInvestor",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "رسائل التواصل",
      //     // name_en: "Contact Message",
      //     route_name: "/allContacts",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "العقود المبرمة",
      //     // name_en: "Contracts",
      //     route_name: "/AllContracts",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الفيديوهات",
      //     // name_en: "addVideo",
      //     route_name: "/addVideo",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الاهتمامات",
      //     // name_en: "Interests",
      //     route_name: "/interests",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "منصات التواصل",
      //     // name_en: "Platforms",
      //     route_name: "/platforms",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "مدة العقد",
      //     // name_en: "Durations",
      //     route_name: "/durations",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "اسباب رفض العقد",
      //     // name_en: "Contract Cancel Reason",
      //     route_name: "/reports",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "اسباب الابلاغ",
      //     // name_en: "Report Reason",
      //     route_name: "/declines",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "حقل الاهتمام في التواصل",
      //     // name_en: "Social Interest",
      //     route_name: "/Areas",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المدونة",
      //     // name_en: "Countries",
      //     route_name: "/blogs",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الدول",
      //     // name_en: "Countries",
      //     route_name: "/countries",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "المدن",
      //     // name_en: "Cities",
      //     route_name: "/cities",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "اسباب حذف الحساب",
      //     // name_en: "Delete Account Reason",
      //     route_name: "/deleteAccounts",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الاسئلة الشائعة",
      //     // name_en: "Faqs",
      //     route_name: "/faqs",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الاعدادات العامة",
      //     // name_en: "Settings",
      //     route_name: "/about-us",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الرسائل الادارية",
      //     // name_en: "Manage Messages",
      //     route_name: "/addManageMessage",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "البلاغات",
      //     // name_en: "Reports",
      //     route_name: "/allReports",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "التحويلات",
      //     // name_en: "Transfares",
      //     route_name: "/allTransfares",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "طلبات تعطيل الحساب",
      //     // name_en: "Deactivate Account Request",
      //     route_name: "/inactivatedAccounts",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الاسليدر الرئيسي",
      //     // name_en: "Deactivate Account Request",
      //     route_name: "/allSlides",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "كيفية العمل",
      //     // name_en: "Deactivate Account Request",
      //     route_name: "/allCards",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "تعديل العوائد",
      //     // name_en: "Deactivate Account Request",
      //     route_name: "/invest_Text_img",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "شركاء النجاح",
      //     // name_en: "Deactivate Account Request",
      //     route_name: "/allPartners",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      //   {
      //     name: "الاعدادات العامة",
      //     // name_en: "Deactivate Account Request",
      //     route_name: "/about-us",
      //     is_show: false,
      //     is_create: false,
      //     is_edit: false,
      //     is_destroy: false,
      //   },
      // ],
    };
  },

  methods: {
    // validate email
    validEmail: function (email) {
      let re =
        /^(([^<script>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    //upload single
    uploadImage(event) {
      this.data.image = event.target.files[0];
      this.data.imgSrc = URL.createObjectURL(this.data.image);
    },

    getData() {
      this.loading = true;

      // let pageIndex;
      // if (pageNumber) {
      //   pageIndex = pageNumber;
      // } else {
      //   pageIndex = 1;
      // }

      this.$axios
        .get("/roles/" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("Inflico_token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            "Accept-Language": this.$store.getters["lang_module/lang"],
          },
        })
        .then((res) => {
          this.items = res.data.data;
          // this.paginations.current_page = res.data.meta.current_page;
          // this.paginations.last_page = res.data.meta.last_page;
          this.data.nameInArabic = this.items.ar.name;
          this.data.nameInEnglish = this.items.en.name;
          this.data.selected_permission = this.items.permissions.map((x) => {
            return {
              label: x.name,
              value: x.route_name,
              name: x.name,
              route_name: x.route_name,
              is_show: x.is_show,
              is_create: x.is_create,
              is_edit: x.is_edit,
              is_destroy: x.is_destroy,
            };
          });
        });
    },

    //main method
    addUser() {
      const submitButton = this.$refs["login2"];
      submitButton.classList.add("click");

      if (this.data.nameInArabic == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          iziToast.error({
            message: this.$t("validation.permission.name_ar"),
          });
        }, 1000);
      } else if (this.data.nameInEnglish == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          iziToast.error({
            message: this.$t("validation.permission.name_en"),
          });
        }, 1000);
      } else if (this.data.selected_permission.length == 0) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          iziToast.error({
            message: this.$t("validation.permission.per"),
          });
        }, 1000);
      } else {
        // Submit Data Here
        const data = new FormData();
        data.append("_method", "PUT");
        data.append("role[ar][name]", this.data.nameInArabic);
        data.append("role[en][name]", this.data.nameInEnglish);
        this.data.selected_permission.forEach((item, index) => {
          data.append(
            "permissions[" + index + "][route_name]",
            item.route_name
          );
          if (this.$store.getters["lang_module/lang"] == "ar") {
            data.append("permissions[" + index + "][name]", item.name);
          } else {
            data.append("permissions[" + index + "][name]", item.name_en);
          }
          data.append("permissions[" + index + "][is_show]", +item.is_show);
          data.append("permissions[" + index + "][is_create]", +item.is_create);
          data.append("permissions[" + index + "][is_edit]", +item.is_edit);
          data.append(
            "permissions[" + index + "][is_destroy]",
            +item.is_destroy
          );
        });

        this.$axios
          .post("/roles/" + this.id, data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("Inflico_token"),
              "cache-control": "no-cache",
              "Content-type": "multipart/form-data",
              Accept: "application/json",
              "Accept-language": this.$store.getters["lang_module/lang"],
            },
          })
          .then(() => {
            submitButton.classList.remove("click");
            setTimeout(() => {
              submitButton.classList.remove("click");
              iziToast.success({
                message: this.$t("validation.add"),
              });
            }, 300);
            this.$router.push("/AllPermission");
          })
          .catch((error) => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: error.response.data.message,
              position: "bottomRight",
              rtl: true,
            });
          });
      }
    },
  },

  computed: {
    permissions_map() {
      return this.permissions.map((item) => ({
        label:
          this.$store.getters["lang_module/lang"] == "ar"
            ? item.name
            : item.name_en,
        value: item.route_name,
        name: item.name,
        route_name: item.route_name,
        is_show: false,
        is_create: false,
        is_edit: false,
        is_destroy: false,
      }));
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
/* ============================== */
.switch_part label.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin: 0 10px;
}
.switch_part .switch input {
  opacity: 0;
  width: 1px;
  height: 1px;
}
.switch_part .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch_part .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
.switch_part input:checked + .slider {
  background-color: #7367f0;
}
.switch_part input:focus + .slider {
  box-shadow: 0 0 1px #7367f0;
}
.switch_part input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
/* Rounded sliders */
.switch_part .slider.round {
  border-radius: 34px;
}
.switch_part .slider.round:before {
  border-radius: 50%;
}
.switch_part .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch_part h5 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.switch_part .form-group h5 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}
.switch_part .form-group h4 {
  font-size: 13px;
  font-weight: bold;
  width: 140px;
}
</style>
