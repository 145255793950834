<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Image -->
            <!-- <uplode-image @inputChanged="uplodeImg_1"></uplode-image> -->

            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.name"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.name_ar")
                }}</label>
              </div>
            </div>

            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.name"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.name_en")
                }}</label>
              </div>
            </div>

            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("forms.labels.country")
                }}</label>
                <multiselect
                  v-model="data.country"
                  :options="countries"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                >
                </multiselect>
              </div>
            </div>
            <!-- Start:: Ar Slug -->
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.slug"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.slug_ar")
                }}</label>
              </div>
            </div>
            <!-- End:: Ar Slug -->

            <!-- Start:: En Slug -->
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.slug"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.slug_en")
                }}</label>
              </div>
            </div>
            <!-- End:: En Slug -->

            <!-- Start:: En Slug -->
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.short_name"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.short_name")
                }}</label>
              </div>
            </div>
            <!-- End:: En Slug -->

            <!-- Start:: Sipping Price -->
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="preventMinus"
                  min="0"
                  v-model.trim="data.shipping_price"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.shipping_price")
                }}</label>
              </div>
            </div>
            <!-- End:: Sipping Price -->

            <!-- Start:: Postal Code -->
            <div class="col-lg-4 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.postal_code"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.postal_code")
                }}</label>
              </div>
            </div>
            <!-- End:: Postal Code -->

            <!-- Start:: Map -->
            <div class="col-lg-12" v-if="data.area">
              <div class="input_wrapper top_label">
                <base-map
                  @markersChanged="merkersChanged"
                  :biggingMarkers="data.area"
                />
                <label for="name_input" class="form-label">{{
                  $t("forms.labels.area")
                }}</label>
              </div>
            </div>
            <!-- end:: Map -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.cities.title"),
          disabled: false,
          href: "/countries",
        },
        {
          text: this.$t("breadcrumb.cities.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image: null,
        ar: {
          name: localStorage.getItem("vendor_city_name_ar")
            ? localStorage.getItem("vendor_city_name_ar")
            : null,
          slug: localStorage.getItem("vendor_slug_ar")
            ? localStorage.getItem("vendor_slug_ar")
            : null,
        },
        en: {
          name: localStorage.getItem("vendor_city_name_en")
            ? localStorage.getItem("vendor_city_name_en")
            : null,
          slug: localStorage.getItem("vendor_slug_en")
            ? localStorage.getItem("vendor_slug_en")
            : null,
        },
        phone_code: localStorage.getItem("vendor_city_phone_code")
          ? localStorage.getItem("vendor_city_phone_code")
          : null,
        country: {
          id: JSON.parse(localStorage.getItem("vendor_city_country"))
            ? JSON.parse(localStorage.getItem("vendor_city_country"))
            : null,
        },
        short_name: localStorage.getItem("vendor_city_short_cut")
          ? localStorage.getItem("vendor_city_short_cut")
          : null,
        shipping_price: localStorage.getItem("vendor_city_shipping_price")
          ? localStorage.getItem("vendor_city_shipping_price")
          : null,
        postal_code: localStorage.getItem("vendor_city_postal_code")
          ? localStorage.getItem("vendor_city_postal_code")
          : null,
        area: JSON.parse(localStorage.getItem("vendor_city_map_area"))
          ? JSON.parse(localStorage.getItem("vendor_city_map_area"))
          : [],
      },

      // Fixed Data
      countries: [],
    };
  },

  methods: {
    merkersChanged(data) {
      this.data.area = data;
    },

    // Uplode Image
    uplodeImg_1(obj) {
      this.data.image = obj;
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;
      if (!this.data.ar.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      // =============== Start:: Cache Form Data At LocalStorage =============== //
      localStorage.setItem("vendor_city_name_ar", this.data.ar.name);
      localStorage.setItem("vendor_city_slug_ar", this.data.ar.slug);
      localStorage.setItem("vendor_city_name_en", this.data.en.name);
      localStorage.setItem("vendor_city_slug_en", this.data.en.slug);
      localStorage.setItem(
        "vendor_city_country",
        JSON.stringify(this.data.country.id)
      );
      localStorage.setItem("vendor_city_postal_code", this.data.postal_code);
      localStorage.setItem("vendor_city_short_cut", this.data.short_name);
      localStorage.setItem(
        "vendor_city_shipping_price",
        this.data.shipping_price
      );
      localStorage.setItem("vendor_city_phone_code", this.data.phone_code);
      localStorage.setItem(
        "vendor_city_map_area",
        JSON.stringify(this.data.area)
      );
      // =============== End:: Cache Form Data At LocalStorage =============== //
      const submit_data = new FormData();
      submit_data.append("ar[name]", this.data.ar.name);
      submit_data.append("en[name]", this.data.en.name);
      submit_data.append("ar[slug]", this.data.ar.slug);
      submit_data.append("en[slug]", this.data.en.slug);
      submit_data.append("country_id", this.data.country.id);
      submit_data.append("short_name", this.data.short_name);
      submit_data.append("shipping_price", this.data.shipping_price);
      submit_data.append("postal_code", this.data.postal_code);
      this.data.area.forEach((item, index) => {
        submit_data.append(`area[${index}][lat]`, item.lat);
        submit_data.append(`area[${index}][lng]`, item.lng);
      });
      this.$axios({
        method: "POST",
        url: "cities",
        data: submit_data,
      })
        .then(() => {
          // =============== Start:: Remove Form Data From LocalStorage =============== //
          localStorage.removeItem("vendor_city_name_ar");
          localStorage.removeItem("vendor_city_slug_ar");
          localStorage.removeItem("vendor_city_name_en");
          localStorage.removeItem("vendor_city_slug_en");
          localStorage.removeItem("vendor_city_country");
          localStorage.removeItem("vendor_city_postal_code");
          localStorage.removeItem("vendor_city_short_cut");
          localStorage.removeItem("vendor_city_shipping_price");
          localStorage.removeItem("vendor_city_phone_code");
          localStorage.removeItem("vendor_city_map_area");
          // =============== End:: Remove Form Data From LocalStorage =============== //
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/cities" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },

    // Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: `countries`,
      }).then((res) => {
        this.countries = res.data.data.map((item) => {
          return {
            id: item.id,
            name: `${item.ar.name} - ${item.en.name}`,
          };
        });
      });
    },
  },

  created() {
    this.getData();
  },
};
</script>
