<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <MainLoader key="loader" v-if="loading" />
      <!-- End Main Loader -->

      <div key="main" v-else>
        <!-- Start Statistics Card-->
        <div class="order-details-page mb-5">
          <div class="order-status">
            <h2 class="title">رقم الطلب: {{ `# ${allData.id}` }}</h2>
            <div class="status">
              <div class="date">
                <h5 v-if="allData.created_at">
                  <v-icon small>fa-solid fa-calendar-days</v-icon>
                  <span>{{ allData.created_at }}</span>
                </h5>
                <h5 v-if="allData.status_trans">
                  <span
                    v-if="
                      allData.status == 'admin_rejected' ||
                      allData.status == 'admin_cancel' ||
                      allData.status == 'client_cancel'
                    "
                    class="status canceled"
                  >
                    {{ allData.status_trans }}
                  </span>
                  <span
                    v-if="
                      allData.status == 'admin_shipping' ||
                      allData.status == 'pending'
                    "
                    class="status pending"
                  >
                    {{ allData.status_trans }}
                  </span>
                  <span
                    v-if="
                      allData.status == 'admin_delivered' ||
                      allData.status == 'admin_accept'
                    "
                    class="status success"
                  >
                    {{ allData.status_trans }}
                  </span>
                </h5>
              </div>
              <div class="action">
                <div
                  v-if="
                    allData.status == 'admin_cancel' ||
                    allData.status == 'client_cancel' ||
                    allData.status == 'admin_delivered'
                  "
                >
                  <span
                    v-if="
                      allData.status == 'admin_cancel' ||
                      allData.status == 'client_cancel'
                    "
                    class="status canceled"
                  ></span>
                  <span
                    v-if="allData.status == 'admin_delivered'"
                    class="status success"
                  ></span>
                </div>

                <v-select
                  v-else
                  label="تغيير الحالة"
                  solo
                  :items="orderStatusItems"
                  @input="changeStatus"
                ></v-select>
              </div>
            </div>
          </div>
          <hr class="line" />
          <div class="details">
            <div class="row">
              <div class="col-sm-6 col-lg-4">
                <div class="customer">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-regular fa-user</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">بيانات العميل</h5>
                      <p>الاسم: {{ allData.client.fullname }}</p>
                      <p>
                        البريد الالكتروني:
                        <span v-if="allData.client.email">{{
                          allData.client.email
                        }}</span>
                        <span v-else>{{ $t("notExist") }}</span>
                      </p>
                      <p>رقم الموبايل: {{ allData.client.phone }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4">
                <div class="order-info">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-solid fa-cart-plus</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">تفاصيل الطلب</h5>
                      <p>
                        <span> المسافة: </span>
                        <span>
                          {{ allData.distance }}
                        </span>
                      </p>
                      <p>
                        حالة الدفع:
                        {{ allData.is_payment ? "مدفوع" : "غير مدفوع" }}
                      </p>
                      <p>
                        <span> طريقة الدفع: </span>
                        <span>
                          {{ allData.pay_type_trans }}
                        </span>
                      </p>
                      <p v-if="allData.marchent_order_id">
                        <span> رقم العملية </span>
                        <span>
                          {{ allData.marchent_order_id }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="customer">
                  <div class="item">
                    <div class="icon">
                      <v-icon>fa-solid fa-truck-moving</v-icon>
                    </div>
                    <div class="info">
                      <h5 class="title">عنوان الشحن</h5>

                      <p v-if="allData.address.full_name != 'null'">
                        <span>الاسم:</span>
                        <span>{{ allData.address.full_name }}</span>
                      </p>
                      <p>
                        <span>العنوان:</span>
                        <span>
                          {{ allData.address.country.ar.name }} -
                          {{ allData.address.city.ar.name }}
                        </span>
                      </p>
                      <p>
                        <span>المكان:</span>
                        <span>
                          {{ allData.address.name }}
                        </span>
                      </p>
                      <p>
                        <span>وصف الموقع</span>
                        <span>
                          {{ allData.address.location_description }}
                        </span>
                      </p>
                      <p>
                        <span>رقم الموبايل:</span>
                        <span>
                          {{ allData.address.phone_code
                          }}{{ allData.address.phone }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="line" />
          <div class="product-details">
            <div class="row">
              <div class="col-lg-8">
                <h2 class="title">المنتجات</h2>
                <v-data-table
                  class="elevation-1 thumb diff_table"
                  :headers="headers"
                  :items="rows"
                  :search="search"
                  :loading="loading"
                  :loading-text="$t('table.loadingData')"
                  item-key="id"
                  hide-default-footer
                >
                  <template v-slot:no-data>
                    {{ $t("table.noData", { name: "طلبات" }) }}
                  </template>
                  <template v-slot:[`item.product_details`]="{ item }">
                    <div class="item-with-img flex-wrap justify-content-center">
                      <img
                        :src="item.product_details.image"
                        :alt="item.name"
                        @click="show_model_1"
                      />
                    </div>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <router-link :to="`/products/show/${item.product_id}`">
                      {{ item.name }}
                    </router-link>
                  </template>
                </v-data-table>
                <div v-if="allData.user_cancel_reason">
                  <h2 class="title mt-2">سبب الالغاء من قبل العميل</h2>
                  <p class="fs-5 ps-5">"{{ allData.user_cancel_reason }}"</p>
                </div>
                <h2 class="title mt-2">التعليق</h2>
                <p class="fs-5 ps-5">"{{ allData.comment }}"</p>
              </div>
              <div class="col-lg-4">
                <div class="order-summary">
                  <h2 class="title">ملخص الطلب</h2>
                  <hr class="line" />
                  <table>
                    <tbody>
                      <tr>
                        <td class="title">السلة</td>
                        <td class="value">{{ rows.length }}</td>
                      </tr>
                      <tr>
                        <td class="title">اجمالي السلة</td>
                        <td class="value">
                          {{
                            allData.price_details.total_product_after_discount
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="title">الخصم</td>
                        <td class="value">
                          {{ allData.price_details.discount }}
                        </td>
                      </tr>
                      <tr>
                        <td class="title">الشحن</td>
                        <td class="value">
                          {{ allData.price_details.shipping_price }}
                        </td>
                      </tr>

                      <tr>
                        <td class="title">الضريبة</td>
                        <td class="value">
                          {{ allData.price_details.vat_price }}
                        </td>
                      </tr>
                    </tbody>
                    <div class="line"></div>
                    <tfoot>
                      <tr>
                        <td class="title">الاجمالي</td>
                        <td class="value">
                          {{ allData.price_details.total_price }}
                        </td>
                      </tr>
                      <tr>
                        <td class="title status">الحالة</td>
                        <td class="value">
                          <span
                            v-if="
                              allData.status == 'pending' ||
                              allData.status == 'admin_shipping'
                            "
                            class="status pending bg-transparent"
                          >
                            {{ allData.status_trans }}
                          </span>
                          <span
                            v-if="
                              allData.status == 'admin_accept' ||
                              allData.status == 'admin_delivered'
                            "
                            class="status success bg-transparent"
                          >
                            {{ allData.status_trans }}
                          </span>
                          <span
                            v-if="
                              allData.status == 'admin_rejected' ||
                              allData.status == 'admin_cancel' ||
                              allData.status == 'client_cancel'
                            "
                            class="status canceled bg-transparent"
                          >
                            {{ allData.status_trans }}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <base-model
          @closeModel="model_1.show_model = false"
          :show="model_1.show_model"
        >
          <div class="image">
            <img
              v-if="model_1.model_img_src"
              :src="model_1.model_img_src"
              alt="..."
            />
          </div>
        </base-model>
        <!-- End Statistics Card-->
        <!-- Start:: Text Content Modal -->
        <DeleteModel
          :id="allData.id"
          @toggleModal="toggleDeleteModal"
          :modalIsActive="deleteModalIsOpen"
          :elementIdToGet="deleteItemToShow"
        />
        <!-- End:: Text Content Modal -->
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Start:: Importing Modals
import DeleteModel from "@/components/Modals/DeleteModel.vue";
// End:: Importing Modals

export default {
  props: ["id"],
  name: "Withdraw",

  components: {
    DeleteModel,
  },

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.orders.title"),
          disabled: true,
          href: "",
        },

        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Statistics
      statisticsItem: {},
      rows: null,

      // ========== Top Section
      search: "",
      model_1: {
        show_model: false,
        model_img_src: "",
      },
      rejectedReason: "",

      deleteModalIsOpen: false,
      deleteItemToShow: null,

      // ========== Your Data
      allData: null, // injected in created

      // ========== Loading
      loading: false,
      reportReasonToShow: null,
      btnIsLoading: false,
      e1: 1,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "صورة المنتج",
            align: "center",
            value: "product_details",
            sortable: false,
          },
          {
            text: "المنتج",
            align: "center",
            value: "name",
            sortable: false,
          },

          {
            text: "الكود",
            align: "center",
            value: "code",
            sortable: false,
          },
          {
            text: "اللون",
            align: "center",
            value: "product_details.color.name",
            sortable: false,
          },
          {
            text: "الحجم",
            align: "center",
            value: "product_details.size.name",
            sortable: false,
          },
          {
            text: "السعر",
            align: "center",
            value: "price",
            sortable: false,
          },
          {
            text: "الكمية",
            align: "center",
            value: "quantity",
            sortable: false,
          },
          {
            text: "الاجمالي",
            align: "center",
            value: "total_price",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
          },
          {
            text: "Reporter",
            align: "center",
            value: "from",
            sortable: false,
          },
          {
            text: "Reported",
            align: "center",
            value: "to",
            sortable: false,
          },
          {
            text: "Report Reason",
            align: "center",
            value: "report_reason",
            sortable: false,
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },

    orderStatusItems() {
      if (this.allData.status == "pending") {
        return [
          {
            text: "قبول",
            value: "admin_accept",
          },
          { text: "رفض", value: "admin_rejected" },
        ];
      } else if (this.allData.status == "admin_accept") {
        return [
          { text: "شحن", value: "admin_shipping" },
          { text: "الغاء", value: "admin_cancel" },
        ];
      } else if (this.allData.status == "admin_rejected") {
        return [{ text: "الغاء", value: "admin_cancel" }];
      } else if (this.allData.status == "admin_shipping") {
        return [
          { text: "تم التوصيل", value: "admin_delivered" },
          { text: "الغاء", value: "admin_cancel" },
        ];
      } else if (this.allData.status == "admin_delivered") {
        return [{ text: "الغاء", value: "admin_cancel" }];
      }
    },
  },

  methods: {
    // ===== Toggle Delete Modal =====
    toggleDeleteModal(item) {
      this.deleteItemToShow = item;
      this.deleteModalIsOpen = !this.deleteModalIsOpen;
    },

    // Set Rows
    setRows() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "order/" + this.id,
      })
        .then((res) => {
          this.allData = res.data.data;
          this.rows = res.data.data.products;
          this.setStep(res.data.data.status);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //Change Status
    changeStatus(currStatus) {
      this.btnIsLoading = true;
      let submit_data = new FormData();
      submit_data.append("_method", "PUT");
      submit_data.append("status", currStatus);
      this.$axios({
        method: "POST",
        url: `order/${this.allData.id}/change_status`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("sendSuccess"),
            position: "bottomRight",
          });
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.btnIsLoading = false;
          this.setRows();
        })
        .catch((err) => {
          // console.log(err)
          if (err.response.data.status == "fail") {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }

          this.setRows();
          this.btnIsLoading = false;
        });
    },
    setStep(status) {
      switch (status) {
        case "pending":
          this.e1 = 1;
          break;
        case "admin_accept ":
          this.e1 = 2;
          break;
        case "admin_shipping":
          this.e1 = 3;
          break;
        case "admin_delivered":
          this.e1 = 4;
          break;
        default:
      }
    },
    orderStatus(status) {
      let message = "";
      switch (status) {
        case "pending":
          message = "جاري التنفيذ";
          break;
        case "admin_accept ":
          message = "تم القبول";

          break;
        case "admin_shipping":
          message = "جاري الشحن";

          break;
        case "admin_delivered":
          message = "تم التوصيل";

          break;
        case "admin_rejected":
          message = "تم الرفض ";

          break;
        case "admin_cancel":
          message = "تم الالغاء";

          break;
        default:
      }
      return message;
    },
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },
  },
  created() {
    this.setRows();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.order-details-page {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 11%);
  padding: 20px 0;
  h2.title {
    font-size: 14px !important;
    padding: 0 10px;
    font-weight: bold !important;
    text-align: start !important;
  }
  span.status {
    font-size: 14px;
    &.finished {
      color: #02bc77;
    }
    &.pendding {
      color: #ffc800;
    }
    &.shipping {
      color: #a900ff;
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
  }
  .action {
    .v-input {
      padding: 0;
      margin: 0;
    }
    .v-input__control {
      background: #e7e7e7;
      padding: 8px;
      max-width: 200px;
      border-radius: 5px;
    }
    .v-text-field__details {
      display: none !important;
    }
    .v-input__slot {
      margin: 0;
      &::before {
        content: unset !important;
      }
    }
    .v-select__slot {
      &::after {
        content: unset !important;
      }
    }
    input[role="readonly"] {
      display: none !important;
    }
  }
  .details {
    padding: 0 10px;
    h5.title {
      color: #000;
      font-size: 14px !important;
      font-weight: bold;
      margin-bottom: 0%;
    }
    .item {
      display: flex;
      align-items: flex-start;
      .icon {
        background: #f5f5fa;
        border-radius: 50%;
        width: 50px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px !important;
        * {
          color: #000;
        }
      }
      .info {
        flex: 1;
        margin-inline-start: 8px;
        background: none !important;
        p {
          font-size: 14px;
          color: #8d8d8d;
          margin-bottom: 0;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  .product-details {
    padding: 0 10px;
    h2.title {
      font-size: 18px !important;
    }
  }
  table {
    width: 100%;
    td.title {
      font-size: 16px !important;
      line-height: 1.7;
      &.status {
        font-size: 14px !important;
        padding: 0;
      }
    }
    td.value {
      font-size: 14px !important;
      font-weight: bold;
      text-align: end;

      span.status {
        justify-content: flex-end;
        padding: 0;
      }
    }
  }

  div.line {
    padding: 10px 0;
  }
  tfoot {
    border-top-width: 1px;
    margin-top: 10px;
  }
}

.btns_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 5px;
  }
}
</style>
