<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="container">
      <transition-group name="fadeInUp" mode="in-out">
        <!-- Start Main Loader -->

        <MainLoader key="loader" v-if="loading" />
        <!-- End Main Loader -->

        <div key="main" v-else>
          <div v-if="allData">
            <!-- Start Statistics Card-->
            <div class="row mb-5 justify-content-center">
              <div class="col-md-10">
                <div class="slider_for">
                  <vue-slick
                    v-if="ratesImages.length"
                    :focusOnSelect="true"
                    :slidesToShow="1"
                    :dots="false"
                    :draggable="true"
                    :arrows="false"
                  >
                    <div v-for="image in ratesImages" :key="image.id">
                      <img :src="image.url" v-if="image.url" />
                      <v-icon
                        class="delete"
                        small
                        @click="deleteItem(image.id)"
                      >
                        fal fa-trash
                      </v-icon>
                    </div>
                  </vue-slick>
                  <div class="rate_det">
                    <div class="d-flex justify-content-between">
                      <h4>التفاصيل</h4>
                      <div class="col-4 p-0" v-if="allData.status == 'pending'">
                        <section class="table-buttons p-0 justify-content-end">
                          <v-select
                            label="تغيير الحالة"
                            solo
                            :items="orderStatusList"
                            @input="changeStatus"
                          ></v-select>
                        </section>
                      </div>
                    </div>
                    <hr />
                    <div class="flex_content">
                      <div class="flex_con">
                        <h4></h4>
                        <v-chip
                          v-if="allData.status == 'pending'"
                          color="yellow"
                        >
                          الحالة : قيد الانتظار
                        </v-chip>
                        <v-chip
                          v-if="allData.status == 'accepted'"
                          color="green"
                        >
                          الحالة : تم القبول
                        </v-chip>
                        <v-chip v-if="allData.status == 'rejected'" color="red">
                          الحالة : تم الالغاء
                        </v-chip>
                      </div>
                      <div class="flex_con" v-if="allData.product_details">
                        <v-chip color="#0e3958" text-color="#fff">
                          اسم المنتج :
                          {{ allData.product_details.product.name }}
                        </v-chip>
                      </div>
                      <div class="flex_con" v-if="allData.product_details">
                        <v-chip color="#0e3958" text-color="#fff">
                          سعر المنتج : {{ allData.product_details.price }}
                        </v-chip>
                      </div>
                    </div>

                    <div class="flex_content">
                      <div class="flex_con" v-if="allData.product_details">
                        <v-chip color="#0e3958" text-color="#fff">
                          كمية المنتج :
                          {{ allData.product_details.quantity }}
                        </v-chip>
                      </div>
                      <div class="flex_con" v-if="allData.product_details">
                        <v-chip color="#0e3958" text-color="#fff">
                          يوجد عرض ؟ :
                          {{ allData.product_details.have_sale ? 'نعم' : 'لا' }}
                        </v-chip>
                      </div>
                    </div>
                    <h4>التقيمات</h4>
                    <hr />
                    <UserRate
                      :name="allData.user.fullname"
                      :rateNum="allData.rate"
                      :desc="allData.comment"
                      :image="allData.user.image"
                    ></UserRate>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-6">
                <div class="product_details">
                  <div class="product_head">
                    <div class="pro_det_tit">
                      <h4>الحالة</h4>
                      <span
                        v-if="
                          allData.status == 'accepted' ||
                          allData.status == 'rejected'
                        "
                      >
                        {{ allData.status }}
                      </span>
                    </div>
                  </div>
                  <hr class="line" />

                  <div class="product_desc">
                    <div class="pro_det_tit">
                      <h4>المنتج</h4>
                    </div>
                    <div class="desc">
                      <div class="item-with-img">
                        <img
                          :src="allData.product_details.product.image"
                          :alt="allData.product_details.product.name"
                        />
                        <div>
                          <h3 class="title">
                            {{ allData.product_details.product.name }}
                          </h3>
                          <p>
                            {{ allData.product_details.product.desc }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pro_det_tit">
                    <h4>تقيمات المنتج</h4>
                  </div>
                  <UserRate
                    :name="allData.user.fullname"
                    :rateNum="allData.rate"
                    :desc="allData.comment"
                    :image="allData.user.image"
                  ></UserRate>
                </div>
              </div> -->
              <!-- <div class="col-lg-6" v-if="ratesImages.length">
                <div class="col-lg-12 py-0">
                  <div class="divider">
                    <h4>الصور</h4>
                    <hr
                      role="separator"
                      aria-orientation="horizontal"
                      class="v-divider theme--dark"
                    />
                  </div>
                </div>
                <v-row>
                  <v-col
                    md="6"
                    class="position-relative rating-images"
                    v-for="image in ratesImages"
                    :key="image.id"
                  >
                    <v-icon class="delete" small @click="deleteItem(image.id)">
                      fal fa-trash
                    </v-icon>
                    <v-img :src="image.url">
                      <div class="fill-height bottom-gradient"></div>
                    </v-img>
                  </v-col>
                </v-row>
              </div> -->
              <!---------------end products details  -->
            </div>
          </div>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 justify-center">
                {{ $t('table.deletedialog.areYouSure') }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1B5E20" @click="deleteItemConfirm">
                  {{ $t('table.deletedialog.ok') }}
                </v-btn>
                <v-btn color="#F44336" @click="dialogDelete = false">
                  {{ $t('table.deletedialog.cancel') }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Start:: Importing Modals
import DeleteModel from '@/components/Modals/DeleteModel.vue'
import UserRate from '@/components/Global/UserRate.vue'
// End:: Importing Modals

export default {
  props: ['id'],
  name: 'show-rate',

  components: {
    DeleteModel,
    UserRate,
  },

  data() {
    return {
      expanded: [],
      singleExpand: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t('breadcrumb.mainPage'),
          disabled: false,
          href: '/',
        },
        {
          text: this.$t('breadcrumb.rates.title'),
          disabled: true,
          href: '',
        },
        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Top Section
      search: '',
      dialogDelete: false,
      itemtoDelete: null,
      ratesImages: [],

      deleteModalIsOpen: false,
      deleteItemToShow: null,

      // ========== Your Data
      allData: null, // injected in created
      paginations: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },
      // ========== Loading
      loading: false,

      btnIsLoading: false,
    }
  },

  watch: {
    [`paginations.current_page`]() {
      this.setRows()
    },
  },

  computed: {
    ...mapGetters({
      lang: 'lang_module/lang',
    }),
    orderStatusList() {
      return [
        {
          text: 'قبول',
          value: 'accepted',
        },
        { text: 'رفض', value: 'rejected' },
      ]
    },
  },

  methods: {
    // ===== Toggle Delete Modal =====
    toggleDeleteModal(item) {
      this.deleteItemToShow = item
      this.deleteModalIsOpen = !this.deleteModalIsOpen
    },
    changeStatus(e) {
      this.loading = true

      let currentStatus = ''

      let submit_data = new FormData()
      if (e == 'accepted') {
        currentStatus = 'accepted'

        submit_data.append(`apply_on[0]`, this.id)
      } else if (e == 'rejected') {
        currentStatus = 'rejected'

        submit_data.append(`apply_on[0]`, this.id)
      }

      submit_data.append('_method', 'PATCH')
      submit_data.append('status', currentStatus)

      this.$axios({
        method: 'POST',
        url: `rates/change-status`,
        data: submit_data,
      })
        .then((data) => {
          this.loading = false
          if (data) {
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t(data.data.messages),
              position: 'bottomRight',
            })
          }
          this.setRows()
        })
        .catch(() => (this.loading = false))
    },

    // Set Rows
    setRows() {
      this.rows = []
      this.loading = true

      this.$axios({
        method: 'GET',
        url: 'rates/' + this.id,
      })
        .then((res) => {
          this.allData = res.data.data
          this.ratesImages = res.data.data.rate_images
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    deleteItem(item) {
      this.dialogDelete = true
      this.itemtoDelete = item
    },
    deleteItemConfirm() {
      this.$axios({
        method: 'DELETE',
        url: `/rates/${this.allData.id}/images/${this.itemtoDelete}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id
          })
          this.dialogDelete = false
          this.setRows()
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t('deleteSuccess'),
            position: 'bottomRight',
          })
        })
        .catch((err) => {
          this.dialogDelete = false
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: 'bottomRight',
          })
        })
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows()
  },
}
</script>

<style lang="scss" scoped>
.btns_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 5px;
  }
}
.product_desc {
  .item-with-img {
    img {
      width: 120px;
    }
  }
}

.rating-images {
  .v-image {
    max-height: 300px;
  }
  .v-icon.delete {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;

    font-size: 18px !important;
    background: red;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #fff;
  }
}
.product_details {
  max-height: auto;
  min-height: auto;
}

.slider_for {
  box-shadow: 0 2px 8px rgb(0 0 0 / 11%);
  padding: 10px;
  border-radius: 10px;
  .rate_det {
    h4 {
      font-size: 18px;
      color: var(--mainColor-2);
    }
    .flex_content {
      @include flexSpaceBetweenAlignment;
      margin-block: 15px;
      .flex_con {
        @include flexStartAlignment;
        h4 {
          margin-bottom: 0;
          margin-inline-end: 10px;
        }
      }
    }
  }
}

.slick-slide {
  border-radius: 10px;
  & > div {
    & > div {
      position: relative;
    }
  }
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
  }
  .v-icon.delete {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;

    font-size: 18px !important;
    background: red;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #fff;
  }
}
</style>
