<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <transition-group name="fadeInUp" mode="in-out">
      <!-- Start Main Loader -->
      <MainLoader key="loader" v-if="loading" />
      <!-- End Main Loader -->

      <div key="main" v-else>
        <div v-if="allData">
          <!-- Start Statistics Card-->
          <div class="row justify-content-center mb-5">
            <!--START:: SUB TITLE-->

            <div class="col-md-6">
              <vue-slick
                :focusOnSelect="true"
                :slidesToShow="1"
                :dots="false"
                :draggable="true"
                :arrows="false"
              >
                <div v-for="image in allData.image" :key="image.id">
                  <img :src="image.image" v-if="image.image" />
                </div>
              </vue-slick>
            </div>
          </div>
          <div class="row mb-5">
            <!------Client---------->
            <!--START:: SUB TITLE-->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>بيانات العميل</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <!--START:: SUB TITLE-->
            <div class="col-md-3">
              <div class="wrraper">
                <stats-card
                  title="رقم الحساب"
                  :number="allData.user.fullname"
                ></stats-card>
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrraper">
                <stats-card
                  title="البريد الالكتروني"
                  :number="allData.user.email"
                ></stats-card>
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrraper">
                <stats-card
                  title="رقم الهاتف"
                  :number="allData.user.phone"
                ></stats-card>
              </div>
            </div>
            <div class="col-md-3" v-if="allData.user.country">
              <div class="wrraper">
                <stats-card
                  title="الدولة"
                  :number="allData.user.country.ar.name"
                ></stats-card>
              </div>
            </div>
            <!------Order---------->
            <!--START:: SUB TITLE-->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>بيانات الطلب</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrraper">
                <stats-card
                  title="المسافة"
                  :number="allData.order.distance"
                ></stats-card>
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrraper">
                <stats-card
                  title="مدفوع؟"
                  :number="allData.order.is_payment ? 'مدفوع' : 'غير مدفوع'"
                ></stats-card>
              </div>
            </div>

            <div class="col-md-3">
              <div class="wrraper" v-if="allData.status">
                <stats-card
                  title="الحالة"
                  :number="allData.status_trans"
                ></stats-card>
              </div>
            </div>
            <!------Price Details---------->
            <!--START:: SUB TITLE-->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>تفاصيل الاسعار</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>

            <div class="col-md-3">
              <div class="wrraper">
                <stats-card
                  title="السعر الاجمالي"
                  :number="allData.order.total_price"
                ></stats-card>
              </div>
            </div>

            <div class="col-md-3">
              <div class="wrraper">
                <stats-card
                  title="اجمالي السعر بعد الاسترجاع"
                  :number="allData.order.total_price_after_return_products"
                ></stats-card>
              </div>
            </div>

            <!---------------start products details  -->
            <!--START:: SUB TITLE-->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>تفاصيل المنتجات</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <v-data-table
              ref="returnProducts"
              class="elevation-1 thumb diff_table"
              :headers="headers"
              :items="rows"
              :disabled="true"
              :search="search"
              :loading="loading"
              :loading-text="$t('table.loadingData')"
              item-key="id"
              :items-per-page="paginations.items_per_page"
              hide-default-footer
            >
              <!-- ================== You Can use any slots you want ================== -->

              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>

              <!-- Start:: Users Routes -->
              <template v-slot:[`item.product_name`]="{ item }">
                <span>
                  {{ item.product.product.name }}
                </span>
              </template>

              <template v-slot:[`item.index`]="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:[`item.price`]="{ item }">
                <span>
                  {{ item.price }}
                </span>
              </template>
              <template v-slot:[`item.order_quantity`]="{ item }">
                <span>
                  {{ item.order_product_qty }}
                </span>
              </template>
              <template v-slot:[`item.return_quantity`]="{ item }">
                <span>
                  {{ item.return_qty }}
                </span>
              </template>

              <template v-slot:[`item.to`]="{ item }">
                <router-link :to="`/users/show/${item.to.id}`">
                  {{ item.to.fullname }}
                </router-link>
              </template>
              <!-- End:: Users Routes -->

              <!-- Start:: Report Reason Modal Button -->
              <template v-slot:[`item.report_reason`]="{ item }">
                <v-icon class="show" small v-if="item.report_reason">
                  <!-- @click="controlReportReasonModalModal(item.report_reason)" -->
                  fal fa-eye
                </v-icon>

                <span v-else>_</span>
              </template>
              <!-- End:: Report Reason Modal Button -->

              <!-- Select actions-->

              <template v-slot:[`item.action`]="{ item }">
                <div class="d-flex">
                  <v-col cols="12" class="d-flex align-items-center">
                    <v-switch
                      v-if="item.waiting"
                      @change="handleExpansion(item, isExpanded)"
                      v-model="item.status"
                      label="قبول"
                      color="red"
                      hide-details
                    ></v-switch>
                    <span v-else>
                      {{ item.status_trans }}
                    </span>
                  </v-col>
                  <v-col cols="6" v-if="e1 == 2">
                    <span v-if="item.received">
                      {{ item.received }}
                    </span>
                    <v-switch
                      v-else
                      v-model="item.status"
                      label="تم الاستقبال"
                      color="red"
                      hide-details
                    ></v-switch>
                  </v-col>
                </div>
                <v-col cols="12" class="d-flex align-items-center">
                  <p v-if="item.reject_reason != ''">
                    <span class="font-weight-bold"> سبب الرفض: </span>
                    {{ item.reject_reason }}
                  </p>
                </v-col>
                <!-- v-if="!item.status" -->
                <v-text-field
                  v-if="item.status_trans == 'طلب الاسترجاع قيد الانتظار'"
                  placeholder="سبب الرفض"
                  v-model="item.reject_reason"
                ></v-text-field>
                <!-- ======================== Start Top Section ======================== -->
                <div class="w-100 my-4">
                  <v-btn
                    v-if="rows[0].waiting"
                    depressed
                    class="background-primary"
                    @click="sendReply"
                  >
                    ارسال الرد علي الاسترجاع
                  </v-btn>
                  <div v-if="e1 == 2">
                    <v-btn
                      depressed
                      class="background-primary"
                      @click="confirmReturn"
                    >
                      تاكيد الاسترجاع
                    </v-btn>
                  </div>
                </div>
                <!-- ======================== End Top Section ======================== -->
              </template>
            </v-data-table>

            <!---------------end products details  -->
          </div>
        </div>
      </div>
    </transition-group>
    <!-- <div v-if="allData">
      <v-stepper
        v-model="e1"
        max-width="80rem"
        class="mx-auto"
        v-if="allData.status == 'waiting'"
      >
        <v-stepper-header>
          <v-stepper-step
            @click="setRows"
            :complete="e1 >= 1"
            complete-icon="fa-plug"
            step="fa fa-plug"
            color="#339989"
          >
            عرض طلبات الاسترجاع
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            @click="getFinallAcception"
            :complete="e1 >= 2"
            complete-icon="fa-user-check"
            step="2"
            color="#339989"
          >
            مراجعه طلبات الاسترجاع
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Start:: Importing Modals
import DeleteModel from "@/components/Modals/DeleteModel.vue";
// End:: Importing Modals

export default {
  props: ["id"],
  name: "Withdraw",

  components: {
    DeleteModel,
  },

  data() {
    return {
      expanded: [],
      singleExpand: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.returns.title"),
          disabled: true,
          href: "",
        },
        // {
        //   text: this.$t("breadcrumb.transaction.products"),
        //   disabled: true,
        //   href: "",
        // },
      ],

      // ========== Statistics
      statisticsItem: {},

      // ========== Top Section
      search: "",

      rejectedReason: "",
      rows: [],

      deleteModalIsOpen: false,
      deleteItemToShow: null,

      // ========== Your Data
      allData: null, // injected in created
      paginations: {
        current_page: 1,
        last_page: 2,
        items_per_page: 20,
      },
      // ========== Loading
      loading: false,
      reportReasonToShow: null,
      btnIsLoading: false,
      e1: 1,
    };
  },

  watch: {
    [`paginations.current_page`]() {
      this.setRows();
    },
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            sortable: true,
          },
          {
            text: "اسم المنتج",
            align: "center",
            value: "product_name",
            sortable: false,
          },
          {
            text: "سعر العرض",
            align: "center",
            value: "price",
            sortable: false,
          },
          {
            text: "كمية الطلب",
            align: "center",
            value: "order_quantity",
            sortable: false,
          },
          {
            text: "كمية الاسترجاع",
            align: "center",
            value: "return_quantity",
            sortable: false,
          },

          {
            text: "قبول الطلب",
            value: "action",
            align: "center",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "id",
            sortable: true,
          },
          {
            text: "Reporter",
            align: "center",
            value: "from",
            sortable: false,
          },
          {
            text: "Reported",
            align: "center",
            value: "to",
            sortable: false,
          },
          {
            text: "Report Reason",
            align: "center",
            value: "report_reason",
            sortable: false,
          },
          // {
          //   text: 'Actions',
          //   value: 'actions',
          //   sortable: false,
          //   align: 'center',
          // },
        ];
      }
    },
  },

  methods: {
    // ===== Toggle Delete Modal =====
    toggleDeleteModal(item) {
      this.deleteItemToShow = item;
      this.deleteModalIsOpen = !this.deleteModalIsOpen;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);

      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    sendReply() {
      this.loading = true;
      const frmData = new FormData();
      this.$axios({
        method: "post",
        url: "return-order/change-status/first-step",

        data: frmData,
      })
        .then(() => {
          this.e1 = 2;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("sendSuccess"),
            position: "bottomRight",
          });
          this.loading = false;
          this.$nextTick(() => this.setRows());
        })
        .catch(() => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.loading = false;
        });

      frmData.append("return_order_id", this.allData.id);

      this.rows.forEach((el, index) => {
        frmData.append(`_method`, "PATCH");
        frmData.append(
          `return_order_products[${index}][return_order_product_id]`,
          el.id
        );

        if (el.status == true) {
          frmData.append(`return_order_products[${index}][status]`, "accepted");
        } else {
          frmData.append(`return_order_products[${index}][status]`, "rejected");
          frmData.append(
            `return_order_products[${index}][reject_reason]`,
            el.reject_reason
          );
        }
      });
    },
    // Set Rows
    setRows() {
      this.rows = [];
      this.loading = true;
      this.e1 = 1;
      this.$axios({
        method: "GET",
        url: "return-order/" + this.id,
      })
        .then((res) => {
          this.allData = res.data.data;
          this.setStep(res.data.data.status);
          this.rows = res.data.data.products;
          this.rows.forEach((el) => {
            if (el.status == "accepted" || el.status == "received") {
              el.generalStatus = el.status;
              el.status = true;
            } else {
              if (el.status == "waiting") {
                el.waiting = true;
              }
              el.status = false;
            }
          });

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getFinallAcception() {
      this.rows = [];
      this.e1 = 2;
      this.loading = true;
      this.$axios({
        method: "GET",
        url: `return-order/${this.id}/return-order-product?status=accepted`,
      })
        .then((res) => {
          this.rows = res.data.data;
          this.rows.forEach((el) => {
            if (
              el.status == "received" ||
              el.status == "rejected" ||
              el.status == "accepted"
            ) {
              el.generalStatus = el.status;
              if (el.status == "accepted") {
                el.accepted = "accepted";
              } else if (el.status == "rejected") {
                el.rejected = "rejected";
              } else if (el.status == "received") {
                el.rejected = "rejected";
              }

              if (el.generalStatus == "received") {
                el.status = true;
              } else {
                el.status = false;
              }
            }
          });

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //Change Status
    changeStatus(currStatus) {
      let newStatus = "";
      if (currStatus == "pending") {
        newStatus = "admin_accept";
      } else if (currStatus == "admin_accept") {
        newStatus = "admin_shipping ";
      } else if (currStatus == "admin_shipping") {
        newStatus = "admin_delivered ";
      }
      this.btnIsLoading = true;
      let submit_data = new FormData();
      submit_data.append("_method", "PUT");
      submit_data.append("status", newStatus);
      this.$axios({
        method: "POST",
        url: `order/${this.allData.id}/change_status`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("sendSuccess"),
            position: "bottomRight",
          });
          // this.$router.push({ path: "/transactions/all-transactions" });
          this.btnIsLoading = false;
          this.setRows();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
    setStep(status) {
      switch (status) {
        case "pending":
          this.e1 = 1;
          break;
        case "admin_accept ":
          this.e1 = 2;
          break;
        case "admin_shipping":
          this.e1 = 3;
          break;
        case "admin_delivered":
          this.e1 = 4;
          break;
        default:
      }
    },
    orderStatus(status) {
      let message = "";
      switch (status) {
        case "pending":
          message = "جاري التنفيذ";
          break;
        case "admin_accept ":
          message = "تم القبول";

          break;
        case "admin_shipping":
          message = "جاري الشحن";

          break;
        case "admin_delivered":
          message = "تم التوصيل";

          break;
        case "admin_rejected":
          message = "تم الرفض ";

          break;
        case "admin_cancel":
          message = "تم الالغاء";

          break;
        default:
      }
      return message;
    },
    confirmReturn() {
      this.loading = true;
      const frmData = new FormData();

      frmData.append("return_order_id", this.allData.id);

      this.rows.forEach((el, index) => {
        frmData.append(`_method`, "PATCH");
        frmData.append(
          `return_order_products[${index}][return_order_product_id]`,
          el.id
        );

        if (el.status == true) {
          frmData.append(`return_order_products[${index}][status]`, "received");
        } else {
          frmData.append(`return_order_products[${index}][status]`, "rejected");
          frmData.append(
            `return_order_products[${index}][reject_reason]`,
            el.reject_reason
          );
        }
      });

      this.$axios({
        method: "post",
        url: "return-order/change-status/second-step",

        data: frmData,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: res.data.message,
            position: "bottomRight",
          });
          this.$nextTick(() => this.getFinallAcception());
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
  },
};
</script>

<style lang="scss" scoped>
.btns_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 5px;
  }
}

.slick-slide {
  border-radius: 10px;
  img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    border-radius: 10px;
  }
}
</style>
