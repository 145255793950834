<template>
  <!-- ==== Start Form ==== -->
  <form @submit.prevent="validateCreateForm">
    <div class="container">
      <div class="row justify-content-between">
        <!--applys on -->
        <div class="col-lg-12 py-0">
          <div class="col-12 productRow">
            <div class="row p-2">
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <select
                    class="form-control border-0 outline-none"
                    style="box-shadow: none"
                    v-model.trim="data.product_row.apply_on"
                    @input="clearProductsRow()"
                  >
                    <option
                      v-for="item in applys_on"
                      :key="item.name"
                      :value="item.name"
                    >
                      {{ $t(`${item.name}`) }}
                    </option>
                  </select>
                  <label for="name_input" class="form-label">
                    منتج / قسم
                  </label>
                </div>
              </div>
              <div
                class="col-lg-6 py-0"
                v-if="data.product_row.apply_on == 'special_payment'"
              >
                <div class="input_wrapper top_label">
                  <label class="form-label">
                    {{ $t("forms.labels.special_payment") }}
                  </label>

                  <multiselect
                    v-model="data.product_row.payment"
                    :options="payments"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                  ></multiselect>
                </div>
              </div>

              <div class="productRow p-4" v-else>
                <div
                  v-for="(row, index) in data.product_row.products_row"
                  :key="row.id"
                >
                  <div class="row productRow">
                    <div
                      class="col-lg-8 py-0"
                      v-if="data.product_row.apply_on == 'special_categories'"
                    >
                      <div class="input_wrapper top_label">
                        <label class="form-label">
                          {{ $t("forms.labels.category") }}
                        </label>

                        <multiselect
                          v-model="row.category"
                          :options="categories"
                          label="name"
                          track-by="id"
                          placeholder=" "
                          :searchable="true"
                          :allow-empty="false"
                          :show-labels="false"
                        ></multiselect>
                      </div>
                    </div>
                    <div
                      class="col-lg-8 py-0"
                      v-if="data.product_row.apply_on == 'special_products'"
                    >
                      <div class="input_wrapper top_label">
                        <label class="form-label">
                          {{ $t("forms.labels.product") }}
                        </label>
                        <!-- {{ row.product }} -->
                        <multiselect
                          v-model="row.product"
                          :options="products"
                          :custom-label="nameWithCode"
                          track-by="id"
                          placeholder=" "
                          :searchable="true"
                          :allow-empty="false"
                          :show-labels="false"
                          @input="getProductDetails(index)"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-11" v-if="row.product_details.length">
                      <main>
                        <v-data-table
                          class="elevation-1 thumb diff_table"
                          :headers="headers"
                          :items="row.product_details"
                          item-key="id"
                          disable-pagination
                          :show-select="true"
                          v-model="row.selected"
                          hide-default-footer
                        >
                          <!-- ================== You Can use any slots you want ================== -->

                          <template v-slot:no-data>
                            {{ $t("table.noData", { name: "عروض" }) }}
                          </template>

                          <template v-slot:[`item.item`]="{ item }">
                            <div class="detail">
                              <div class="productRow mb-0 w-100">
                                <div class="detail_show w-100">
                                  <span class="rate">
                                    {{ item.rate_avg }}
                                    <span><i class="fas fa-star"></i></span>
                                  </span>

                                  <div class="image">
                                    <span class="key">الصورة</span>

                                    <img
                                      v-if="item.media"
                                      :src="item.media[0].image"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      :src="item.product.image"
                                      alt=""
                                    />
                                  </div>

                                  <div class="color">
                                    <span class="key">اللون</span>
                                    <v-chip
                                      :color="
                                        item.color ? item.color.hex : 'لا يوجد'
                                      "
                                      class="value"
                                    ></v-chip>
                                  </div>

                                  <div class="size">
                                    <span class="key">الكمية</span>
                                    <v-chip
                                      color="#0E3958"
                                      class="value text-white"
                                    >
                                      {{
                                        item.quantity
                                          ? item.quantity
                                          : "لا يوجد"
                                      }}
                                    </v-chip>
                                  </div>

                                  <div class="size">
                                    <div class="size">
                                      <span class="key">الحجم</span>
                                      <v-chip
                                        color="#0E3958"
                                        class="value text-white"
                                      >
                                        {{
                                          item.size ? item.size.name : "لا يوجد"
                                        }}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </v-data-table>
                      </main>
                    </div>

                    <div class="col-1 py-0 d-flex justify-center align-center">
                      <span
                        v-if="index == data.product_row.products_row.length - 1"
                        class="append mx-1"
                        @click="appendRow"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </span>

                      <span
                        v-if="data.product_row.products_row.length > 1"
                        class="append mx-1"
                        @click="deleteRow(index)"
                      >
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-12 py-0"
          v-for="(row, index) in data.products_rows"
          :key="row.id"
        >
          <div class="row">
            <div class="col-11">
              <div class="productRow">
                <div class="row">
                  <div class="col-lg-6 py-0">
                    <div class="input_wrapper top_label">
                      <select
                        class="form-control border-0 outline-none"
                        style="box-shadow: none"
                        @input="clearDataProductsRow(index)"
                        v-model.trim="row.apply_on"
                      >
                        <option
                          v-for="item in applys_on"
                          :key="item.name"
                          :value="item.name"
                        >
                          {{ $t(`${item.name}`) }}
                        </option>
                      </select>
                      <label for="name_input" class="form-label">
                        منتج / قسم
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 py-0"
                    v-if="row.apply_on == 'special_payment'"
                  >
                    <div class="input_wrapper top_label">
                      <label class="form-label">
                        {{ $t("forms.labels.special_payment") }}
                      </label>

                      <multiselect
                        v-model="row.payment"
                        :options="payments"
                        label="name"
                        track-by="id"
                        placeholder=" "
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 py-0"
                    v-if="row.apply_on == 'special_categories'"
                  >
                    <div class="input_wrapper top_label">
                      <label class="form-label">
                        {{ $t("forms.labels.category") }}
                      </label>

                      <multiselect
                        v-model="row.category"
                        :options="categories"
                        label="name"
                        track-by="id"
                        placeholder=" "
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 py-0"
                    v-if="row.apply_on == 'special_products'"
                  >
                    <div class="input_wrapper top_label">
                      <label class="form-label">
                        {{ $t("forms.labels.product") }}
                      </label>

                      <multiselect
                        v-model="row.product"
                        :options="products"
                        label="name"
                        track-by="id"
                        placeholder=" "
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="getProductDetails(index)"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- applys on -->

        <div class="col-lg-6 py-0">
          <div class="input_wrapper top_label">
            <input
              type="number"
              class="form-control"
              v-model.trim="data.discount_amount"
            />
            <label for="name_input" class="form-label">
              {{
                staticData.type == "percentage" ? "نسبة الخصم" : "قيمة الخصم"
              }}
            </label>
          </div>
        </div>

        <div class="col-lg-6 py-0" v-if="staticData.type == 'percentage'">
          <div class="input_wrapper top_label">
            <input
              type="number"
              class="form-control"
              v-model.trim="data.max_amount"
            />
            <label for="name_input" class="form-label"> اقصي قيمة للخصم </label>
          </div>
        </div>
        <!-- Discount type  -->
      </div>
    </div>

    <div class="buttons_wrapper">
      <button class="button_style_1" :class="btnIsLoading ? 'disabled' : ''">
        {{ $t("forms.submit") }}
        <span class="btn_loader" v-if="btnIsLoading"></span>
      </button>
    </div>
  </form>
  <!-- ==== End Form ==== -->
</template>

<script>
export default {
  name: "Create",
  props: ["staticData"],
  data() {
    return {
      id: this.$route.params.id || "",
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.offers.title"),
          disabled: false,
          href: "/flash-sales",
        },
        {
          text: this.$t("breadcrumb.offers.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        // min_type: null,
        // min_value: {
        //   name: '',
        // },
        discount_amount: null,
        max_amount: null,

        discount_type: "",

        product_row: {
          apply_on: "special_products",
          products_row: [
            {
              product: null,
              category: null,
              product_details: [],

              selected: [],
            },
          ],
          products_id: [],
          payment: {
            id: null,
            name: null,
          },
        },
      },
      products: [],
      categories: [],
    };
  },

  computed: {
    applys_on() {
      return [
        {
          id: "special_products",
          name: "special_products",
        },
        {
          id: "special_categories",
          name: "special_categories",
        },
        {
          id: "special_payment",
          name: "special_payment",
        },
      ];
    },

    payments() {
      return [
        {
          name: "cash",
          name: "wallet",
          name: "card",
        },
      ];
    },
    discount_types() {
      return [
        {
          id: "value",
          name: "value",
        },
        {
          id: "percentage",
          name: "percentage",
        },
      ];
    },
    headers() {
      return [
        {
          text: "المنتج",
          align: "center",
          value: "item",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    getProductDetails(index) {
      this.$axios({
        method: "GET",
        url: `products/${this.data.product_row.products_row[index].product.id}`,
      }).then((res) => {
        console.log(this.data.product_row.products_row[index]);
        this.data.product_row.products_row[index].product_details = [];
        this.data.product_row.products_row[index].selected = [];
        this.data.product_row.products_row[index].product_details =
          res.data.data.product_details;
      });
    },
    // ====== Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.discount_amount) {
        this.$iziToast.error({
          timeout: 2000,
          message: "قيمة الخصم مطلوبة",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.staticData.ar.title || !this.staticData.ar.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: "حقل العنوان مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.staticData.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.staticData.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.staticData.max_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: "اقصي عدد مرات الالاستخدام مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.staticData.num_of_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: "عدد مرات الاستخدام",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // ====== Submit Data
    submitData() {
      const submit_data = new FormData();
      if (this.$route.params.id) {
        submit_data.append("_method", "PUT");
      }
      console.log(this.staticData.display_platform);
      if (this.staticData.display_platform == "both") {
        if (this.staticData.ar.app_img?.img_file) {
          submit_data.append(
            "app_image_ar",
            this.staticData.ar.app_img.img_file
          );
        }
        if (this.staticData.en.app_img?.img_file) {
          submit_data.append(
            "app_image_en",
            this.staticData.en.app_img.img_file
          );
        }
        if (this.staticData.ar.website_img?.img_file) {
          submit_data.append(
            "web_image_ar",
            this.staticData.ar.website_img.img_file
          );
        }
        if (this.staticData.en.website_img?.img_file) {
          submit_data.append(
            "web_image_en",
            this.staticData.en.website_img.img_file
          );
        }
      } else if (this.staticData.display_platform == "website") {
        if (this.staticData.ar.website_img?.img_file) {
          submit_data.append(
            "web_image_ar",
            this.staticData.ar.website_img.img_file
          );
        }
        if (this.staticData.en.website_img?.img_file) {
          submit_data.append(
            "web_image_en",
            this.staticData.en.website_img.img_file
          );
        }
      } else {
        if (this.staticData.ar.app_img?.img_file) {
          submit_data.append(
            "app_image_ar",
            this.staticData.ar.app_img.img_file
          );
        }
        if (this.staticData.en.app_img?.img_file) {
          submit_data.append(
            "app_image_en",
            this.staticData.en.app_img.img_file
          );
        }
      }

      submit_data.append("ar[name]", this.staticData.ar.title);
      if (this.staticData.ar.desc) {
        submit_data.append("ar[desc]", this.staticData.ar.desc);
      }
      if (this.staticData.en.desc) {
        submit_data.append("en[desc]", this.staticData.en.desc);
      }
      if (this.staticData.ordering) {
        submit_data.append("ordering", this.staticData.ordering);
      }

      submit_data.append("en[name]", this.staticData.en.title);
      if (this.staticData.type == "fix_amount") {
        submit_data.append("discount_type", "value");
      } else if (this.staticData.type == "percentage") {
        submit_data.append("discount_type", "percentage");
      }
      submit_data.append(
        "start_at",
        `${this.staticData.start_date} ${this.staticData.start_time}`
      );
      submit_data.append(
        "end_at",
        `${this.staticData.end_date} ${this.staticData.end_time}`
      );

      submit_data.append("type", this.staticData.type);

      submit_data.append("discount_amount", this.data.discount_amount);

      submit_data.append("max_use", this.staticData.max_use);

      submit_data.append("num_of_use", this.staticData.num_of_use);
      submit_data.append("display_platform", this.staticData.display_platform);
      submit_data.append("is_with_coupon", +this.staticData.is_with_coupon);
      submit_data.append("is_active", +this.staticData.is_active);

      if (this.staticData.type == "percentage") {
        submit_data.append("max_discount", this.data.max_amount);
      }

      submit_data.append(`apply_on`, this.data.product_row.apply_on);

      if (this.data.product_row.apply_on == "special_products") {
        if (this.data.product_row.products_row[0].selected.length) {
          let ids = [];
          this.data.product_row.products_row.map((el) => {
            el.selected.map((el) => {
              ids.push(el.id);
            });
          });
          ids.map((el, index) => submit_data.append(`apply_ids[${index}]`, el));
        } else {
          this.$iziToast.error({
            timeout: 2000,
            message: "ادخل منتجات العرض",
            position: "bottomRight",
          });
        }
      } else if (this.data.product_row.apply_on == "special_categories") {
        if (this.data.product_row.products_row.length) {
          this.data.product_row.products_row.map((main, index) => {
            submit_data.append(`apply_ids[${index}]`, main.category.id);
          });
        } else {
          this.$iziToast.error({
            timeout: 2000,
            message: "ادخل منتجات الشراء",
            position: "bottomRight",
          });
        }
      } else {
        if (this.data.product_row.payment.name) {
          submit_data.append(
            `payment_type`,
            this.data.product_row.payment.name
          );
        } else {
          this.$iziToast.error({
            timeout: 2000,
            message: "حدد طريقة الدفع",
            position: "bottomRight",
          });
        }
      }

      let url = "";
      if (this.$route.params.id) {
        url = `offer/${this.$route.params.id}`;
      } else {
        url = "offer";
      }

      this.$axios({
        method: "POST",
        url: url,
        data: submit_data,
      })
        .then(() => {
          let resMessage = "";
          if (this.$route.params.id) {
            resMessage = "تم التعديل بنجاح";
          } else {
            resMessage = this.$t("addSuccess");
          }
          this.$iziToast.success({
            timeout: 2000,
            message: resMessage,
            position: "bottomRight",
          });
          this.$router.push({ path: "/offers" });
          this.btnIsLoading = false;
          this.$store.commit("offerTypeEdit", "");
        })
        .catch((err) => {
          if (err.response) {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          } else if (err.request) {
            this.$iziToast.error({
              timeout: 2000,
              message: err.request.response,
              position: "bottomRight",
            });
          } else {
            this.$iziToast.error({
              timeout: 2000,
              message: err.message,
              position: "bottomRight",
            });
          }

          this.btnIsLoading = false;
        });
    },
    appendRow() {
      this.data.product_row.products_row.push({
        product: null,
        category: null,
        selected: [],
        product_details: [],
      });
    },
    deleteRow(index) {
      this.data.product_row.products_row.splice(index, 1);
    },
    // ====== Products
    getProducts() {
      this.$axios({
        method: "GET",
        url: `product/without_paginate`,
      }).then((res) => {
        this.products = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            code: item.code,
          };
        });
      });
    },
    // ====== categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `last-categories`,
      }).then((res) => {
        this.categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },

    clearDataProductsRow(i) {
      this.data.products_rows[i].category
        ? (this.data.products_rows[i].category = null)
        : "";
      this.data.products_rows[i].product
        ? (this.data.products_rows[i].product = null)
        : "";
    },

    getData() {
      this.$axios({
        method: "GET",
        url: `offer/${this.id}`,
      }).then((res) => {
        let result = res.data.data;
        if (result.discount_of_offer) {
          this.$store.commit("offerTypeEdit", "fix");
          this.data.discount_type = result.discount_of_offer.discount_type;
          this.data.discount_amount = result.discount_of_offer.discount_amount;
          this.data.max_amount = result.discount_of_offer.max_discount;
          this.data.product_row.apply_on = result.discount_of_offer.apply_on;
          if (result.discount_of_offer.apply_on == "special_products") {
            let currentData = [];
            let newData = [];
            result.discount_of_offer.apply.map((el) => {
              let obj = {};

              obj["product"] = el.product;
              obj["product_details"] = el;
              currentData.push(obj);
            });
            currentData.map((parent) => {
              let obj = {};
              const found = newData.find(
                (f) => parent.product.id == f.product.id
              );
              if (found) {
                found.products_details.push(parent.product_details);
              } else {
                obj["product"] = parent.product;
                obj["products_details"] = [parent.product_details];
                newData.push(obj);
              }
            });
            this.data.product_row.products_row = [];
            newData.map((element) => {
              this.$axios({
                method: "GET",
                url: `products/${element.product.id}`,
              }).then((res) => {
                this.data.product_row.products_row.push({
                  product: element.product,
                  product_details: res.data.data.product_details,
                  selected: [...element.products_details],
                  category: null,
                });
              });
            });
          } else if (
            result.discount_of_offer.apply_on == "special_categories"
          ) {
            result.discount_of_offer.apply.map((el, index) => {
              this.data.product_row.products_row[index] = {};

              this.data.product_row.products_row[index].product_details = [];

              this.data.product_row.products_row[index].category = el;
            });

            this.data.product_row.products_id =
              result.discount_of_offer.apply_ids;
          } else {
            this.data.product_row.payment.name =
              result.discount_of_offer.payment_type;
          }
        }
      });
    },
    clearProductsRow() {
      this.data.product_row.products_row = [];
      this.appendRow();
    },
    nameWithCode({ name, code }) {
      return `${name} — كود ${code}`;
    },
  },

  created() {
    if (this.$route.params.id) {
      this.getData();
    }
    this.getProducts();
    this.getCategories();
  },
};
</script>

<style></style>
