<template>
  <div class="create_wrapper fadeIn">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Image -->
            <uplode-image
              @inputChanged="uplodeImg_1"
              placeHolder="صورة التصنيف"
            ></uplode-image>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.image_alt_ar"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.image_alt_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.image_alt_en"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.image_alt_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_en") }}
                </label>
              </div>
            </div>

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.slug"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_ar") }}
                </label>
              </div>
            </div> -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.slug"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_en") }}
                </label>
              </div>
            </div> -->

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.content_ar") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.ar.desc"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.content_en") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.en.desc"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.meta_title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_title_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.meta_title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_title_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.meta_description"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_description_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.meta_description"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_description_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.meta_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_tag_ar") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.meta_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_tag_en") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="url"
                  class="form-control"
                  v-model.trim="data.meta_canonical_tag"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.meta_canonical_tag") }}
                </label>
              </div>
            </div>
            <v-radio-group v-model="data.type" row>
              <v-radio
                @click="
                  data.category = null;
                  data.sub_category = null;
                "
                label="اضافة ك قسم رئيسي"
                value="main"
              ></v-radio>
              <v-radio
                @click="
                  data.category = null;
                  data.sub_category = null;
                "
                label="اضافة ك قسم فرع"
                value="sub"
              ></v-radio>
              <!-- <v-radio
                @click="
                  data.category = null
                  data.sub_category = null
                "
                label="اضافة ك قسم فرعي لقسم فرعي"
                value="sub_sub"
              ></v-radio> -->
            </v-radio-group>

            <div class="col-lg-6 py-0" v-if="data.type == 'sub'">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.category") }}
                </label>
                <treeselect
                  dir="rtl"
                  :multiple="false"
                  placeholder=""
                  :options="categories"
                  :show-count="true"
                  :flat="false"
                  v-model="data.category"
                />

                <!-- :disable-branch-nodes="true" -->
              </div>
            </div>
            <!-- 
            <div class="col-lg-6 py-0" v-if="data.type == 'sub_sub'">
              <div
                class="input_wrapper top_label"
                :class="{ disabled: data.category == null }"
              >
                <label class="form-label">
                  {{ $t('forms.labels.sub_category') }}
                </label>
                <multiselect
                  v-model="data.sub_category"
                  :options="sub_categories"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div> -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      loading: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.categories.title"),
          disabled: false,
          href: "/categories",
        },
        {
          text: this.$t("breadcrumb.categories.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image: null,
        ar: {
          title: null,
          desc: null,
          // slug: null,
          meta_title: null,
          meta_tag: null,
          meta_description: null,
        },
        en: {
          title: null,
          desc: null,
          // slug: null,
          meta_title: null,
          meta_tag: null,
          meta_description: null,
        },
        type: "main",
        meta_canonical_tag: null,
        image_alt_ar: null,
        image_alt_en: null,
        category: null,
        sub_category: null,
      },

      // Fixed Data
      categories: [],
      sub_categories: [],
    };
  },

  methods: {
    // Uplode Image
    uplodeImg_1(obj) {
      this.data.image = obj;
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image?.img_src) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.image_alt_ar) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image_alt_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.image_alt_en) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image_alt_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.title_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.title_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      }
      // else if (!this.data.ar.slug) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.slug_ar"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // }
      // else if (!this.data.en.slug) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("forms.validation.slug_en"),
      //     position: "bottomRight",
      //   });
      //   this.btnIsLoading = false;
      //   return;
      // }
      else if (!this.data.ar.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.content_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.content_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_title_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_title) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_title_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_description) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_description_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_description) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_description_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.meta_tag) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_tag_ar"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.en.meta_tag) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.meta_tag_en"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // Submit Data
    submitData() {
      this.btnIsLoading = true;
      if (this.data.type == "sub_sub" && !this.data.category) {
        this.$iziToast.success({
          timeout: 2000,
          message: "يجب اختيار قسم رئيسي",
          position: "bottomRight",
        });
        this.btnIsLoading = false;

        return;
      } else {
        const submit_data = new FormData();
        submit_data.append("image", this.data.image.img_file);
        submit_data.append("ar[name]", this.data.ar.title);
        submit_data.append("ar[desc]", this.data.ar.desc);
        // submit_data.append("ar[slug]", this.data.ar.slug);
        submit_data.append("ar[meta_title]", this.data.ar.meta_title);
        submit_data.append("ar[meta_tag]", this.data.ar.meta_tag);
        submit_data.append(
          "ar[meta_description]",
          this.data.ar.meta_description
        );
        submit_data.append("en[name]", this.data.en.title);
        submit_data.append("en[desc]", this.data.en.desc);
        // submit_data.append("en[slug]", this.data.en.slug);
        submit_data.append("en[meta_title]", this.data.en.meta_title);
        submit_data.append("en[meta_tag]", this.data.en.meta_tag);
        submit_data.append(
          "en[meta_description]",
          this.data.en.meta_description
        );
        submit_data.append("meta_canonical_tag", this.data.meta_canonical_tag);
        submit_data.append("image_alt_ar", this.data.image_alt_ar);
        submit_data.append("image_alt_en", this.data.image_alt_en);

        if (this.data.type == "sub") {
          submit_data.append("parent_id", this.data.category);
        }

        this.$axios({
          method: "POST",
          url: "categories",
          data: submit_data,
        })
          .then(() => {
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("addSuccess"),
              position: "bottomRight",
            });
            if (this.data.type == "sub") {
              this.$router.push({
                path: `/categories/${this.data.category}/sub-category`,
              });
            } else {
              this.$router.push({ path: "/categories" });
            }
            this.btnIsLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
            this.btnIsLoading = false;
          });
      }
    },

    // ====== Main Categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `category/treet`,
      }).then((res) => {
        this.categories = res.data.data;
      });
    },

    // ====== Sub Categories
    getSubCategories(id) {
      this.$axios({
        method: "GET",
        url: `categories/${id}`,
      }).then((res) => {
        this.sub_categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });

        // console.log(this.sub_categories)
      });
    },
  },

  created() {
    this.getCategories();
  },
};
</script>
