<template>
  <transition name="fadeInUp" mode="out-in" v-if="data.loading">
    <MainLoader />
  </transition>
  <div class="profile_wrapper most_profile" v-else>
    <div class="profile_header_wrapper custom_card">
      <vue-particles
        color="#fff"
        :particleOpacity="0.7"
        :particlesNumber="180"
        shapeType="circle"
        :particleSize="4"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.3"
        :linesDistance="100"
        :moveSpeed="4"
        :hoverEffect="true"
        :clickEffect="true"
        hoverMode="repulse"
        clickMode="push"
      ></vue-particles>

      <div class="profile_header_data" v-if="data.userInfo">
        <div class="avatar_wrapper">
          <img
            :src="data.userInfo.avatar"
            width="100"
            height="100"
            alt="Avatar"
          />
        </div>

        <div class="name_wrapper">
          <h3>{{ data.userInfo.fullname }}</h3>
          <!-- <h5 class="font-weight">{{ data.userInfo.email }}</h5> -->
        </div>
      </div>
      <div
        v-else
        class="text-center text-white mt-4 fs-4 position-absolute top-50 w-100"
      >
        لا يوجد بيانات
      </div>
    </div>
    <transition name="fadeInUp" mode="out-in">
      <router-view :userInfo="data.userInfo"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      data: {
        userInfo: null,
        loading: false,
      },
    };
  },

  methods: {
    getData() {
      this.data.loading = true;
      this.$axios({
        method: "GET",
        url: `providers/${this.id}`,
      })
        .then((res) => {
          this.data.loading = false;

          this.data.userInfo = res.data.data;
          this.data.userInfo.loading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.data.loading = false;
        });
    },
  },
  computed: {
    isActive() {
      return this.data.userInfo.is_active
        ? this.$t("active")
        : this.$t("inactive");
    },

    isBan() {
      return this.data.userInfo.is_ban ? this.$t("ban") : this.$t("inban");
    },
    isActiveManager() {
      return this.data.userInfo.is_ban ? "غير مفعل" : " مفعل";
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style>
.profile_wrapper .custom_card {
  background-color: var(--mainColor-2);
}
</style>
