<template>
  <div class="create_wrapper add-order">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_with_coupon')"
                v-model="data.is_with_coupon"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
            <!-- Start:: Image -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <select
                  class="form-control border-0 outline-none"
                  style="box-shadow: none"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.display_platform"
                >
                  <option value="both">{{ $t("both") }}</option>
                  <option value="app">{{ $t("app") }}</option>
                  <option value="website">{{ $t("website") }}</option>
                </select>
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.platform") }}
                </label>
              </div>
            </div>
            <transition name="fadeInUp">
              <div class="row justify-center align-center">
                <div
                  class="col-md-6"
                  v-if="
                    data.display_platform == 'both' ||
                    data.display_platform == 'app'
                  "
                >
                  <label for="name_input" class="fs-6">
                    صورة العرض في التطبيق بالعربي
                  </label>
                  <uplode-image
                    :data_src="data.ar.app_img"
                    @inputChanged="uploadAppImgAr"
                    placeHolder="صورة العرض"
                  ></uplode-image>
                </div>
                <div
                  class="col-md-6"
                  v-if="
                    data.display_platform == 'both' ||
                    data.display_platform == 'app'
                  "
                >
                  <label for="name_input" class="fs-6">
                    صورة العرض في التطبيق بالانجليزي
                  </label>
                  <uplode-image
                    :data_src="data.en.app_img"
                    @inputChanged="uploadAppImgEn"
                    placeHolder="صورة العرض"
                  ></uplode-image>
                </div>
                <div
                  class="col-md-6"
                  v-if="
                    data.display_platform == 'both' ||
                    data.display_platform == 'website'
                  "
                >
                  <label for="name_input" class="fs-6">
                    صورة العرض في الموقع بالعربي
                  </label>
                  <uplode-image
                    :data_src="data.ar.website_img"
                    @inputChanged="uploadWebImgAr"
                    placeHolder="صورة العرض"
                  ></uplode-image>
                </div>
                <div
                  class="col-md-6"
                  v-if="
                    data.display_platform == 'both' ||
                    data.display_platform == 'website'
                  "
                >
                  <label for="name_input" class="fs-6">
                    صورة العرض في الموقع بالانجليزي
                  </label>
                  <uplode-image
                    :data_src="data.en.website_img"
                    @inputChanged="uploadWebImgEn"
                    placeHolder="صورة العرض"
                  ></uplode-image>
                </div>
              </div>
            </transition>
            <!-- End:: Image -->

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.content_ar") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.ar.desc"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.content_en") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.en.desc"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="preventMinus"
                  min="0"
                  v-model.trim="data.ordering"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.ordering") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="preventMinus"
                  min="0"
                  v-model.trim="data.max_use"
                />
                <label for="name_input" class="form-label">
                  الحد الأقصي للاستخدام
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="preventMinus"
                  min="0"
                  v-model.trim="data.num_of_use"
                />
                <label for="name_input" class="form-label">
                  عدد مرات الاستخدام
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0"></div>
            <!-- Start:: Start Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_date") }}
                </label>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    @input="dateMenu = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_time") }}
                </label>
                <v-dialog
                  ref="start_time_dialog"
                  v-model="start_time_modal"
                  :return-value.sync="data.start_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start_time_modal"
                    v-model="data.start_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_time_modal = false"
                    >
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_time_dialog.save(data.start_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- End:: Start Date -->

            <!-- Start:: End Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_date") }}
                </label>
                <v-menu
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    @input="dateMenu2 = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_time") }}
                </label>
                <v-dialog
                  ref="dialog"
                  v-model="end_time_modal"
                  :return-value.sync="data.end_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end_time_modal"
                    v-model="data.end_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_time_modal = false">
                      الغاء
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(data.end_time)"
                    >
                      تم
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- End:: End Date -->

            <div class="col-12" v-if="offerTypeEdit == 'fix'">
              <label for="name_input" class="form-label title">
                {{ $t("forms.labels.offerType") }}
              </label>
              <v-radio-group v-model="data.type" row>
                <v-radio :label="$t('fix_amount')" value="fix_amount"></v-radio>
                <v-radio :label="$t('percentage')" value="percentage"></v-radio>
              </v-radio-group>
            </div>
            <div class="col-12" v-else>
              <label for="name_input" class="form-label title">
                {{ $t("forms.labels.offerType") }}
              </label>
              <v-radio-group v-model="data.type" row>
                <v-radio
                  :label="$t('buy_x_get_y')"
                  value="buy_x_get_y"
                ></v-radio>
              </v-radio-group>
            </div>
            <transition name="fade">
              <BuyxGety v-if="data.type == 'buy_x_get_y'" :static="data" />
              <fix-or-quantity v-else :staticData="data" />
            </transition>
          </div>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
import FixOrQuantity from "./add/FixOrQuantity.vue";
import BuyxGety from "./add/BuyxGety.vue";
export default {
  components: { BuyxGety, FixOrQuantity },
  name: "update",
  props: ["id"],
  data() {
    return {
      dateMenu: false,
      dateMenu2: false,
      start_time_modal: false,
      end_time_modal: false,

      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.offers.title"),
          disabled: false,
          href: "/flash-sales",
        },
        {
          text: this.$t("breadcrumb.offers.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading

      // ========== create_data
      data: {
        ar: {
          app_img: null,
          website_img: null,
          title: null,
          desc: null,
        },
        en: {
          app_img: null,
          website_img: null,
          title: null,
          desc: null,
        },
        display_platform: null,
        ordering: "",
        max_use: null,
        num_of_use: null,
        type: "buy_x_get_y",
        start_date: null,
        end_date: null,

        start_time: null,
        end_time: null,

        is_with_coupon: false,
        is_active: 1,
      },
    };
  },

  computed: {
    discount_types() {
      if (this.data.type == "buy_x_get_y") {
        return [
          {
            id: "free_product",
            name: "free_product",
          },
          {
            id: "percentage",
            name: "percentage",
          },
        ];
      } else if (
        this.data.type == "fix_amount" ||
        this.data.type == "percentage"
      ) {
        return [
          {
            id: "value",
            name: "value",
          },
          {
            id: "percentage",
            name: "percentage",
          },
        ];
      }
    },
    applys_on() {
      return [
        {
          id: "special_products",
          name: "special_products",
        },
        {
          id: "special_categories",
          name: "special_categories",
        },
      ];
    },
    offerTypeEdit() {
      return this.$store.getters.offerTypeEdit;
    },
    min_type_option() {
      return [
        {
          id: "quantity_of_products",
          name: "quantity_of_products",
        },
        {
          id: "amount_of_total_price",
          name: "amount_of_total_price",
        },
      ];
    },
  },

  methods: {
    uploadAppImgAr(obj) {
      this.data.ar.app_img = obj;
    },
    uploadAppImgEn(obj) {
      this.data.en.app_img = obj;
    },
    uploadWebImgAr(obj) {
      this.data.ar.website_img = obj;
    },
    uploadWebImgEn(obj) {
      this.data.en.website_img = obj;
    },

    resetApplys(e) {
      this.data.applys_ids = [];
    },

    appendProductRow() {
      this.data.products_rows.push({
        product: null,
        product_details_show: [],
        product_details_ids: [],
      });
    },
    deleteProductRow(index) {
      this.data.products_rows.splice(index, 1);
    },

    getProductDetails(index, id) {
      this.$axios({
        method: "GET",
        url: `dashboard/products/${id}`,
      }).then((res) => {
        console.log(res.data.data.product_details);
        this.data.products_rows[index].product_details_show =
          res.data.data.product_details;
      });
    },

    // ====== Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image?.img_file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "الصورة مطلوبة",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.discount) {
        this.$iziToast.error({
          timeout: 2000,
          message: "قيمة الخصم مطلوبة",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.title || !this.data.ar.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: "حقل العنوان مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.type == "both") {
        if (!this.data.ar.app_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في التطبيق بالعربي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.en.app_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في التطبيق بالانجليزي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.ar.website_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في الموقع بالعربي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.en.website_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في الموقع بالانجليزي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        }
      } else if (!this.data.type == "app") {
        if (!this.data.ar.app_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في التطبيق بالعربي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.en.app_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في التطبيق بالانجليزي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        }
      } else if (!this.data.type == "website") {
        if (!this.data.ar.website_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في الموقع بالعربي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.en.website_img) {
          this.$iziToast.error({
            timeout: 2000,
            message: "حقل صورة العرض في الموقع بالانجليزي مطلوب",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        }
      } else if (!this.data.ar.desc || !this.data.ar.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: "حقل الوصف مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.discount_type) {
        this.$iziToast.error({
          timeout: 2000,
          message: "نوع الخصم مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.max_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: "اقصي عدد مرات الالاستخدام مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.num_of_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: "عدد مرات الاستخدام",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // ====== Submit Data

    // ====== Products
    getProducts() {
      this.$axios({
        method: "GET",
        url: `product/without_paginate`,
      }).then((res) => {
        this.products = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    // ====== categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `category/all`,
      }).then((res) => {
        this.categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    clearDataProductsRow(i) {
      this.data.products_rows[i].category
        ? (this.data.products_rows[i].category = null)
        : "";
      this.data.products_rows[i].product
        ? (this.data.products_rows[i].product = null)
        : "";
    },
    getData() {
      this.$axios({
        method: "GET",
        url: `offer/${this.id}`,
      }).then((res) => {
        this.data.ar.app_img = res.data.data.app_image_ar;
        this.data.en.app_img = res.data.data.app_image_en;
        this.data.ar.website_img = res.data.data.web_image_ar;
        this.data.en.website_img = res.data.data.web_image_en;

        this.data.discount_type = {
          id: res.data.data.discount_type,
          name: res.data.data.discount_type,
        };
        this.data.type = res.data.data.type;
        this.data.ordering = res.data.data.ordering;
        this.data.display_platform = res.data.data.display_platform;
        this.data.max_use = res.data.data.max_use;
        this.data.num_of_use = res.data.data.num_of_use;
        this.data.start_date = res.data.data.start_at;
        this.data.end_date = res.data.data.end_at;
        this.data.start_time = res.data.data.start_time;
        this.data.end_time = res.data.data.end_time;
        this.data.image = res.data.data.image;
        this.data.ar.title = res.data.data.ar.name;
        this.data.en.title = res.data.data.en.name;
        this.data.ar.desc = res.data.data.ar.desc;
        this.data.en.desc = res.data.data.en.desc;
        this.data.is_with_coupon = res.data.data.is_with_coupon;
        this.data.is_active = +res.data.data.is_active;
      });
    },
  },

  created() {
    this.getData();
    this.getProducts();
    this.getCategories();
  },
};
</script>

<style lang="scss">
.add-order {
  .productRow {
    border: 1px solid #339989;
    border-radius: 15px;
    padding: 10px 10px 20px 10px;

    .detail {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .detail_show {
        border: 1px solid #339989;
        padding: 10px;
        flex-grow: 1;
        border-radius: 15px;
        position: relative;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .rate {
          color: gray;
          position: absolute;
          top: 5px;
          left: 5px;
          font-size: 13px;

          span {
            color: yellow;
          }
        }

        .image {
          img {
            max-height: 50px;
          }
        }

        .color,
        .size {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .key {
          margin-bottom: 5px;
        }
      }
    }
  }
  label.form-label.title {
    color: #339989 !important;
  }
}
</style>
