<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Start:: Image Ar -->
            <div class="col-lg-6">
              <uplode-image
                @inputChanged="uplodeImg_1"
                :placeHolder="$t('forms.labels.image_ar')"
                :data_src="data.ar.image"
              ></uplode-image>
            </div>
            <!-- End:: Image Ar -->

            <!-- Start:: Image Ar -->
            <div class="col-lg-6">
              <uplode-image
                @inputChanged="uplodeImg_2"
                :placeHolder="$t('forms.labels.image_en')"
                :data_src="data.en.image"
              ></uplode-image>
            </div>
            <!-- End:: Image Ar -->

            <!-- Start:: Ar title -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ar.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_ar") }}
                </label>
              </div>
            </div>
            <!-- End:: Ar title -->

            <!-- Start:: En title -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.en.title"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.title_en") }}
                </label>
              </div>
            </div>
            <!-- End:: En title -->

            <!-- Start:: Ar desc -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_ar") }}
                </label>
                <ckeditor class="col-12" v-model="data.ar.desc" />
              </div>
            </div>
            <!-- End:: Ar desc -->

            <!-- Start:: En desc -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_en") }}
                </label>
                <ckeditor class="col-12" v-model="data.en.desc" />
              </div>
            </div>

            <!-- Start:: En slug -->
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.ordering"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.ordering") }}
                </label>
              </div>
            </div>
            <!-- End:: En slug -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  props: ["id"],

  data() {
    return {
      dateMenu: false,
      dateMenu2: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.about.title"),
          disabled: false,
          href: "/settings/about",
        },
        {
          text: this.$t("breadcrumb.about.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        ar: {
          image: null,
          title: null,
          desc: null,
        },
        en: {
          image: null,
          title: null,
          desc: null,
        },
        ordering: null,
      },
    };
  },

  methods: {
    // Uplode Image
    uplodeImg_1(obj) {
      this.data.ar.image = obj;
    },
    // Uplode Image
    uplodeImg_2(obj) {
      this.data.en.image = obj;
    },

    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;
      this.submitData();
    },

    //GET DATA
    getData() {
      this.$axios({
        method: "GET",
        url: `about/${this.id}`,
      }).then((res) => {
        this.data.ar.image = res.data.data.images.ar;
        this.data.en.image = res.data.data.images.en;

        this.data.ar.title = res.data.data.ar.title;
        this.data.en.title = res.data.data.en.title;

        this.data.ar.desc = res.data.data.ar.desc;
        this.data.en.desc = res.data.data.en.desc;

        this.data.ordering = res.data.data.ordering;
      });
    },
    //GET DATA

    // Submit Data
    submitData() {
      const submit_data = new FormData();

      submit_data.append("_method", "PUT");

      if (this.data.ar.image?.img_file) {
        submit_data.append("ar[image]", this.data.ar.image?.img_file || "");
      }
      if (this.data.en.image?.img_file) {
        submit_data.append("en[image]", this.data.en.image?.img_file || "");
      }

      submit_data.append("ar[title]", this.data.ar.title || "");
      submit_data.append("en[title]", this.data.en.title || "");

      submit_data.append("ar[desc]", this.data.ar.desc || "");
      submit_data.append("en[desc]", this.data.en.desc || "");

      submit_data.append("ordering", this.data.ordering || "");

      this.$axios({
        method: "POST",
        url: `about/${this.id}`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/settings/about" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>
